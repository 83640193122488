import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'underscore';
import { setLoader } from "../store/reducer";
import { HiOutlinePlus } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { AxiosApi } from "../utility/axios";

function AllPlans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allPlans, setAllPlans] = useState([]);
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  useEffect(() => {
    getAllPlans();
  }, []);

  const getAllPlans = async (isLoader = true) => {
    isLoader && dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/admin/stripe/getList/plan`).then((res) => {
      setAllPlans(res.data.data);
      dispatch(setLoader(false));
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">All Plans</h4>
        <div>
          <button onClick={() => navigate("/create-plan")} type="button" className="addlly-primary py-2 px-3 ms-3">
            <span>Add Plan</span> <HiOutlinePlus className="fs-6" />
          </button>
        </div>
      </div>
      <ShowPlan product={allPlans} />
    </div>
  );
}

const ShowPlan = ({ product }) => {
  const navigate = useNavigate();
  const products = _.sortBy(product, (item) => {
    return item.is_active
  })

  return (
    <div className='showplan-container mb-20'>
      <div className="d-flex align-items-center justify-content-between pointer">
        <h4 className="mb-0">{product.product_name}</h4>
      </div>

      <div className="res-table">
        <table className="custom-table table mt-20 mb-4">
          <thead>
            <tr>
              <th scope="col text-light bg-dark">Name</th>
              <th scope="col text-light bg-dark">Amount</th>
              <th scope="col text-light bg-dark">Addlly Credits</th>
              <th scope="col text-light bg-dark">Per Credit Rate</th>
              <th scope="col text-light bg-dark">Status</th>
              <th scope="col text-light bg-dark">Action</th>
            </tr>
          </thead>
          <tbody>
            {products?.map((item, ind) => (
              <tr key={ind}>
                <td>{item?.name}</td>
                <td>{item?.amount}</td>
                <td>{item?.credits}</td>
                <td>{item?.per_credit_rate ?? '-'}</td>
                <td>
                  {item?.is_active ? <span style={{ color: 'green' }}>Activated</span> : <span style={{ color: 'red' }}>Not Activated</span>}
                </td>
                <td>
                  <button onClick={() => navigate(`/create-plan/${item?.planId}`)} type="button" className="addlly-outline py-2 px-3 ms-3">
                    <span>Edit</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AllPlans;
