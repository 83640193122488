import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import ChartsPie from "../Components/ChartsPie";
import ChartsBar from '../Components/ChartsBar';
import { setLoader } from "../store/reducer";
import blogImage from '../Assets/Images/analytics_blog.png';
import socialImage from '../Assets/Images/analytics_social.png';
import creditsImage from '../Assets/Images/analytics_credits.png';
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const Analytics = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [totalArticles, setTotalArticles] = useState([]);
  const [totalSocialPost, setTotalSocialPost] = useState([]);
  const [totalCredits, setTotalCredits] = useState([]);
  const [top_keywords, setTopKeywords] = useState([]);
  const [social_media_post, setSocialMediaPost] = useState([]);
  const [credits_usage, setCreditsUsage] = useState([]);
  const [credits_usage_social, setCreditsUsageSocial] = useState([]);
  const [top_users_article, setTopUsersArticle] = useState([]);
  const [top_users_social, setTopUsersSocial] = useState([]);
  const [combinedDataMonth, setCombinedDataMonth] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("Month");

  useEffect(() => {
    getSummaryByFilter(currentFilter);
  }, []);

  const getSummaryByFilter = async (filter) => {
    let startDate = '', endDate = '';
    const dateObj = new Date();
    if (filter === "Month") {
      startDate = formatDate(new Date(dateObj.getFullYear(), dateObj.getMonth(), 1));
      endDate = formatDate(new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0));
    } else if (filter === "Last 3 Months") {
      startDate = formatDate(new Date(dateObj.getFullYear(), dateObj.getMonth() - 3, dateObj.getDate()));
      endDate = formatDate(new Date(dateObj));
    } else if (filter === "Last 6 Months") {
      startDate = formatDate(new Date(dateObj.getFullYear(), dateObj.getMonth() - 6, dateObj.getDate()));
      endDate = formatDate(new Date(dateObj));
    } else if (filter === "Year") {
      startDate = formatDate(new Date(dateObj.getFullYear(), 0, 1));
      endDate = formatDate(new Date(dateObj.getFullYear(), 11, 31));
    } else if (filter === "Max") {
      startDate = formatDate(new Date(dateObj.getFullYear() - 100));
      endDate = formatDate(new Date(dateObj));
    }

    try {
      dispatch(setLoader(true));
      const queryParams = `startDate=${startDate}&endDate=${endDate}`
      const res = await AxiosApi.post(`${apiBaseUrl}/analytics/summary?${queryParams}`);
      if (res && res.data) {
        setTotalArticles(res.data.total_articles);
        setTotalSocialPost(res.data.total_social_post);
        setTotalCredits(res.data.total_credits);
        setTopKeywords(res.data.top_keywords);
        setSocialMediaPost(res.data.social_media_post);
        setTopUsersArticle(res.data.top_users_article);
        setTopUsersSocial(res.data.top_users_social);

        const shortArticle = res.data.credits_usage.find(item => item.post_type === "Short Article")?.ttl || 0;
        const longArticle = (res.data.credits_usage.find(item => item.post_type === "Long Article")?.ttl || 0) + (res.data.credits_usage.find(item => item.post_type === "Regenerate Long Article")?.ttl || 0);
        const coPilotArticle = (res.data.credits_usage.find(item => item.post_type === "Generate Article")?.ttl || 0) + (res.data.credits_usage.find(item => item.post_type === "Regenerate Article")?.ttl || 0);
        const linkedinPost = (res.data.credits_usage.find(item => item.post_type === "LinkedIn Post")?.ttl || 0) + (res.data.credits_usage.find(item => item.post_type === "Regenerate LinkedIn Post")?.ttl || 0);
        const fbPost = (res.data.credits_usage.find(item => item.post_type === "Facebook Post")?.ttl || 0) + (res.data.credits_usage.find(item => item.post_type === "Regenerate Facebook Post")?.ttl || 0);
        const instagramAd = (res.data.credits_usage.find(item => item.post_type === "Instagram Post")?.ttl || 0) + (res.data.credits_usage.find(item => item.post_type === "Regenerate Instagram Post")?.ttl || 0);
        const googleAd = (res.data.credits_usage.find(item => item.post_type === "Google Ad Copy")?.ttl || 0) + (res.data.credits_usage.find(item => item.post_type === "Regenerate Google Ad Copy")?.ttl || 0);

        setCreditsUsage([
          { "post_type": "Short Article", "ttl": shortArticle },
          { "post_type": "Long Article", "ttl": longArticle },
          { "post_type": "Co-pilot Article", "ttl": coPilotArticle },
        ]);

        setCreditsUsageSocial([
          { "post_type": "LinkedIn Post", "ttl": linkedinPost },
          { "post_type": "Facebook Post", "ttl": fbPost },
          { "post_type": "Instagram Post", "ttl": instagramAd },
          { "post_type": "Google Ads Post", "ttl": googleAd },
        ]);

        const userRegisterData = [];
        for (let index = 0; index < res.data.users_register.length; index++) {
          const element = res.data.users_register[index];
          userRegisterData.push({
            date: formatDate(new Date(element.date)),
            register: element.ttl,
          });
        }

        if (userRegisterData.length === 0) {
          const today = new Date();
          const dayOfWeek = today.getDay();
          const startDate = new Date(today);
          startDate.setDate(today.getDate() - dayOfWeek);
          if (dayOfWeek !== 0) {
            startDate.setDate(startDate.getDate());
          }

          for (let i = 0; i < 7; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);
            const formattedDate = currentDate.toISOString().slice(0, 10);
            userRegisterData.push({ date: formattedDate, register: 0 });
          }
        }
        setCombinedDataMonth(userRegisterData);

        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      console.log(error, "error");
      dispatch(setLoader(false));
    }
  };

  const handleOnChangeCurrentFilter = async (val) => {
    if (val === currentFilter) {
      return false;
    }

    setCurrentFilter(val);
    getSummaryByFilter(val);
  }

  return (
    <React.Fragment>
      <div className="row m-0 mb-4">
        <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-0">Analytics Dashboard</h4>
            <div className="d-flex align-items-center">
              <Dropdown data-bs-theme="dark">
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" className="me-3">
                  <FaFilter /> <span className="mx-2">{currentFilter && currentFilter !== "" ? currentFilter : "Filter"}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {["Month", "Last 3 Months", "Last 6 Months", "Year", "Max"].map((item, index) => (
                    <Dropdown.Item as="button" key={index}
                      onClick={() => handleOnChangeCurrentFilter(item)}
                      active={item === currentFilter ? true : false}
                    >
                      {item}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col sm="12" md="7">
          <Row className="mb-2">
            {[
              { label: 'Articles Generated', value: totalArticles, img: blogImage },
              { label: 'Social Media Post', value: totalSocialPost, img: socialImage },
              { label: 'Credits Used', value: totalCredits, img: creditsImage }
            ].map((item, index) => (
              <Col sm="12" md="4" className="mb-3" key={index}>
                <div
                  className="dashboard-history border-radius-12 bg-white p-3 p-xl-4"
                  style={{
                    backgroundImage: `url(${item.img})`, backgroundSize: 'cover',
                    backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'
                  }}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h3>{item.value || 0}</h3>
                  </div>
                  <h5 className="fw-normal mt-2">{item.label}</h5>
                </div>
              </Col>
            ))}
          </Row>
          {top_keywords && top_keywords.length > 0 && (
            <Row className="mb-4">
              <Col sm="12">
                <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                  <h4>Top 5 Keywords by User</h4>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <table className="analytics-table">
                      <thead>
                        <tr>
                          <th style={{ width: '80%' }}>Keyword</th>
                          <th>Searches</th>
                        </tr>
                      </thead>
                      <tbody>
                        {top_keywords && top_keywords.length > 0 ? top_keywords.map((item, index) => (
                          <tr key={index}>
                            <td>{item.keyword || "-"}</td>
                            <td>{item.ttl}</td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              <NoDataAvailable />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row className="mb-4">
            <Col sm="12">
              <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                <h4>Top 5 users who have generated the most Article</h4>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <table className="analytics-table">
                    <thead>
                      <tr>
                        <th style={{ width: '80%' }}>Username</th>
                        <th>Generations</th>
                      </tr>
                    </thead>
                    <tbody>
                      {top_users_article && top_users_article.length > 0 ? top_users_article.map((item, index) => (
                        <tr key={index}>
                          <td>{item.username}</td>
                          <td>{item.ttl}</td>
                        </tr>
                      )) : (
                        <tr>
                          <td colSpan="2" className="text-center">
                            <NoDataAvailable />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
          {top_users_social && top_users_social.length > 0 && (
            <Row className="mb-4">
              <Col sm="12">
                <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                  <h4>Top 5 users who have generated the most Social Media Posts</h4>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <table className="analytics-table">
                      <thead>
                        <tr>
                          <th style={{ width: '80%' }}>Username</th>
                          <th>Generations</th>
                        </tr>
                      </thead>
                      <tbody>
                        {top_users_social && top_users_social.length > 0 ? top_users_social.map((item, index) => (
                          <tr key={index}>
                            <td>{item.username}</td>
                            <td>{item.ttl}</td>
                          </tr>
                        )) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              <NoDataAvailable />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row className="">
            <Col sm="12">
              <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
                <h4 className="mb-3">Users Registered over the {currentFilter}</h4>
                <ChartsBar data={combinedDataMonth} isLineChart={true} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm="12" md="5">
          <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4 mb-4">
            <h4 className="mb-3">Social Credits Usage</h4>
            <ChartsPie data={social_media_post} />
          </div>
          <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4 mb-4">
            <h4 className="mb-3">Credits Usage (Articles)</h4>
            <ChartsPie data={credits_usage} />
          </div>
          <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4 mb-4">
            <h4 className="mb-3">Credits Usage (Social)</h4>
            <ChartsPie data={credits_usage_social} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Analytics;
