import React, { useEffect, useState } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../store/reducer";
import { BsFillCheckCircleFill } from "react-icons/bs";
import SelectedItem from "./SelectedItem";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { AxiosApi } from "../utility/axios";

const intervals = ["month", "one_time"];

const subscriptions = [
  {
    id: 0,
    title: "English",
    key: "english"
  },
  {
    id: 1,
    title: "Bahasa Indonesia",
    key: "bahasa"
  },
  {
    id: 2,
    title: "Mandarine",
    key: "mandarine"
  },
  {
    id: 10,
    title: "1-Click Blog",
    key: "one_click_blog"
  },
  {
    id: 11,
    title: "Blog Co-Pilot",
    key: "blog_co_pilot"
  },
  {
    id: 12,
    title: "Review System",
    key: "review_system"
  },
  {
    id: 3,
    title: "Google Ad Posts",
    key: "google_ad"
  },
  {
    id: 4,
    title: "Faq",
    key: "faq"
  },
  {
    id: 5,
    title: "Fact Checker",
    key: "fact_checker"
  },
  {
    id: 12,
    title: "Schema Markup",
    key: "schema_markup"
  },
  {
    id: 13,
    title: "HTML view",
    key: "html_view"
  },
  {
    id: 6,
    title: "LinkedIn Post",
    key: "linkedin_post"
  },
  {
    id: 14,
    title: "LinkedIn Carousel",
    key: "linkedin_carousel"
  },
  {
    id: 7,
    title: "Facebook Post",
    key: "facebook_post"
  },
  {
    id: 8,
    title: "Twitter Post",
    key: "twitter_post"
  },
  {
    id: 9,
    title: "Instagram Post",
    key: "instagram_post"
  },
  {
    id: 15,
    title: "Access to Media Library",
    key: "media_library"
  },
  {
    id: 16,
    title: "Customized Tone and Style",
    key: "brand_voice"
  },
  {
    id: 17,
    title: "Editorial Support",
    key: "editorial_support"
  },
]

const CreatePlans = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [errors, setErrors] = useState({});
  const [inputs, setInputs] = useState({});
  const [currentLan, setCurrentLan] = useState("usd");
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [accessData, setAccessData] = useState({
    english: 0,
    bahasa: 0,
    mandarin: 0,
    one_click_blog: 0,
    blog_co_pilot: 0,
    review_system: 0,
    google_ad: 0,
    faq: 0,
    fact_checker: 0,
    schema_markup: 0,
    html_view: 0,
    linkedin_post: 0,
    linkedin_carousel: 0,
    facebook_post: 0,
    twitter_post: 0,
    instagram_post: 0,
    media_library: 0,
    brand_voice: 0,
    editorial_support: 0,
  })

  useEffect(() => {
    getProducts();

    if (params && params.id) {
      getCurrentPlanData(params.id);
    }
  }, []);

  useEffect(() => {
    setInputs({ ...inputs, currency: currentLan });
  }, [currentLan]);

  const getCurrentPlanData = async (id) => {
    AxiosApi.get(`${apiBaseUrl}/admin/stripe/get/plan/${id}`).then((res) => {
      const data = res.data && res.data.data ? res.data.data : {};
      const metadata = data.metadata;

      setInputs({
        nick_name: data.nickname,
        product_id: data.product,
        currency: data.currency,
        amount: data.unit_amount / 100 + Number(metadata.discount_amount),
        credits: metadata.credits,
        credit_rate: metadata.credit_rate,
        discount_amount: metadata.discount_amount,
        monthly_price: data.unit_amount / 100 + Number(metadata.discount_amount),
        min_subscription_period: metadata.min_subscription_period,
        interval: (data?.recurring || data?.type),
      });

      setAccessData({
        english: metadata.english,
        bahasa: metadata.bahasa,
        mandarine: metadata?.mandarine ? metadata.mandarine : 0,
        one_click_blog: metadata.one_click_blog,
        blog_co_pilot: metadata.blog_co_pilot,
        review_system: metadata.review_system,
        google_ad: metadata.google_ad,
        faq: metadata.faq,
        fact_checker: metadata.fact_checker,
        schema_markup: metadata.schema_markup,
        html_view: metadata.html_view,
        linkedin_post: metadata.linkedin_post,
        linkedin_carousel: metadata.linkedin_carousel,
        facebook_post: metadata.facebook_post,
        twitter_post: metadata.twitter_post,
        instagram_post: metadata.instagram_post,
        media_library: metadata.media_library,
        brand_voice: metadata.brand_voice,
        editorial_support: metadata.editorial_support,
      });
      dispatch(setLoader(false));
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  };

  const getProducts = async (isLoader = true) => {
    isLoader && dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/admin/stripe/products`).then((res) => {
      setProducts(res.data.data.data);
      dispatch(setLoader(false));
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  };

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const createPlan = async () => {
    const data = {
      nickname: inputs.nick_name,
      product: inputs.product_id,
      currency: inputs.currency,
      unit_amount: inputs.amount,
      metadata: {
        credits: inputs.credits,
        credit_rate: inputs.credit_rate,
        discount_amount: inputs.discount_amount,
        min_subscription_period: inputs.min_subscription_period,
        ...accessData
      },
      intervals: inputs.interval,
    };

    try {
      let res = {};
      if (params && params.id) {
        res = await AxiosApi.put(`${apiBaseUrl}/admin/stripe/update/plan/${params.id}`, data);
      } else {
        res = await AxiosApi.post(`${apiBaseUrl}/admin/stripe/create/plan`, data);
      }

      setLoading(false);
      if (res && res.data) {
        toast.success(`Plan has been successFully ${params && params.id ? "updated" : "added"}.`);
        navigate("/all-plans");
      } else {
        toast.error("Something went wrong, Please try again.");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onCreate = async () => {
    try {
      setLoading(true);
      setErrors({});
      const errors = {};

      if (!inputs.amount && inputs.amount !== 0) {
        errors["amount"] = "Amount is required";
      }
      if (!inputs.nick_name) {
        errors["nick_name"] = "Nick Name is required";
      }
      if (!inputs.product_id) {
        errors["product_id"] = "please select an Product ";
      }
      if (!inputs.credits) {
        errors["credits"] = "credits is required";
      }
      if (!inputs.interval) {
        errors["interval"] = "please select an Interval ";
      }
      if (!inputs.credit_rate) {
        errors["credit_rate"] = "Credits Rate is required ";
      }
      if (!inputs.monthly_price && inputs.monthly_price !== 0) {
        errors["monthly_price"] = "Monthly Price is required ";
      }
      if (!inputs.min_subscription_period) {
        errors["min_subscription_period"] = "Minimum Subscription period is required";
      }
      if (!inputs.discount_amount) {
        errors["discount_amount"] = "Discount Amount is required ";
      }
      setErrors(errors);
      if (Object.keys(errors).length > 0) {
        setLoading(false);
        return;
      } else {
        createPlan();
      }
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  const onClose = () => {
    setShow(false);
  };

  if (params && params.id && !inputs?.nick_name) {
    return false;
  }

  return (
    <React.Fragment>
      <div className="w-100">
        <div className="border-radius-12 bg-white AIWriterBlog">
          <div className="AIWriterBlogHead">
            <div className="AIWriterBlogHeadTop align-items-end">
              <h3>{params && params.id ? "Edit Plan" : "Create Plan"}</h3>
            </div>
          </div>
          <div className="addllyFormWrap">
            <Row>
              <Col sm="6">
                <div className=" form-group mb-4">
                  <label className="text-dark">Select Product</label>
                  <select
                    name="product_id" onChange={handleInputs} value={inputs.product_id}
                    className={`rounded-pill addllyForm-control ${errors.product_id ? "is-invalid" : ""}`}
                    onFocus={() => setErrors({ ...errors, product_id: null })}
                  >
                    <option value="">Select Product</option>
                    {products.map((product) => {
                      return <option value={product.id}>{product.name}</option>;
                    })}
                  </select>
                  <div className="errorField" style={{ color: "#dc3545" }}>{errors.product_id}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Name</label>
                  <input
                    name="nick_name" type="text" placeholder="Name..." value={inputs.nick_name}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, nick_name: null })}
                    className={`addllyForm-control ${errors.nick_name ? "is-invalid" : ""}`}
                  />
                  <div className="errorField">{errors.nick_name}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Amount</label>
                  <input
                    name="amount" type="number" placeholder="Amount..." value={inputs.amount}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, amount: null })}
                    className={`addllyForm-control ${errors.amount ? "is-invalid" : ""}`}
                    disabled={params && params.id ? true : false}
                  />
                  <div className="errorField">{errors.amount}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Choose a Currency</label>
                  <ul className="selectLang">
                    <li onClick={() => setCurrentLan("usd")} onKeyDown={() => setCurrentLan("usd")} className={currentLan === "usd" ? "active" : ""}>
                      <p> USD </p>
                    </li>
                    <li onClick={() => setCurrentLan("sgd")} onKeyDown={() => setCurrentLan("sgd")} className={currentLan === "sgd" ? "active" : ""}>
                      <p> SGD </p>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Credits</label>
                  <input
                    name="credits" type="number" placeholder="Credits..." value={inputs.credits}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, credits: null })}
                    className={`addllyForm-control ${errors.credits ? "is-invalid" : ""}`}
                  />
                  <div className="errorField">{errors.credits}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Credits Rate</label>
                  <input
                    name="credit_rate" type="number" value={inputs.credit_rate} placeholder="Credits Rate..."
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, credit_rate: null })}
                    className={`addllyForm-control ${errors.credit_rate ? "is-invalid" : ""}`}
                  />
                  <div className="errorField">{errors.credit_rate}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className=" form-group mb-4">
                  <label className="text-dark">Select Interval</label>
                  <select
                    name="interval" value={inputs.interval}
                    className={`rounded-pill addllyForm-control ${errors.interval ? "is-invalid" : ""}`}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, interval: null })}
                  >
                    <option value="">Select Interval</option>
                    {intervals?.map((interval) => {
                      return <option value={interval}>{interval}</option>;
                    })}
                  </select>
                  <div className="errorField">{errors.interval}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Minimum Subscription period (months)</label>
                  <input
                    name="min_subscription_period" type="number" placeholder="Minimum Subscription period (months)"
                    value={inputs.min_subscription_period}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, min_subscription_period: null })}
                    className={`addllyForm-control ${errors.min_subscription_period ? "is-invalid" : ""}`}
                  />
                  <div className="errorField">{errors.min_subscription_period}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Monthly Price</label>
                  <input
                    name="monthly_price" type="number" placeholder="Monthly Price..." value={inputs.monthly_price}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, monthly_price: null })}
                    className={`addllyForm-control ${errors.monthly_price ? "is-invalid" : ""}`}
                  />
                  <div className="errorField">{errors.monthly_price}</div>
                </div>
              </Col>
              <Col sm="6">
                <div className="form-group mb-4">
                  <label>Discount Amount</label>
                  <input
                    name="discount_amount" type="text" placeholder="Discount Amount" value={inputs.discount_amount}
                    onChange={handleInputs} onFocus={() => setErrors({ ...errors, discount_amount: null })}
                    className={`addllyForm-control ${errors.discount_amount ? "is-invalid" : ""}`}
                  />
                  <div className="errorField">{errors.discount_amount}</div>
                </div>
              </Col>
              <Col sm="12">
                <div className=" form-group mb-4">
                  <label className="text-dark">Select Access</label>
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 14 }}>
                    {subscriptions?.map((sub) => {
                      return <SelectedItem title={sub.title} key={sub.id} objKey={sub.key} accessData={accessData}
                        onChange={(selectedItem) => { setAccessData({ ...accessData, ...selectedItem }) }}
                      />
                    })}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <button type="submit" className="addlly-primary" onClick={() => { onCreate(); }} disabled={loading}>
                <span>{loading ? "please wait.." : (params && params.id) ? "Update" : "Create"}</span> <HiOutlineChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal className="note-modal" centered show={show} onHide={onClose}>
        <Modal.Body className="text-center">
          <BsFillCheckCircleFill color="#0000ff" size={60} />
          <h5 className="mt-20">Plan Has Been SuccessFully Added</h5>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="addlly-primary" onClick={onClose}>
            <span>close</span> <HiOutlineChevronRight />
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CreatePlans;
