import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Cookies from "js-cookie";
import { BiUser } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLock, AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { setLoginDetails } from "../store/reducer";
import { toast } from "react-hot-toast";
import { setLoader } from "../store/reducer";
import { useNavigate } from "react-router-dom";
import { AxiosApi } from "../utility/axios";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .min(4, "Username must be at least 4 characters")
    .max(20, "Username must not exceed 20 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(2, "Password must be at least 2 characters")
    .max(40, "Password must not exceed 40 characters"),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);
  const [typePassword, setTypePassword] = useState(true);
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    dispatch(setLoader(true));
    AxiosApi.post(`${process.env.REACT_APP_API_URL_PROD}/admin/login`, {
      username: data?.username,
      password: data?.password,
    }).then((res) => {
      dispatch(setLoginDetails(res.data.data));
      dispatch(setLoader(false));
      localStorage.setItem('token', res.data.data?.token);
      // window.location.href = '/';
    }).catch((error) => {
      toast.error(error?.response?.data?.error || "Wrong username or password!");
      console.log(error);
      dispatch(setLoader(false));
    });

    /*if (data.username === "User123" && data.password === "User@123") {
      dispatch(setLoginDetails(data));
      dispatch(setCurrentEnvironment("staging"));
    } else {
      alert("Authenticate details wrong.");
    }*/
  };

  useEffect(() => {
    /* if (loginDetails?.username === "User123" && loginDetails?.password === "User@123") {
      navigate("/");
    } */
    if (loginDetails?.username && loginDetails?.password) {
      const cookieValue = Cookies.get("redirect-url");
      Cookies.set("redirect-url", "");
      if (cookieValue && cookieValue !== "") {
        window.location.href = cookieValue;
      } else {
        window.location.href = "/";
      }
    }
  }, [loginDetails]);

  if (loginDetails?.username && loginDetails?.password) {
    return ""
  }

  return (
    <div className="loginWrap">
      <div className="authenticateBox">
        <h2 className="boxTitle">Welcome Back</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="iconFormGrp">
            <span className="inputIcon">
              <BiUser size={16} color="#50506A" />
            </span>
            <input
              type="text"
              name="username"
              {...register("username")}
              placeholder="User Name"
              className={`${errors.username ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.username?.message}</div>
          </div>
          <div className="iconFormGrp">
            <span className="inputIcon">
              <AiOutlineLock size={16} color="#50506A" />
            </span>
            <input
              name="password"
              placeholder="Password"
              {...register("password")}
              type={typePassword ? "password" : "text"}
              className={`${errors.password ? "is-invalid" : ""}`}
            />
            <button type="button" onClick={() => setTypePassword(!typePassword)} className="passwordIcon">
              {!typePassword ? (
                <AiOutlineEyeInvisible size={16} color="#50506A" />
              ) : (
                <AiOutlineEye size={16} color="#50506A" />
              )}
            </button>
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
          <div className="submitBtn">
            <button type="submit">Sign In</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
