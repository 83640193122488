import React, { useEffect, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";

const SeoScore = ({ contentScore }) => {
  const word_score = (contentScore["Word count"]["SEO Score"] * 100) / contentScore["Word count"]["Max SEO score available"];
  const related_keywords_score = (contentScore["Related keywords"]["SEO Score"] * 100) / contentScore["Related keywords"]["Max SEO score available"];

  const [relatedKeywords, setRelatedKeywords] = useState([]);
  const [missingKeywords, setMissingKeywords] = useState([]);

  useEffect(() => {
    if (contentScore && contentScore["Related keywords"] && contentScore["Related keywords"]["Related keywords found"]) {
      setRelatedKeywords([...contentScore["Related keywords"]["Related keywords found"].split(",").filter((item) => item)]);
    }

    if (contentScore && contentScore["Related keywords"] && contentScore["Related keywords"]["Related keywords not found"]) {
      setMissingKeywords([...contentScore["Related keywords"]["Related keywords not found"].split(",").filter((item) => item)]);
    }
  }, []);

  return (
    <Col xl={4} lg={5} md={6}>
      <div className="content-score">
        <h3 className="text-center">SEO score</h3>
        <h6 className="text-center">(Valid only for articles generated in English)</h6>
        <h4 className="mt-3">Heading and Word Count Summary</h4>
        <div className="heading-summary mt-2">
          <div>
            <h6>h1</h6>
            <p>{contentScore["Page Headings summary"]["H1"]}</p>
          </div>
          <div>
            <h6>h2</h6>
            <p>{contentScore["Page Headings summary"]["H2"]}</p>
          </div>
          <div>
            <h6>h3</h6>
            <p>{contentScore["Page Headings summary"]["H3"]}</p>
          </div>
          <div>
            <h6>h4</h6>
            <p>{contentScore["Page Headings summary"]["H4"]}</p>
          </div>
          <div>
            <h6>h5</h6>
            <p>{contentScore["Page Headings summary"]["H5"]}</p>
          </div>
          <div>
            <h6>h6</h6>
            <p>{contentScore["Page Headings summary"]["H6"]}</p>
          </div>
          <div>
            <h6>Word Count</h6>
            <p>{contentScore["Word count"]["Word count total"]}</p>
          </div>
        </div>

        <div className="progress-bars">
          <Row className="mt-3">
            <Col xl={6} lg={12} sm={6}>
              <h6 className="mb-1 mt-2">Word Score</h6>
              <ProgressBar now={word_score} label={`${word_score.toFixed()}%`} className="rounded-pill" />
            </Col>
            <Col xl={6} lg={12} sm={6}>
              <h6 className="mb-1 mt-2">Related Keywords Score</h6>
              <ProgressBar now={related_keywords_score} label={`${related_keywords_score.toFixed()}%`} className="rounded-pill" />
            </Col>
          </Row>
        </div>
        <h4 className="mt-3">Related keywords</h4>
        <div className="tags mt-2">
          {relatedKeywords.map((it) => (
            <div className="tag status-success" key={it}>{it}</div>
          ))}
          {missingKeywords.map((it) => (
            <div className="tag status-danger" key={it}>{it}</div>
          ))}
        </div>
      </div>
    </Col>
  );
};

export default SeoScore;
