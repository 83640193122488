import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import NoDataAvailable from "./NoDataAvailable";

const ChartsPie = ({ data }) => {
  const [chartWidth, setChartWidth] = useState(400);
  const [isNoData, setIsNoData] = useState(true);
  const COLORS = [
    'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)', 'rgba(255, 99, 132, 1)',
  ];

  useEffect(() => {
    setIsNoData(true);
    if (data && data.length > 0) {
      const findIndex = data.findIndex((e) => e.ttl !== 0);
      if (findIndex !== -1) {
        setIsNoData(false);
      }
    }
  }, [data])

  useEffect(() => {
    const handleResize = () => {
      const newWidth = Math.min(document.getElementById('chart-container').clientWidth, 800);
      setChartWidth(newWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="chart-container" className="w-100">
      {isNoData ? (
        <NoDataAvailable />
      ) : (
        <PieChart width={chartWidth} height={360}>
          <Pie data={data} dataKey="ttl" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name, props) => {
              const postType = data[name]?.post_type;
              return [`${postType}: ${value}`];
            }}
          />
          <Legend
            formatter={(value, entry) => {
              const postType = data[value]?.post_type;
              return postType;
            }}
          />
        </PieChart>
      )}
    </div>
  );
};

export default ChartsPie;