import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import TablePaginate from "../Components/TablePaginate";

const Invoices = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [allInvoices, setAllInvoices] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async (isLoader = true) => {
    isLoader && dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/user/stripe/invoices`).then((res) => {
      setAllInvoices(res.data.data);
      dispatch(setLoader(false));
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  };

  const filterData = allInvoices?.filter((item) => item);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  const handlePageClick = (limit, newOffset) => {
    setItemOffset(newOffset);
  };

  const downloadInvoice = (url) => {
    const element = document.createElement("a");
    element.href = url;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">All User Invoices ( {filterData.length || 0} )</h4>
      </div>
      <div className="res-table">
        {currentItems.length > 0 ? (
          <table className="custom-table table mt-20 mb-4">
            <thead>
              <tr>
                <th scope="col">Invoice Number</th>
                <th scope="col">Email</th>
                <th scope="col">Username</th>
                <th scope="col">Total</th>
                <th scope="col" className="th-action">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item, ind) => (
                <tr key={ind}>
                  <td>
                    <h6>{item?.number}</h6>
                  </td>
                  <td>
                    <h6>{item?.email}</h6>
                  </td>
                  <td>
                    <h6>{item?.username}</h6>
                  </td>
                  <td>
                    <h6>$ {item?.amount || "-"}</h6>
                  </td>
                  <td>
                    <div className="action-container">
                      <button className="addlly-primary btn-sm" onClick={() => { downloadInvoice(item?.invc_url) }}>
                        Download
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
    </div>
  );
};

export default Invoices;