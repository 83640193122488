import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from 'react-select';
import HtmlDiff from 'htmldiff-js';
import { Modal, Row, Col, Dropdown } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { HiOutlinePlus } from "react-icons/hi";
import { LuHistory } from "react-icons/lu";
import { GrClose } from "react-icons/gr";
import { TbRestore } from "react-icons/tb";
import { FaFilter, FaRegEye } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";

const addValidationSchema = Yup.object().shape({
  type: Yup.string().required("System prompt type is required."),
  aiType: Yup.string().required("System prompt ai type is required."),
  subType: Yup.string().required("System prompt sub type is required."),
  prompt: Yup.string().required("System prompt is required."),
});

const writerTypeOptions = [
  { label: "1-Click Blog", value: "1-Click Blog" },
  { label: "Blog Co-Pilot", value: "Blog Co-Pilot" },
  { label: "Social Media Post", value: "Social Media Post" },
  { label: "NewsLetters", value: "NewsLetters" },
  { label: "Press Release", value: "Press Release" },
  { label: "Media Library", value: "Media Library" },
]

const AiTypeOptionList = [
  { label: "OpenAi", value: "OpenAi" },
  { label: "Anthropic", value: "Anthropic" },
];

const SystemPromptsHistory = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [systemPromptsData, setSystemPromptsData] = useState({ data: [], length: 0 });
  const [systemPromptsPopup, setSystemPromptsPopup] = useState({ open: false, type: null });
  const [viewPromptPopup, setViewPromptPopup] = useState({ open: false, prompt: null });
  const [versionHistoryPopup, setVersionHistoryPopup] = useState({ open: false, data: [] });
  const [diffPromptText, setDiffPromptText] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");

  const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
    defaultValues: { aiType: "OpenAi" },
    resolver: yupResolver(addValidationSchema),
  });

  useEffect(() => {
    getSystemPromptsOfVoiceList(10, 0, currentStatus);
  }, []);

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      getSystemPromptsOfVoiceList(limit, offset, currentStatus);
    }
  };

  const handleOnChangeCurrentTab = async (val) => {
    if (val === currentStatus) {
      return false;
    }

    setSystemPromptsData({ data: [], length: 0 });
    setCurrentStatus(val);
    getSystemPromptsOfVoiceList(10, 0, val);
  }

  const handleCloseSystemPromptsPopup = () => {
    setSystemPromptsPopup({ open: false, type: null });
    setVersionHistoryPopup({ open: false, data: [] });
    reset({ type: "", aiType: "OpenAi" });
  }

  const getSystemPromptsOfVoiceList = async (limit, offset, type) => {
    try {
      setSystemPromptsData({ data: [], length: 0 });
      dispatch(setLoader(true));

      const queryParams = new URLSearchParams({ type, limit, offset }).toString();
      const response = await AxiosApi.get(`${apiBaseUrl}/api/admin/system-prompts/list?${queryParams}`);
      if (response && response.data && response.data.data && response.data.data.length > 0) {
        setSystemPromptsData(response.data);
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
      toast.error("Something went wrong!");
    }
  }

  const onSubmitSystemPrompt = async (data) => {
    try {
      dispatch(setLoader(true));
      const response = await AxiosApi.post(`${apiBaseUrl}/api/admin/system-prompts/create-or-update`, data);
      if (response && response.data) {
        toast.success(`System prompt ${data.id ? "updated" : "added"} successfully.`);
        dispatch(setLoader(false));
        handleCloseSystemPromptsPopup();
        getSystemPromptsOfVoiceList(10, 0, currentStatus);
      } else {
        toast.success("Something went wrong, Please try again.");
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
    }
  }

  const handleOnEditSystemPrompt = async (data) => {
    setSystemPromptsPopup({ open: true, type: "Edit" });
    reset({
      id: data?.id || "",
      type: data?.type || "",
      aiType: data?.ai_type || "",
      subType: data?.sub_type || "",
      prompt: data?.prompt || "",
    });
  }

  const handleGetSystemPromptVersions = async (item) => {
    try {
      setDiffPromptText("");
      if (item.id && item.id !== "") {
        dispatch(setLoader(true));
        const response = await AxiosApi.get(`${apiBaseUrl}/api/admin/system-prompts/versions/${item.id}`);
        if (response && response.data) {
          dispatch(setLoader(false));
          if (response.data.data && response.data.data.length > 0) {
            const version = response.data.data[0];
            handleSetSelectedVersionDiffText(version.name, response.data.data);
          }
          setVersionHistoryPopup({ open: true, ...response.data, selectedItem: item });
        } else {
          dispatch(setLoader(false));
          toast.success("Something went wrong, Please try again.");
        }
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
    }
  }

  const handleSetSelectedVersionDiffText = async (id, data) => {
    try {
      setDiffPromptText("");
      if (id && id !== "" && data && data.length > 0) {
        const version = data.find((ele) => ele.name === id);
        let modifiedHTML = await HtmlDiff.execute(version.old_prompt, version.new_prompt);
        modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">&nbsp;</ins>`, "");
        modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffins">`, `<ins style="color: #406619; background-color: #eaf2c2;  text-decoration: none;">`);
        modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">&nbsp;</ins>`, "");
        modifiedHTML = modifiedHTML.replaceAll(`<ins class="diffmod">`, `<ins style="color: #406619; background-color: #eaf2c2;  text-decoration: none;">`);

        modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">&nbsp;</del>`, "");
        modifiedHTML = modifiedHTML.replaceAll(`<del class="diffdel">`, `<del style="color: #b30000; background-color: #fadad7; text-decoration: line-through;">`);
        modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">&nbsp;</del>`, "");
        modifiedHTML = modifiedHTML.replaceAll(`<del class="diffmod">`, `<del style="color: #b30000; background-color: #fadad7; text-decoration: line-through;">`);

        modifiedHTML = modifiedHTML.replaceAll("<h2>", "h2").replaceAll("</h2>", "/h2").replaceAll("<h3>", "h3").replaceAll("</h3>", "/h3");
        setDiffPromptText(modifiedHTML);
        setVersionHistoryPopup((prev) => ({ ...prev, oldPrompt: version.old_prompt }));

        try {
          await new Promise(resolve => setTimeout(resolve, 200));
          const elements = document.querySelectorAll("ins, del");
          if (elements && elements.length > 0) {
            elements[0].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
          }
        } catch (error) {
        }
      }
    } catch (error) {
    }
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">OpenAi System Prompts ( {systemPromptsData.length || 0} )</h4>
          <div className="d-flex align-items-center gap-2">
            <Dropdown data-bs-theme="">
              <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" className="">
                <FaFilter /> <span className="mx-2">{currentStatus && currentStatus !== "" ? currentStatus : "Filter"}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {writerTypeOptions.map((item, index) => (
                  <Dropdown.Item as="button" key={index}
                    onClick={() => handleOnChangeCurrentTab(item.value)}
                    active={item.value === currentStatus ? true : false}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => handleOnChangeCurrentTab("")}>Clear Filter</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button type="button" className="addlly-primary py-2 px-3 ms-3" onClick={() => setSystemPromptsPopup({ open: true, type: "Add" })}>
              <HiOutlinePlus className="fs-6" /> <span>Add</span>
            </button>
          </div>
        </div>
        <div className="res-table">
          <table className="custom-table table mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">SubType</th>
                <th scope="col">AI Type</th>
                <th scope="col">Created At</th>
                <th scope="col">Updated At</th>
                <th className="text-center" scope="col">Action</th>
              </tr>
            </thead>
            {systemPromptsData && systemPromptsData.data && systemPromptsData.data.length > 0 && (
              <tbody>
                {systemPromptsData.data.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item?.type || "-"}</td>
                    <td>{item?.sub_type || "-"}</td>
                    <td>{item?.ai_type || "-"}</td>
                    <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                    <td>
                      {item?.updated_at === null ? '-' : moment(item?.updated_at).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" : moment(item?.updated_at).format("MMM DD YYYY - h:mm a")}
                    </td>
                    <td className="text-center">
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <button
                          className="btn btn-outline-info" type="button" onClick={() => setViewPromptPopup({ open: true, prompt: item?.prompt })}
                          data-tooltip-id="my-tooltip" data-tooltip-content="View Prompt" data-tooltip-place="bottom"
                        >
                          <FaRegEye className="fs-5" />
                        </button>
                        <button
                          className="btn btn-outline-primary" type="button" onClick={() => handleOnEditSystemPrompt(item)}
                          data-tooltip-id="my-tooltip" data-tooltip-content="Edit Prompt" data-tooltip-place="bottom"
                        >
                          <MdEdit className="fs-5" />
                        </button>
                        <button
                          className="btn btn-outline-dark" type="button" onClick={() => handleGetSystemPromptVersions(item)}
                          data-tooltip-id="my-tooltip" data-tooltip-content="Version History" data-tooltip-place="bottom"
                        >
                          <LuHistory className="fs-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {systemPromptsData && systemPromptsData.length === 0 && (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={systemPromptsData.length} handlePageChange={handleChangePageLoadData} />
      </div>

      {systemPromptsPopup && systemPromptsPopup.open && (
        <Modal size="xl" show={systemPromptsPopup.open} centered onHide={handleCloseSystemPromptsPopup}>
          <Modal.Header closeButton>
            <h4>{systemPromptsPopup?.type === "Edit" ? "Edit" : "Add"} System Prompt</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <form onSubmit={handleSubmit(onSubmitSystemPrompt)} className="">
              <Row>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Writer Type</label>
                    <Controller
                      control={control} name="type" {...register("type")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className={`custom-select ${errors.type ? "is-invalid" : ""}`}
                          classNamePrefix="custom-select" placeholder="Select Writer Type"
                          options={writerTypeOptions} isClearable={false} inputRef={ref}
                          value={writerTypeOptions.find(c => c.value === value)}
                          onChange={(e) => onChange(e.value)}
                          isDisabled={systemPromptsPopup?.type === "Edit" ? true : false}
                        />
                      )}
                    />
                    <div className="invalid-feedback">{errors.type?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>AI Type</label>
                    <Controller
                      control={control} name="aiType" {...register("aiType")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className={`custom-select ${errors.aiType ? "is-invalid" : ""}`}
                          classNamePrefix="custom-select" placeholder="Select Writer AI Type"
                          options={AiTypeOptionList} isClearable={false} inputRef={ref}
                          value={AiTypeOptionList.find(c => c.value === value)}
                          onChange={(e) => onChange(e.value)}
                        />
                      )}
                    />
                    <div className="invalid-feedback">{errors.aiType?.message}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-4">
                    <label>Writer Sub Type</label>
                    <input
                      name="subType" type="text" placeholder="Insert system prompt sub type" {...register("subType")}
                      className={`addllyForm-control ${errors.subType ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.subType?.message}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-4">
                    <label>Prompt</label>
                    <textarea
                      name="prompt" type="text" placeholder="Insert system prompt" {...register("prompt")}
                      className={`addllyForm-control fs-6 ${errors.prompt ? "is-invalid" : ""}`}
                      style={{ borderRadius: "12px", height: "40vh" }}
                    />
                    <div className="invalid-feedback">{errors.prompt?.message}</div>
                  </div>
                </Col>
              </Row>
              <div className="">
                <button className="addlly-primary ms-auto" type="submit" variant="primary">
                  Save
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {viewPromptPopup && viewPromptPopup.open && (
        <Modal size="xl" show={viewPromptPopup.open} centered onHide={() => setViewPromptPopup({ open: false, prompt: null })}>
          <Modal.Header closeButton>
            <h4>View Prompt</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <textarea
              name="prompt" type="text" placeholder="Insert system prompt" value={viewPromptPopup.prompt}
              className={`addllyForm-control rounded w-100 p-3 fs-6`} style={{ height: "40vh" }} disabled
            />
          </Modal.Body>
        </Modal>
      )}

      {versionHistoryPopup && versionHistoryPopup.open && (
        <Modal size="lg" show={versionHistoryPopup.open} centered onHide={handleCloseSystemPromptsPopup}>
          <Modal.Body className="addllyFormWrap">
            <Row className="mb-3 align-items-center">
              <Col sm="6">
                <Select
                  className={`custom-select`} classNamePrefix="custom-select" placeholder="Select prompt version"
                  options={versionHistoryPopup.options} defaultValue={versionHistoryPopup.options[0]} isClearable={false}
                  onChange={(e) => handleSetSelectedVersionDiffText(e.value, versionHistoryPopup.data)}
                />
              </Col>
              <Col sm="6" className="d-flex align-items-center justify-content-end gap-3">
                {versionHistoryPopup?.oldPrompt && versionHistoryPopup?.oldPrompt !== "" && (
                  <button className="btn btn-dark" type="button"
                    data-tooltip-id="my-tooltip" data-tooltip-content="Restore Version" data-tooltip-place="bottom"
                    onClick={() => onSubmitSystemPrompt({
                      id: versionHistoryPopup?.selectedItem?.id || "",
                      type: versionHistoryPopup?.selectedItem?.type || "",
                      subType: versionHistoryPopup?.selectedItem?.sub_type || "",
                      prompt: versionHistoryPopup?.oldPrompt || "",
                    })}
                  >
                    <TbRestore className="fs-5" />
                  </button>
                )}
                <button className="btn btn-dark" type="button" onClick={handleCloseSystemPromptsPopup}>
                  <GrClose className="fs-5" />
                </button>
              </Col>
            </Row>
            {diffPromptText && diffPromptText !== "" ? (
              <div
                dangerouslySetInnerHTML={{ __html: diffPromptText ? diffPromptText.replaceAll("\n", "<br />") : "" }}
                className="w-100 outlineTextarea textarea-content overflow-auto" style={{ height: "50vh" }} disabled
              />
            ) : (
              <NoDataAvailable text="Versions Not Available" />
            )}
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default SystemPromptsHistory;
