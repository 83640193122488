import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tree from "react-d3-tree";
import { toast } from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";

const TopicSuggestionsDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [treeGraphData, setTreeGraphData] = useState({});

  useEffect(() => {
    if (id && id !== "") {
      handleGetPressReleaseDetailsById(id);
    }
  }, []);

  const handleGetPressReleaseDetailsById = async (id) => {
    try {
      dispatch(setLoader(true));

      const response = await AxiosApi.get(`${apiBaseUrl}/api/topic-suggestions/get-detail/${id}`);
      if (response && response.data && response.data.length > 0) {
        const convertToArrayStructure = (data) => {
          if (!data || typeof data !== 'object') { return null; }

          if (Array.isArray(data)) {
            return { name: 'Parent', children: data.map(convertToArrayStructure) };
          }

          const result = {
            name: (data?.term || data?.question),
            children: []
          };

          if (data.results && Array.isArray(data.results)) {
            result.children = data.results.map(convertToArrayStructure);
          }

          return result;
        };

        let convertedObject = {};
        if (response.data && response.data.length > 0) {
          const singleTerm = response.data[0];
          convertedObject = convertToArrayStructure(singleTerm);
        }
        setTreeGraphData(convertedObject);

        const scrollWrapper = document.getElementById('scroll-wrapper');
        if (scrollWrapper) { scrollWrapper.scrollTo({ top: 0, behavior: 'smooth' }); }
      } else {
        toast.error("Topic suggestions content not generated.", { id: "Toast-01" });
      }
      dispatch(setLoader(false));
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  }

  const renderCustomNode = ({ nodeDatum }) => (
    <React.Fragment>
      <circle r="15"></circle>
      <g class="rd3t-label">
        <text class="rd3t-label__title" text-anchor="start" x="40">
          {nodeDatum.name}
        </text>
      </g>
    </React.Fragment>
  );

  return (
    <div className="border-radius-12 bg-white AIWriterBlog">
      <div className="AIWriterBlogHead pb-2">
        <div className="AIWriterBlogHeadTop">
          <h4>Topic Suggestions</h4>
        </div>
      </div>

      <Row className="">
        <Col sm="12">
          <div className="position-relative">
            <div className="tree-graph-wrapper">
              <Tree
                data={treeGraphData} renderCustomNodeElement={renderCustomNode}
                rootNodeClassName="node__root" branchNodeClassName="node__branch" leafNodeClassName="node__leaf"
                collapsible={false} zoomable={true} draggable={true} nodeSize={{ x: 500, y: 50 }}
                zoom={0.7} scaleExtent={{ min: 0.5, max: 2 }} translate={{ x: 200, y: 400 }}
              />
            </div>
            <div className="mt-3">
              <button type="button" className="addlly-outline" onClick={() => navigate(`/topic-suggestions`)}>
                <HiOutlineChevronLeft className="" /> <span>Back</span>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TopicSuggestionsDetails;
