import React, { useState } from "react";
import Editor from "@monaco-editor/react";
import js_beautify from "js-beautify";

const HtmlViewer = ({ value }) => {
  const [code] = useState(js_beautify.html_beautify(value));

  return (
    <Editor
      theme="vs-dark"
      height="100%"
      minHeight="100vh"
      className="editorMinHeight editorMinHeightHtml"
      defaultLanguage="html"
      defaultValue={code}
      options={{
        formatOnPase: true,
        formatOnType: true,
      }}
      disabled
    />
  );
};
export default HtmlViewer;
