import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { HiOutlineSearch } from "react-icons/hi";

const NewslettersHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTxt, setSearchTxt] = useState("");
  const [tableInfo, setTableInfo] = useState({ limit: 10, offset: 0 });

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [newslettersList, setNewslettersList] = useState({ data: [], length: 0 });

  useEffect(() => {
    handleGetNewslettersList(tableInfo.limit, tableInfo.offset);
  }, []);

  const handleGetNewslettersList = (limit, offset, search = "") => {
    setNewslettersList({ data: [], length: 0 });
    dispatch(setLoader(true));

    const queryString = new URLSearchParams({ limit, offset, search}).toString();
    AxiosApi.get(`${apiBaseUrl}/api/admin/news-letters/list?${queryString}`).then((res) => {
      setNewslettersList(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      setTableInfo((prev) => ({ ...prev, limit, offset }));
      handleGetNewslettersList(limit, offset, searchTxt);
    }
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Newsletters ( {newslettersList.length || 0} )</h4>
        <div>
            <div className="input-group">
              <input
                placeholder="Search Name..." type="text" className="form-control"
                onChange={(e) => setSearchTxt(e.target.value)}
              />
              <button className="btn btn-outline-secondary" type="button"
                onClick={() =>{handleGetNewslettersList(tableInfo.limit, tableInfo.offset, searchTxt)}}
              >
                <HiOutlineSearch className="fs-4" />
              </button>
            </div>
          </div>
      </div>
      <div className="res-table">
        {newslettersList && newslettersList.length > 0 ? (
          <table className="custom-table table table-hover mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Topic</th>
                <th scope="col">CTA</th>
                <th scope="col">Language</th>
                <th scope="col">User Name</th>
                <th scope="col">Created At</th>
                <th scope="col" className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {newslettersList.data && newslettersList.data.length > 0 && newslettersList.data.map((item, ind) => (
                <tr className="cursor-pointer" key={ind} onClick={() => navigate(`/news-letters/${item?.id}`)}>
                  <td>{item?.id}</td>
                  <td>
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
                      {item?.topic || "-"}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.keyword} data-tooltip-place="bottom">
                      {item?.cta || "-"}
                    </h6>
                  </td>
                  <td className="">{item?.lan || "-"}</td>
                  <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-primary" type="button" onClick={(e) => { e.stopPropagation(); navigate(`/news-letters/${item?.id}`); }}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={newslettersList.length} handlePageChange={handleChangePageLoadData} />
    </div>
  )
}

export default NewslettersHistory;
