import React, { useState, useEffect } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import { FacebookPostPreview, InstagramPostPreview, LinkedInPostPreview, SuggestionPopularPostsPreview, TwitterPostPreview } from "../Components/PostPreview";
import { setLoader } from "../store/reducer";
import Slider from "react-slick";
import { AxiosApi } from "../utility/axios";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";
import RefundRequestCredits from "../Components/RefundRequestCredits";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const SocialMediaPostDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const [postData, setPostData] = useState({});
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [postImagesModal, setPostImagesModal] = useState({ open: false });
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  useEffect(() => {
    if (params?.id) {
      dispatch(setLoader(true));
      AxiosApi.get(`${apiBaseUrl}/api/post-writer/postHistory/byId/${params?.id}?role=admin`).then(async (res) => {
        setPostData(res.data.data);
        dispatch(setLoader(false));

        if (res.data.data && res.data.data.trending_posts && JSON.parse(res.data.data.trending_posts)?.length > 0) {
          setTrendingPosts(JSON.parse(res.data.data.trending_posts));
        }
      }).catch((error) => {
        console.log(error);
        dispatch(setLoader(false));
      });
    }
  }, [params]);

  const handleViewAiImagesById = (subType) => {
    const obj = {
      type: "Social Media Post Writer", subType,
      articleId: params?.id ? params.id : "",
      userId: postData?.user_id ? postData.user_id : "",
    };

    setPostImagesModal({ open: true, isLoader: true });
    AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/get-images?${new URLSearchParams(obj).toString()}`).then((res) => {
      setTimeout(() => {
        setPostImagesModal({ open: true, isLoader: false, ...res.data });
      }, 1000);
    }).catch((error) => {
      setPostImagesModal({ open: false, isLoader: false });
      toast.error("Something went wrong, Please try again.");
    });
  };

  return (
    <div className="w-100">
      <div className="border-radius-12 bg-white AIWriterBlog">
        <div className="AIWriterBlogHead">
          <div className="AIWriterBlogHeadTop">
            <h3>Social Media Post Writer - {postData.post_type || "LinkedIn"} Post</h3>
          </div>
        </div>
        <div className="addllyFormWrap">
          <form>
            <Row>
              {postData?.insert_link ? (
                <Col sm="12" md="6">
                  <div className="form-group mb-4">
                    <label>Need a post crafted around your website link?</label>
                    <input
                      name="link" type="text" placeholder="Insert your link here and let's transform it into a compelling social media post!"
                      defaultValue={postData?.insert_link} className={`addllyForm-control`} disabled
                    />
                  </div>
                </Col>
              ) : (
                <React.Fragment>
                  <Col sm="12" md="12">
                    <div className="form-group mb-4">
                      <label>
                        {postData && postData.post_type === "Instagram" ? (
                          "What do you want to write about? Write a brief description of your photo or topic."
                        ) : (
                          "What do you want to write about? Write a brief description."
                        )}
                      </label>
                      <textarea
                        name="topic" type="text" rows="3" defaultValue={postData?.topic}
                        placeholder={postData?.post_type === "Instagram" ? "Example: First time trying..." : "Example: Best way to get the most out of..."}
                        className={`addllyForm-control`} style={{ borderRadius: "25px" }} disabled
                      />
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="form-group mb-4">
                      <label>One main, most relevant hashtag</label>
                      <input
                        name="keyword" type="text" placeholder="Example: Google"
                        className={`addllyForm-control`} disabled defaultValue={postData?.keyword}
                      />
                    </div>
                  </Col>
                </React.Fragment>
              )}
              <Col sm="12" md="6">
                <div className="form-group mb-4">
                  <label>Tone of Voice</label>
                  <input
                    name="keyword" type="text" placeholder="Select Tone of Voice"
                    className={`addllyForm-control`} disabled
                    defaultValue={postData?.tone_of_voice || ""}
                  />
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="form-group mb-4">
                  <label>Key Events Calendar</label>
                  <input
                    name="keyword" type="text" placeholder="Select Key Events Calendar"
                    className={`addllyForm-control`} disabled defaultValue={postData?.selected_event}
                  />
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="form-group mb-4">
                  <label>Add Call to Action</label>
                  <input
                    name="cta" type="text" placeholder="Insert Call to Action"
                    className={`addllyForm-control`} disabled defaultValue={postData?.cta}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="smartWriterTab">
                  <Tabs defaultActiveKey={"generatedPosts"} className="mb-3">
                    {postData && postData.sets_of_post && postData.sets_of_post !== "" && (
                      <Tab eventKey="generatedPosts" title="Generated Posts">
                        <Row className="mt-4">
                          {postData.sets_of_post && JSON.parse(postData.sets_of_post)?.map((item, index) => (
                            <Col sm={12} md={6} lg={4} key={index}>
                              <div className={`media-post-content ${postData?.selected_post === item ? "active" : ""}`}>
                                <pre>{item}</pre>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                    )}
                    {postData && postData.search_response && postData.search_response !== "" && (
                      <Tab eventKey="generatedLinks" title="Generated Links">
                        <ul className="seoOptBlogTopic google-web-wrapper mb-0">
                          {postData.search_response && JSON.parse(postData.search_response)?.map((item, ind) => (
                            <li key={ind} className="overflow-hidden">
                              <div className="checkListItem my-2 py-2" style={{ maxWidth: "100%", overflow: "hidden" }}>
                                <div className="d-flex align-items-center w-100">
                                  <Form.Check type="checkbox" className="custom-checkbox" disabled={!item.url || item.url === ""}
                                    checked={postData.selectedGoogleWebsite && JSON.parse(postData.selectedGoogleWebsite)?.find((item1) => item1.url === item.url) ? true : false}
                                  />
                                  <div className="ms-3 w-100">
                                    <h6 className="font-weight-500">{item.url}</h6>
                                    <h5 className="color-dark-blue fw-semibold mt-1 google-content">
                                      {item?.metaDescription ? item.metaDescription : item.content}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Tab>
                    )}
                    {postData && postData.generated_hashtags && postData.generated_hashtags !== "" && (
                      <Tab eventKey="generatedHashtags" title="Generated Hashtags">
                        <Row className="mt-4">
                          {postData.generated_hashtags && JSON.parse(postData.generated_hashtags)?.map((item, index) => (
                            <Col sm={12} md={6} lg={4} key={index}>
                              <div className="hashtag-content mb-3 d-flex"
                                style={postData?.selected_hashtags?.includes(item.tag) ? { border: "1px solid #0000ff" } : {}}
                              >
                                <div className="ms-3 hashtag-input">
                                  <h5>{item.tag}</h5>
                                  <p>{item.volume?.toLocaleString("en-US")}</p>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                    )}
                    {trendingPosts && trendingPosts.length > 0 && (
                      <Tab eventKey="trendingPosts" title="Trending Posts">
                        <Row className="mt-4">
                          <Col sm="12" className="addllyFormWrap custom-slider">
                            <Slider {...settings}>
                              {trendingPosts.filter((item) => item.text).slice(0, 5).map((post, index) => (
                                <div className={`suggestion-post-wrapper d-flex justify-content-center my-2`} key={index}>
                                  <SuggestionPopularPostsPreview postInfo={post} postType={postData.post_type} index={index} />
                                </div>
                              ))}
                            </Slider>
                          </Col>
                        </Row>
                      </Tab>
                    )}
                    {postData && postData.final_post && postData.final_post !== "" && (
                      <Tab eventKey="finalPost" title="Final Post and Preview">
                        <Row className="mt-4">
                          <Col sm={postData?.final_post ? 8 : 12}>
                            <div className="socialPostEditor">
                              <textarea
                                className="w-100 outlineTextarea" value={postData?.final_post} style={{ height: "60vh" }} disabled
                              />
                            </div>
                          </Col>
                          <Col sm={4}>
                            {postData.post_type === "Facebook" ? (
                              <FacebookPostPreview post={postData?.final_post} col={12} isUploadImage={true}
                                setUploadFileModal={() => handleViewAiImagesById(`${postData.post_type} Post`)}
                                preview_img={postData?.post_image && JSON.parse(postData?.post_image)?.data ? JSON.parse(postData.post_image).data : ""}
                              />
                            ) : postData.post_type === "Twitter" ? (
                              <TwitterPostPreview post={postData?.final_post} col={12} isUploadImage={true}
                                setUploadFileModal={() => handleViewAiImagesById(`${postData.post_type} Post`)}
                                preview_img={postData?.post_image && JSON.parse(postData?.post_image)?.data ? JSON.parse(postData.post_image).data : ""}
                              />
                            ) : postData.post_type === "Instagram" ? (
                              <InstagramPostPreview post={postData?.final_post} col={12} isUploadImage={true}
                                setUploadFileModal={() => handleViewAiImagesById(`${postData.post_type} Post`)}
                                preview_img={postData?.post_image && JSON.parse(postData?.post_image)?.data ? JSON.parse(postData.post_image).data : ""}
                              />
                            ) : (
                              <LinkedInPostPreview post={postData?.final_post} col={12} isUploadImage={true}
                                setUploadFileModal={() => handleViewAiImagesById(`${postData.post_type} Post`)}
                                preview_img={postData?.post_image && JSON.parse(postData?.post_image)?.data ? JSON.parse(postData.post_image).data : ""}
                              />
                            )}
                          </Col>
                        </Row>
                      </Tab>
                    )}
                    <Tab eventKey="refundRequests" title="Refund Credit">
                      {postData?.user_id && (
                        <RefundRequestCredits articleType={"Social Media Post Writer"} articleId={params?.id} userId={postData?.user_id} />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>

      {postImagesModal && postImagesModal.open && (
        <MediaLibraryImagesModal postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} />
      )}
    </div>
  );
};

export default SocialMediaPostDetails;
