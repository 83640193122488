import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Editor from "@monaco-editor/react";
import js_beautify from "js-beautify";

const CodeEditor = (props) => {
  const generatedFullArticle = useSelector(({ generatedFullArticle }) => generatedFullArticle);
  const [code, setCode] = useState();

  useEffect(() => {
    if (generatedFullArticle?.FAQschema) setCode(js_beautify(generatedFullArticle.FAQschema));
  }, [generatedFullArticle]);

  return (
    <Editor
      theme="vs-dark"
      height="100%"
      minHeight="100vh"
      className="editorMinHeight"
      defaultLanguage="json"
      value={props.code}
      options={{
        formatOnPase: true,
        formatOnType: true,
        readOnly: true,
      }}
    />
  );
};

export default CodeEditor;
