import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import moment from "moment";
import { Accordion, Col, Modal, Row } from "react-bootstrap";
import { HiOutlinePlus } from "react-icons/hi";
import { MdEdit } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { FaUserPlus } from "react-icons/fa6";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import TablePaginate from "../Components/TablePaginate";

const addValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  username: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
  email: Yup.string().required("Email is required"),
  company: Yup.string().required("Organization name is required"),
});

const Organizations = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [organizationList, setOrganizationList] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchTxt, setSearchTxt] = useState("");

  const [addOrEditOrganizationPopup, setAddOrEditOrganizationPopup] = useState(false);
  const [userListPopup, setUserListPopup] = useState({ open: false, data: [] });
  const [typePassword, setTypePassword] = useState(true);

  const { register, handleSubmit, formState: { errors }, getValues, reset, control, setError, clearErrors } = useForm({
    defaultValues: {},
    resolver: yupResolver(addValidationSchema),
  });

  const filterData = organizationList.filter((item) => item?.username?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  useEffect(() => {
    setSearchTxt("");
    handleGetAllOrganizationList();
  }, [])

  const handlePageClick = (limit, offset) => {
    setItemOffset(offset);
  };

  const handleGetAllOrganizationList = async () => {
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/organization/list`).then((response) => {
      if (response && response.data && response.data.length > 0) {
        setOrganizationList(response.data);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
    });
  }

  const onSubmitAddOrEditOrganization = async (data) => {
    dispatch(setLoader(true));
    const reqData = { ...data };
    reqData.role = data?.organization_id ? 'OrgUser' : 'OrgAdmin';
    reqData.organization_id = data?.organization_id ? data.organization_id : 0;
    reqData.is_active = data.status === 'active' ? 1 : 0;

    try {
      const response = await AxiosApi.post(`${apiBaseUrl}/api/organization/create-or-update`, reqData);
      if (response && response.data) {
        toast.success(`${data.organization_id ? "User" : "Organization"} ${data.id ? "updated" : "added"} successfully.`);
        setAddOrEditOrganizationPopup(false);
        dispatch(setLoader(false));
        handleGetAllOrganizationList();
        reset({ first_name: "" });
      } else {
        dispatch(setLoader(false));
        toast.error("Something went wrong, please try again.");
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
    }
  }

  const handleCheckOrganizationNameExist = async (e) => {
    const data = {
      username: getValues("username") || "",
      company: getValues("company") || "",
    };

    if (!data[e.target.name] || data[e.target.name] === '') {
      return false;
    }

    const response = await AxiosApi.post(`${apiBaseUrl}/api/user/check-name-email`, data);
    if (response && response.data) {
      if (response.data.userNameExist && e.target.name === "username") {
        setError("username", { type: "manual", message: "Username is already exist, try different username." });
      } else {
        clearErrors("username");
      }

      if (response.data.organizationExist && e.target.name === "company") {
        setError("company", { type: "manual", message: "Organization name is already exist, try different name." });
      } else {
        clearErrors("company");
      }
    }
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Organization History ( {filterData.length || 0} )</h4>
          <div className="d-flex align-items-center">
            <button
              type="button" className="addlly-primary py-2 px-3 ms-3"
              onClick={() => { reset({ first_name: "" }); setAddOrEditOrganizationPopup(true); }}
            >
              <span>Add</span> <HiOutlinePlus className="fs-6" />
            </button>
          </div>
        </div>
        <div className="res-table">
          {currentItems.length > 0 ? (
            <table className="custom-table table mt-20 mb-4">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Username</th>
                  <th scope="col">Organization Name</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((item, ind) => (
                  <React.Fragment>
                    <tr key={ind}>
                      <td>{item?.id}</td>
                      <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                      <td>{item?.email || "-"}</td>
                      <td>{item?.username}</td>
                      <td>{item?.company || "-"}</td>
                      <td>
                        {item?.created_at === null ? '-' : moment(item?.created_at).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" :
                          moment(item?.created_at).format("MMM DD YYYY - h:mm a")}
                      </td>
                      <td>
                        {item?.status === "active" ? (
                          <span className="badge text-bg-success">Active</span>
                        ) : (
                          <span className="badge text-bg-danger">Inactive</span>
                        )}
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <button
                            data-tooltip-id="my-tooltip" data-tooltip-content="Edit Organization" data-tooltip-place="bottom"
                            className="btn btn-outline-primary" onClick={() => {
                              const { users, ...resItem } = item;
                              setAddOrEditOrganizationPopup(true); reset(resItem);
                            }}
                          >
                            <MdEdit />
                          </button>
                          {item?.status === "active" && (
                            <button
                              data-tooltip-id="my-tooltip" data-tooltip-content="Add User" data-tooltip-place="bottom"
                              className="btn btn-outline-primary" onClick={() => {
                                setAddOrEditOrganizationPopup(true);
                                reset({ organization_id: item.id, company: item.company });
                              }}
                            >
                              <FaUserPlus />
                            </button>
                          )}
                          {item?.users && item.users.length > 0 && (
                            <button
                              data-tooltip-id="my-tooltip" data-tooltip-content="View Users" data-tooltip-place="bottom"
                              className="btn btn-outline-primary" onClick={() => { setUserListPopup({ open: true, data: item?.users }); }}
                            >
                              <GrView />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
      </div>

      {addOrEditOrganizationPopup && (
        <Modal show={addOrEditOrganizationPopup} size="lg" onHide={() => { reset({ first_name: "" }); setAddOrEditOrganizationPopup(false); }}>
          <Modal.Header closeButton>
            <h4>
              {getValues("id") && getValues("id") !== "" ? "Edit" : "Add"} {" "}
              {getValues("organization_id") && getValues("organization_id") !== "" ? "User" : "Organization"}
            </h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <form onSubmit={handleSubmit(onSubmitAddOrEditOrganization)} className="">
              <Accordion alwaysOpen defaultActiveKey={getValues("organization_id") && getValues("organization_id") !== "" ? ["1"] : ["0"]}>
                <Accordion.Item eventKey="0" style={errors.company ? { border: "1px solid rgba(220,53,69,1)" } : {}}>
                  <Accordion.Header>Organization Info</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm="6">
                        <div className="form-group mb-4">
                          <label>Organization Name</label>
                          <input
                            name="company" type="text" placeholder="Enter organization name" {...register("company")}
                            className={`addllyForm-control ${errors.company ? "is-invalid" : ""}`}
                            disabled={(getValues("id") || getValues("organization_id")) ? true : false}
                            onBlur={handleCheckOrganizationNameExist}
                          />
                          <div className="invalid-feedback">{errors.company?.message}</div>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" style={errors.username || errors.first_name || errors.last_name || errors.password || errors.email ? { border: "1px solid rgba(220,53,69,1)" } : {}}>
                  <Accordion.Header>{getValues("organization_id") && getValues("organization_id") !== "" ? "User" : "Admin"} Details</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col sm="6">
                        <div className="form-group mb-4">
                          <label>User Name</label>
                          <input
                            name="username" type="text" placeholder="Enter user name"{...register("username")}
                            className={`addllyForm-control ${errors.username ? "is-invalid" : ""}`}
                            disabled={getValues("id") ? true : false}
                          />
                          <div className="invalid-feedback">{errors.username?.message}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group mb-4">
                          <label>First Name</label>
                          <input
                            name="first_name" type="text" placeholder="Enter first name" {...register("first_name")}
                            className={`addllyForm-control ${errors.first_name ? "is-invalid" : ""}`}
                          />
                          <div className="invalid-feedback">{errors.first_name?.message}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group mb-4">
                          <label>Last Name</label>
                          <input
                            name="last_name" type="text" placeholder="Enter last name" {...register("last_name")}
                            className={`addllyForm-control ${errors.last_name ? "is-invalid" : ""}`}
                          />
                          <div className="invalid-feedback">{errors.last_name?.message}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group mb-4 position-relative">
                          <label>Password</label>
                          <input
                            name="password" type={typePassword ? "password" : "text"} placeholder="Enter password"
                            {...register("password")}
                            className={`addllyForm-control ${errors.password ? "is-invalid" : ""}`}
                          />
                          <button type="button" onClick={() => setTypePassword(!typePassword)} className="passwordIcon">
                            {!typePassword ? (
                              <AiOutlineEyeInvisible size={16} color="#50506A" />
                            ) : (
                              <AiOutlineEye size={16} color="#50506A" />
                            )}
                          </button>
                          <div className="invalid-feedback">{errors.password?.message}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group mb-4">
                          <label>Email</label>
                          <input
                            name="email" type="email" placeholder="Enter Email"{...register("email")}
                            className={`addllyForm-control ${errors.email ? "is-invalid" : ""}`}
                            disabled={getValues("id") ? true : false}
                          />
                          <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group mb-4">
                          <label>Phone Number</label>
                          <input
                            name="phone_number" type="phone" placeholder="Enter Phone Number"{...register("phone_number")}
                            className={`addllyForm-control ${errors.phone_number ? "is-invalid" : ""}`}
                          />
                          <div className="invalid-feedback">{errors.phone_number?.message}</div>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="form-group">
                          <div className="form-check form-switch">
                            <Controller
                              control={control} name="status" {...register("status")}
                              render={({ field: { onChange, value, name, ref } }) => (
                                <input
                                  className="form-check-input" type="checkbox" role="switch"
                                  checked={value === "active" ? true : false} id="flexSwitchCheckChecked"
                                  onChange={(e) => onChange(e.target.checked ? "active" : "inactive")}
                                />
                              )}
                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Active</label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                {(!getValues("organization_id") || getValues("organization_id") === "") && (
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Pricing Info</Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col sm="6">
                          <div className="form-group mb-4">
                            <label>Addlly Credits</label>
                            <input
                              name="credits" type="number" placeholder="Enter addlly credits" {...register("credits")}
                              className={`addllyForm-control ${errors.credits ? "is-invalid" : ""}`}
                            />
                            <div className="invalid-feedback">{errors.credits?.message}</div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="form-group mb-4">
                            <label>Per Credit Rate</label>
                            <input
                              name="credits_rate" type="number" placeholder="Enter per credit rate" {...register("credits_rate")}
                              className={`addllyForm-control ${errors.credits_rate ? "is-invalid" : ""}`}
                            />
                            <div className="invalid-feedback">{errors.credits_rate?.message}</div>
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
              <div className="mt-4">
                <button className="addlly-primary ms-auto" type="submit" variant="primary">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {userListPopup && userListPopup.open && (
        <Modal show={userListPopup.open} size="xl" onHide={() => { setUserListPopup({ open: false, data: [] }); }}>
          <Modal.Header closeButton>
            <h4>Organization User List</h4>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="res-table">
              {userListPopup.data && userListPopup.data.length > 0 ? (
                <table className="custom-table table my-3">
                  <thead>
                    <tr>
                      <th scope="col">Id</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Username</th>
                      <th scope="col">Organization Name</th>
                      <th scope="col">Created Date</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userListPopup.data.map((item, ind) => (
                      <React.Fragment>
                        <tr key={ind}>
                          <td>{item?.id}</td>
                          <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                          <td>{item?.email || "-"}</td>
                          <td>{item?.username}</td>
                          <td>{item?.company || "-"}</td>
                          <td>
                            {item?.created_at === null ? '-' : moment(item?.created_at).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" :
                              moment(item?.created_at).format("MMM DD YYYY - h:mm a")}
                          </td>
                          <td>
                            {item?.status === "active" ? (
                              <span className="badge text-bg-success">Active</span>
                            ) : (
                              <span className="badge text-bg-danger">Inactive</span>
                            )}
                          </td>
                          <td className="text-center">
                            <div className="d-flex align-items-center justify-content-center gap-2">
                              <button
                                data-tooltip-id="my-tooltip" data-tooltip-content="Edit User" data-tooltip-place="bottom"
                                className="btn btn-outline-primary" onClick={() => {
                                  const { users, ...resItem } = item;
                                  setAddOrEditOrganizationPopup(true); reset(resItem);
                                  setUserListPopup({ open: false, data: [] });
                                }}
                              >
                                <MdEdit />
                              </button>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataAvailable />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default Organizations;
