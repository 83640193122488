import toast from "react-hot-toast";
import { AxiosApi } from "./axios";
import { setUserRes, setArticleHistory, setUserList, setLoader, setArticleHistoryLength, setOptimizedBlogHistory, setSocialMediaPostHistory } from "../store/reducer";

const getApiBaseUrl = (store) => {
  let baseUrl = process.env.REACT_APP_API_URL_PROD;
  if (store && store() && store()?.apiBaseUrl) {
    baseUrl = store().apiBaseUrl;
  }

  return baseUrl;
}

export const getHistory = (limit = 10, offset = 0, search = "", sort = "id", order = "desc") => async (dispatch, store) => {
  dispatch(setLoader(true));
  const baseUrl = await getApiBaseUrl(store);
  AxiosApi.get(`${baseUrl}/api/articleHistory/list?limit=${limit}&offset=${offset}&search=${search}&sort=${sort}&order=${order}`).then((response) => {
    dispatch(setArticleHistory(response.data.data));
    dispatch(setArticleHistoryLength(response.data.length));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    toast.error("Something went wrong.");
    console.log(error);
  });
};

export const getUser = (status = "") => async (dispatch, store) => {
  dispatch(setLoader(true));
  const baseUrl = await getApiBaseUrl(store);
  AxiosApi.get(`${baseUrl}/user/list?status=${status}`).then((response) => {
    dispatch(setUserList(response.data));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    toast.error("Something went wrong.");
    console.log(error);
  });
};

export const getUserDetail = (userId) => async (dispatch, store) => {
  dispatch(setLoader(true));
  const baseUrl = await getApiBaseUrl(store);
  AxiosApi.get(`${baseUrl}/user/detail/${userId}`).then((response) => {
    dispatch(setUserRes(response.data));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    console.log(error);
    toast.error("something went wrong");
  });
};

export const getOptimizedBlogHistory = (limit = 10, offset = 0, search = "", sort = "id", order = "desc") => async (dispatch, store) => {
  dispatch(setLoader(true));
  const baseUrl = await getApiBaseUrl(store);
  AxiosApi.get(`${baseUrl}/api/articleOptimizedBlogHistory/list?limit=${limit}&offset=${offset}&search=${search}&sort=${sort}&order=${order}`).then((response) => {
    dispatch(setOptimizedBlogHistory(response.data));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    toast.error("Something went wrong.");
    console.log(error);
  });
};

export const getSocialMediaPostHistory = (limit = 10, offset = 0) => async (dispatch, store) => {
  dispatch(setLoader(true));
  const baseUrl = await getApiBaseUrl(store);
  AxiosApi.get(`${baseUrl}/api/socialMediaPostHistory/list?limit=${limit}&offset=${offset}`).then((response) => {
    dispatch(setSocialMediaPostHistory(response.data));
    dispatch(setLoader(false));
  }).catch((error) => {
    dispatch(setLoader(false));
    toast.error("Something went wrong.");
    console.log(error);
  });
};
