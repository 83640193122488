import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import toast from "react-hot-toast";
import { HiOutlinePlus } from "react-icons/hi";
import moment from "moment";
import { Col, Dropdown, Modal, Row } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import NoDataAvailable from "../Components/NoDataAvailable";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Select from 'react-select';
import TablePaginate from "../Components/TablePaginate";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().optional(),
  username: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
  email: Yup.string().optional(),
  role: Yup.string().required("Role is required"),
});

const roleOptions = [
  { label: "Admin", value: "admin" },
  { label: "Sales", value: "sales" },
  { label: "Reviewer", value: "reviewer" },
]

const Admins = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [adminList, setAdminList] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchTxt] = useState("");

  const [addOrEditAdminPopup, setAddOrEditAdminPopup] = useState(false);
  const [typePassword, setTypePassword] = useState(true);

  const { register, handleSubmit, formState: { errors }, getValues, setValue, reset, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(validationSchema),
  });

  const filterData = adminList.filter((item) => item?.username?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  useEffect(() => {
    handleGetAllAdminList();
  }, [])

  const handlePageClick = (limit, offset) => {
    setItemOffset(offset);
  };

  const handleGetAllAdminList = async () => {
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/admin/list`).then((response) => {
      if (response && response.data && response.data.length > 0) {
        setAdminList(response.data);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
    });
  }

  const onSubmitAddOrEditAdmin = (data) => {
    dispatch(setLoader(true));
    AxiosApi.post(`${apiBaseUrl}/api/admin/create-or-update`, data).then((response) => {
      toast.success("Admin added successfully.");
      setAddOrEditAdminPopup(false);
      dispatch(setLoader(false));
      handleGetAllAdminList();
      reset({ first_name: "" });
    }).catch(function (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
    });
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Admin History ( {filterData.length || 0} )</h4>
          <div className="d-flex align-items-center">
            <button onClick={() => setAddOrEditAdminPopup(true)} type="button" className="addlly-primary py-2 px-3 ms-3">
              <span>Add</span> <HiOutlinePlus className="fs-6" />
            </button>
          </div>
        </div>
        <div className="res-table">
          {currentItems.length > 0 ? (
            <table className="custom-table table mt-20 mb-4">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Username</th>
                  <th scope="col">Role</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Last Login</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((item, ind) => (
                  <tr key={ind}>
                    <td>
                      <h6>{item?.id}</h6>
                    </td>
                    <td>
                      <h6>{item?.first_name + " " + (item?.last_name || "")}</h6>
                    </td>
                    <td>
                      <h6>{item?.email || "-"}</h6>
                    </td>
                    <td>
                      <h6>{item?.username}</h6>
                    </td>
                    <td>
                      <h6>{item?.role || "-"}</h6>
                    </td>
                    <td>
                      <h6>
                        {item?.created_at === null ? '-' : moment(item?.created_at).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" :
                          moment(item?.created_at).format("MMM DD YYYY - h:mm a")}
                      </h6>
                    </td>
                    <td>
                      <h6>
                        {item?.last_login === null ? '-' : moment(item?.last_login).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" :
                          moment(item?.last_login).format("MMM DD YYYY - h:mm a")}
                      </h6>
                    </td>
                    <td>
                      {item?.status === "active" ? (
                        <span className="badge text-bg-success">Active</span>
                      ) : (
                        <span className="badge text-bg-danger">Inactive</span>
                      )}
                    </td>
                    <td>
                      <Dropdown align="end" className="actionDropdown">
                        <Dropdown.Toggle id="dropdown-basic">
                          <BsThreeDots />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="1" onClick={() => { setAddOrEditAdminPopup(true); reset(item); }}>
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
      </div>

      {addOrEditAdminPopup && (
        <Modal show={addOrEditAdminPopup} size="lg" onHide={() => { setAddOrEditAdminPopup(false); reset({ first_name: "" }) }}>
          <Modal.Header closeButton>
            <h4>{getValues("id") && getValues("id") !== "" ? "Edit" : "Add"} Admin</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <form onSubmit={handleSubmit(onSubmitAddOrEditAdmin)} className="">
              <Row>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <div className="d-flex gap-2 align-items-center">
                      <label>First Name</label>
                    </div>
                    <input
                      name="first_name" type="text" placeholder="Enter first name" {...register("first_name")}
                      className={`addllyForm-control ${errors.first_name ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <div className="d-flex gap-2 align-items-center">
                      <label>Last Name</label>
                    </div>
                    <input
                      name="last_name" type="text" placeholder="Enter last name" {...register("last_name")}
                      className={`addllyForm-control ${errors.last_name ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <div className="d-flex gap-2 align-items-center">
                      <label>User Name</label>
                    </div>
                    <input
                      name="username" type="text" placeholder="Enter user name"{...register("username")}
                      className={`addllyForm-control ${errors.username ? "is-invalid" : ""}`}
                      disabled={getValues("id") ? true : false}
                    />
                    <div className="invalid-feedback">{errors.username?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4 position-relative">
                    <div className="d-flex gap-2 align-items-center">
                      <label>Password</label>
                    </div>
                    <input
                      name="password" type={typePassword ? "password" : "text"} placeholder="Enter password"
                      {...register("password")}
                      className={`addllyForm-control ${errors.password ? "is-invalid" : ""}`}
                    />
                    <button type="button" onClick={() => setTypePassword(!typePassword)} className="passwordIcon">
                      {!typePassword ? (
                        <AiOutlineEyeInvisible size={16} color="#50506A" />
                      ) : (
                        <AiOutlineEye size={16} color="#50506A" />
                      )}
                    </button>
                    <div className="invalid-feedback">{errors.password?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <div className="d-flex gap-2 align-items-center">
                      <label>Email</label>
                    </div>
                    <input
                      name="email" type="email" placeholder="Enter Email"{...register("email")}
                      className={`addllyForm-control ${errors.email ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <div className="d-flex gap-2 align-items-center">
                      <label>Phone Number</label>
                    </div>
                    <input
                      name="phone_number" type="phone" placeholder="Enter Phone Number"{...register("phone_number")}
                      className={`addllyForm-control ${errors.phone_number ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.phone_number?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <div className="d-flex gap-2 align-items-center">
                      <label>Role</label>
                    </div>
                    <Controller
                      control={control} name="role" {...register("role")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className={`custom-select ${errors.role ? "is-invalid" : ""}`}
                          classNamePrefix="custom-select" placeholder="Select Role"
                          options={roleOptions} isClearable={false} inputRef={ref}
                          value={roleOptions.find(c => c.value === value)}
                          onChange={(e) => onChange(e.value)}
                        />
                      )}
                    />
                    <div className="invalid-feedback">{errors.role?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group my-4">
                    <div className="form-check form-switch">
                      <Controller
                        control={control} name="status" {...register("status")}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <input
                            className="form-check-input" type="checkbox" role="switch"
                            checked={value === "active" ? true : false} id="flexSwitchCheckChecked"
                            onChange={(e) => onChange(e.target.checked ? "active" : "inactive")}
                          />
                        )}
                      />
                      <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Active</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="mt-4">
                <button className="addlly-primary ms-auto" type="submit" variant="primary">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default Admins;
