import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, ToggleButton, Modal, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import moment from "moment";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";

const ErrorLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useParams();

  const [ErrorsData, setErrorsData] = useState({ data: [], length: 0 });
  const [currentTab, setCurrentTab] = useState(type);
  const [errorModal, setErrorModal] = useState({ open: false, data: "" });
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  useEffect(() => {
    setErrorsData({});
    setCurrentTab(type);
    if (type === "openAiFailed") {
      getOpenAiErrorLogsList(10, 0);
    } else {
      getErrorLogsList(10, 0);
    }
  }, [type]);

  const handleClose = () => {
    setErrorModal({ open: false, data: "" });
  }

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      if (type === "openAiFailed") {
        getOpenAiErrorLogsList(limit, offset);
      } else {
        getErrorLogsList(limit, offset);
      }
    }
  };

  const getErrorLogsList = (limit, offset) => {
    setErrorsData({});
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/admin/get-error-logs/list?limit=${limit}&offset=${offset}`).then((res) => {
      setErrorsData(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      console.log("Error :", error);
      dispatch(setLoader(false));
    });
  }

  const getOpenAiErrorLogsList = (limit, offset) => {
    setErrorsData({});
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/admin/get-openai-logs/list?limit=${limit}&offset=${offset}`).then((res) => {
      setErrorsData(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      console.log("Error :", error);
      dispatch(setLoader(false));
    });
  }

  const handleOnChangeCurrentTab = async (e) => {
    const val = e.currentTarget.value;
    setCurrentTab(val);
    navigate(`/error-logs/${val}`);

    // if (val === "openAiFailed") {
    //   getOpenAiErrorLogsList(10, 0);
    // } else {
    //   getErrorLogsList(10, 0);
    // }
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Error Logs ( {ErrorsData.length || 0} )</h4>
          <div>
            <ButtonGroup>
              <ToggleButton
                id={`errors`} type="radio" variant="outline-primary" name="creditTabButton" style={{ minWidth: "100px" }}
                value={"errors"} checked={currentTab === "errors"} onChange={handleOnChangeCurrentTab}
              >
                Errors
              </ToggleButton>
              <ToggleButton
                id={`openAiFailed`} type="radio" variant="outline-primary" name="creditTabButton" style={{ minWidth: "100px" }}
                value={"openAiFailed"} checked={currentTab === "openAiFailed"} onChange={handleOnChangeCurrentTab}
              >
                OpenAi Failed
              </ToggleButton>
            </ButtonGroup>
          </div>
        </div>
        <div className="res-table">
          <table className="custom-table table mt-20 mb-4">
            <thead>
              <tr>
                <th scope="col">Error</th>
                {type === "openAiFailed" ? (
                  <React.Fragment>
                    <th scope="col">Type</th>
                    <th scope="col">Sub Type</th>
                    <th scope="col">Article Id</th>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <th scope="col">Status Code</th>
                    <th scope="col">Function Name</th>
                  </React.Fragment>
                )}
                <th scope="col">User Name</th>
                <th scope="col">Error Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {ErrorsData && ErrorsData.data && ErrorsData.data.length > 0 && (
              <tbody>
                {ErrorsData.data.map((item, ind) => (
                  <tr key={ind}>
                    {type === "openAiFailed" ? (
                      <React.Fragment>
                        <td>
                          {item?.error ? (
                            <div style={{ width: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {item.error?.substring(0, 50)}
                            </div>
                          ) : "-"}
                        </td>
                        <td>
                          <div>{item?.article_type || "-"}</div>
                        </td>
                        <td>
                          <div>{item?.article_sub_type || "-"}</div>
                        </td>
                        <td>
                          <div>{item?.article_id || "-"}</div>
                        </td>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <td>
                          {item?.error && item?.status_code && typeof JSON.parse(item?.error) !== typeof undefined ? (
                            <div style={{ width: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                              {JSON.parse(item.error)?.substring(0, 50)}
                            </div>
                          ) : "-"}
                        </td>
                        <td>
                          <div>{item?.status_code || "-"}</div>
                        </td>
                        <td>
                          <div>{item?.function_name || "-"}</div>
                        </td>
                      </React.Fragment>
                    )}
                    <td>
                      <div>{item?.user_id || "-"}</div>
                    </td>
                    <td>
                      <div>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</div>
                    </td>
                    <td>
                      <button className="btn btn-primary" type="button"
                        onClick={() => setErrorModal({ open: true, data: item })}
                      >View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {ErrorsData && ErrorsData.data && ErrorsData.data.length === 0 && (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={ErrorsData.length} handlePageChange={handleChangePageLoadData} />
      </div>

      {errorModal.open && (
        <Modal centered size="xl" show={errorModal.open} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Error Log</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {type === "openAiFailed" ? (
              <div className="addllyFormWrap">
                <Row>
                  <Col sm="12" md="12">
                    <div className="form-group mb-4">
                      <label>Error</label>
                      <pre className="fs-6"
                        style={{
                          whiteSpace: "break-spaces",
                          border: "1px solid var(--Dark-dark_5)",
                          padding: "14px",
                          borderRadius: "8px",
                          height: "20vh"
                        }}
                      >
                        {errorModal?.data?.error?.replace(/ +/g, ' ') || "-"}
                      </pre>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="form-group mb-4">
                      <label>System Prompt</label>
                      <pre className="fs-6"
                        style={{
                          whiteSpace: "break-spaces",
                          border: "1px solid var(--Dark-dark_5)",
                          padding: "14px",
                          borderRadius: "8px",
                          height: "40vh"
                        }}
                      >
                        {errorModal?.data?.system_prompt?.replace(/ +/g, ' ') || "-"}
                      </pre>
                    </div>
                  </Col>
                  <Col sm="12" md="6">
                    <div className="form-group mb-4">
                      <label>User Prompt</label>
                      <pre className="fs-6"
                        style={{
                          whiteSpace: "break-spaces",
                          border: "1px solid var(--Dark-dark_5)",
                          padding: "14px",
                          borderRadius: "8px",
                          height: "40vh"
                        }}
                      >
                        {errorModal?.data?.user_prompt?.replace(/ +/g, ' ') || "-"}
                      </pre>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <React.Fragment>
                {errorModal.data && errorModal.data.error && typeof JSON.parse(errorModal.data.error) !== typeof undefined ? (
                  <pre className="fs-6" style={{ whiteSpace: "break-spaces" }}>
                    {JSON.parse(errorModal.data.error)}
                  </pre>
                ) : "-"}
              </React.Fragment>
            )}
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ErrorLogs;
