import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { HiOutlineSearch } from "react-icons/hi";

const CmiNewsletterHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTxt, setSearchTxt] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [newslettersList, setNewslettersList] = useState({ data: [], length: 0 });

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = newslettersList && newslettersList.data ? newslettersList.data.slice(itemOffset, endOffset) : [];

  useEffect(() => {
    handleGetNewslettersList();
  }, []);

  const handleGetNewslettersList = (search = "") => {
    setNewslettersList({ data: [], length: 0 });
    dispatch(setLoader(true));

    const queryString = new URLSearchParams({ search, type: "cmi" }).toString();
    AxiosApi.get(`${apiBaseUrl}/api/admin/news-letters/list?${queryString}`).then((res) => {
      setNewslettersList(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      setItemOffset(offset);
    }
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">CMI Newsletters ( {newslettersList.length || 0} )</h4>
        <div>
          <div className="input-group">
            <input
              placeholder="Search Name..." type="text" className="form-control"
              onChange={(e) => setSearchTxt(e.target.value)}
            />
            <button className="btn btn-outline-secondary" type="button" onClick={() => { handleGetNewslettersList(searchTxt) }}>
              <HiOutlineSearch className="fs-4" />
            </button>
          </div>
        </div>
      </div>
      <div className="res-table">
        {newslettersList && newslettersList.length > 0 ? (
          <table className="custom-table table table-hover mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Topic</th>
                <th scope="col">Category</th>
                <th scope="col">User Name</th>
                <th scope="col">Created At</th>
                <th scope="col" className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 && currentItems.map((item, ind) => (
                <tr className="cursor-pointer" key={ind} onClick={() => navigate(`/cmi-newsletter/${item?.id}`)}>
                  <td>{item?.id}</td>
                  <td>
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
                      {item?.topic || "-"}
                    </h6>
                  </td>
                  <td>{item?.category || "-"}</td>
                  <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-primary" type="button" onClick={(e) => { e.stopPropagation(); navigate(`/cmi-newsletter/${item?.id}`); }}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      {newslettersList && newslettersList.length > 0 && (
        <TablePaginate length={newslettersList.length} handlePageChange={handleChangePageLoadData} />
      )}
    </div>
  )
}

export default CmiNewsletterHistory;
