import React from 'react'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import ReactPaginate from 'react-paginate';

const TablePaginate = ({ length, handlePageChange }) => {
    const itemsPerPage = 10;
    const pageCount = Math.ceil(length / itemsPerPage);

    const handleChangePageLoadData = (event) => {
        if (typeof event.nextSelectedPage === typeof undefined) return false;
        const selectedPage = event.nextSelectedPage ? event.nextSelectedPage : 0;
        const newOffset = (selectedPage * itemsPerPage);
        if (newOffset >= 0) {
            handlePageChange(itemsPerPage, newOffset);
        }
    };

    return (
        <div className="mt-4">
            <ReactPaginate
                breakLabel="..."
                nextLabel={<span>Next <MdOutlineArrowForwardIos className="pb-1" /></span>}
                onClick={handleChangePageLoadData}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                className="customPaginate"
                previousLabel={<span> <MdOutlineArrowBackIosNew className="pb-1" /> Previous</span>}
                renderOnZeroPageCount={null}
            />
        </div>
    )
}

export default TablePaginate;
