import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, ToggleButton, Modal, Form } from "react-bootstrap";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import TablePaginate from "../Components/TablePaginate";

const RefundCreditRequests = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [requestCredit, setRequestCredit] = useState([]);
  const [approveRejectModal, setApproveRejectModal] = useState(false);
  const [reqData, setReqData] = useState({ id: "", comment: "" });
  const [currentTab, setCurrentTab] = useState("pending");
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    getRequestHistory(currentTab);
  }, []);

  const filterData = requestCredit.filter((item) => item);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  const handlePageClick = (limit, newOffset) => {
    setItemOffset(newOffset);
  };

  const handleOpenRequestModal = (item, type) => {
    setApproveRejectModal(true);
    setReqData({ id: item?.id, type: type, comment: "" });
  };

  const handleCloseModal = () => {
    setApproveRejectModal(false);
    setReqData({ id: "", comment: "" });
  };

  const getRequestHistory = (status) => {
    setRequestCredit([]);
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/user/credit-transactions?status=${status}`).then((res) => {
      setRequestCredit(res.data);
      dispatch(setLoader(false));
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  }

  const handleApproveOrRejectRefundCredit = (id) => {
    dispatch(setLoader(true));
    const params = `status=${reqData.type}&comment=${reqData.comment}`;
    AxiosApi.get(`${apiBaseUrl}/api/refund-request/approved/${id}?${params}`).then((res) => {
      dispatch(setLoader(false)); handleCloseModal();
      getRequestHistory(currentTab);
    }).catch((e) => {
      dispatch(setLoader(false));
    });
  }

  const handleOnChangeCurrentTab = async (e) => {
    const val = e.currentTarget.value;
    setCurrentTab(val);
    setItemOffset(0);
    await getRequestHistory(val);
  };

  const handleNavigateViewArticle = (item) => {
    let navigateUrl = "";
    if (item?.type === "1-Click Blog") {
      navigateUrl = `/history-details/${item.article_id}`;
    } else if (item?.type === "Blog Co-Pilot") {
      navigateUrl = `/article-optimized-blog/${item.article_id}`;
    } else if (item?.type === "Social Media Post Writer") {
      navigateUrl = `/social-media-post/${item.article_id}`;
    } else if (item?.type === "NewsLetters") {
      navigateUrl = `/news-letters/${item.article_id}`;
    } else if (item?.type === "Press Release") {
      navigateUrl = `/press-release/${item.article_id}`;
    } else if (item?.type === "E-Commerce Blog") {
      navigateUrl = `/e-commerce/${item.article_id}`;
    } else if (item?.type === "Topic Suggestions") {
      navigateUrl = `/topic-suggestions/${item.article_id}`;
    }

    if (navigateUrl && navigateUrl !== "") {
      window.open(navigateUrl, "_blank")
    }
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">All User Refund Credit Requests ( {requestCredit?.length || 0} )</h4>
          <ButtonGroup>
            <ToggleButton
              id={`pending`} type="radio" variant="outline-primary" name="creditTabButton" style={{ width: "100px" }}
              value={"pending"} checked={currentTab === "pending"} onChange={handleOnChangeCurrentTab}
            >
              Pending
            </ToggleButton>
            <ToggleButton
              id={`approved`} type="radio" variant="outline-primary" name="creditTabButton" style={{ width: "100px" }}
              value={"approved"} checked={currentTab === "approved"} onChange={handleOnChangeCurrentTab}
            >
              Approved
            </ToggleButton>
            <ToggleButton
              id={`rejected`} type="radio" variant="outline-primary" name="creditTabButton" style={{ width: "100px" }}
              value={"rejected"} checked={currentTab === "rejected"} onChange={handleOnChangeCurrentTab}
            >
              Rejected
            </ToggleButton>
          </ButtonGroup>
        </div>
        <div className="res-table">
          {currentItems && currentItems.length > 0 ? (
            <table className="custom-table table mt-20 mb-4">
              <thead>
                <tr>
                  <th scope="col">User</th>
                  <th scope="col">Credit Request</th>
                  <th scope="col">Type</th>
                  <th scope="col">Sub Type</th>
                  <th scope="col">Article</th>
                  <th scope="col">Comment</th>
                  {(currentTab === "approved" || currentTab === "rejected") && (
                    <th scope="col">Admin Comment</th>
                  )}
                  <th scope="col">Created At</th>
                  <th scope="col" className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, ind) => (
                  <tr key={ind}>
                    <td>
                      <div>{item?.first_name + " " + (item?.last_name || "")}</div>
                      <p>{item?.username}</p> <p>{item?.email}</p>
                    </td>
                    <td>{item?.debit}</td>
                    <td>{item?.type}</td>
                    <td>{item?.sub_type}</td>
                    <td>
                      <h5 className="view-link fw-normal cursor-pointer" style={{ color: "#0d6efd" }} onClick={() => handleNavigateViewArticle(item)}>
                        View
                      </h5>
                    </td>
                    <td>
                      <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.comment} data-tooltip-place="bottom">
                        {item?.comment}
                      </h6>
                    </td>
                    {(currentTab === "approved" || currentTab === "rejected") && (
                      <td>
                        <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.admin_comment} data-tooltip-place="bottom">
                          {item?.admin_comment || "-"}
                        </h6>
                      </td>
                    )}
                    <td>
                      <h6>{moment(item?.created_at).format("MMM DD YYYY")}</h6>
                      <p>{moment(item?.created_at).format("hh:mm a")}</p>
                    </td>
                    <td>
                      <div className="text-center">
                        {item?.refund_status === "approved" ? (
                          "Approved"
                        ) : item?.refund_status === "rejected" ? (
                          "Rejected"
                        ) : (
                          <div className="d-flex">
                            <button onClick={() => handleOpenRequestModal(item, "approved")} className="addlly-primary me-3 py-2 px-3">
                              Approve
                            </button>
                            <button onClick={() => handleOpenRequestModal(item, "rejected")} className="addlly-primary bg-danger border-danger py-2 px-3">
                              Reject
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
      </div>

      {approveRejectModal && (
        <Modal centered show={approveRejectModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{reqData?.type === "approved" ? "Approve" : "Reject"} Credit Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addllyFormWrap">
              <div className="form-group mb-4">
                <label className="d-block">Comment</label>
                <Form.Control
                  as="textarea" rows={3} name="comment" type="text" value={reqData?.comment}
                  placeholder="Insert Comment" onChange={(e) => setReqData({ ...reqData, comment: e.target.value })}
                  className={`addllyForm-control d-block w-100 shadow-none`} style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="mt-4 d-flex justify-content-end">
                <button className="addlly-primary" type="button" variant="primary"
                  onClick={() => handleApproveOrRejectRefundCredit(reqData.id)}
                >
                  {reqData?.type === "approved" ? "Approve" : "Reject"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default RefundCreditRequests;
