import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { Dropdown, Modal } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineEye } from "react-icons/ai";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { HiOutlineSearch } from "react-icons/hi";

const AzgoNewsletterHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLogs, setShowLogs] = useState({});
  const [searchTxt, setSearchTxt] = useState("");
  const [tableInfo, setTableInfo] = useState({ limit: 10, offset: 0 });

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [azgoNewsletterHistory, setAzgoNewsletterHistory] = useState({
    data: [],
    length: 0,
  });

  useEffect(() => {
    handleGetAllAzgoNewsletterHistory(tableInfo.limit, tableInfo.offset);
  }, []);

  const handleGetAllAzgoNewsletterHistory = (limit, offset, search = "") => {
    setAzgoNewsletterHistory({ data: [], length: 0 });
    dispatch(setLoader(true));
    const queryString = new URLSearchParams({ limit, offset, search, }).toString();
    AxiosApi.get(`${apiBaseUrl}/api/azgo/getAllHistory?${queryString}`).then((res) => {
      setAzgoNewsletterHistory(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  };

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      setTableInfo((prev) => ({ ...prev, limit, offset }));
      handleGetAllAzgoNewsletterHistory(limit, offset, searchTxt);
    }
  };

  const actionBodyTemplate = (item) => {
    return (
      <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle id="dropdown-basic">
          <BsThreeDots />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item target="_blank" href={`/azgo-newsletter/${item.id}`} rel="noreferrer">
            View <AiOutlineEye />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const topicBodyTemplate = (item) => {
    return (
      <React.Fragment>
        <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
          {item?.topic ? item?.topic : "-"}
        </h6>
      </React.Fragment>
    );
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Azgo Newsletter ( {azgoNewsletterHistory.length || 0} )</h4>
        <div>
          <div className="input-group">
            <input
              placeholder="Search Name..." type="text"
              className="form-control" onChange={(e) => setSearchTxt(e.target.value)}
            />
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => {
                handleGetAllAzgoNewsletterHistory(tableInfo.limit, tableInfo.offset, searchTxt);
              }}
            >
              <HiOutlineSearch className="fs-4" />
            </button>
          </div>
        </div>
      </div>
      <DataTable
        value={azgoNewsletterHistory.data} className="table" emptyMessage={<NoDataAvailable />} selectionMode="single"
        dataKey="id" onSelectionChange={(e) => window.open(`/azgo-newsletter/${e.value?.id}`, "_blank")}
      >
        <Column header="Id" field="id" sortable></Column>
        <Column header="Topic" sortField="topic" body={topicBodyTemplate} sortable></Column>
        <Column
          header="Status" sortField="status" sortable
          body={(item) =>
            item?.status === "error" ? (
              <AiFillCloseCircle size={18} color="red" />
            ) : item?.status === "done" ? (
              <AiFillCheckCircle size={18} color="green" />
            ) : (
              item?.status === "pending" && <AiFillClockCircle size={18} color="#e7e721" />
            )
          }
        ></Column>
        <Column
          header="User Name" sortField="username" sortable
          body={(item) => item?.first_name + " " + (item?.last_name || "")}
        ></Column>
        <Column
          header="Created At" sortField="created_at" sortable
          body={(item) => moment(item?.created_at).format("MMM DD YYYY - h:mm a")}
        ></Column>
        <Column header="Action" body={actionBodyTemplate}></Column>
      </DataTable>

      <TablePaginate length={azgoNewsletterHistory.length} handlePageChange={handleChangePageLoadData} />
      <Modal className="" centered size="xl" show={showLogs?.open} onHide={() => setShowLogs({ open: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="me-4">Logs</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <ul>
            {showLogs &&
              showLogs.data &&
              typeof JSON.parse(showLogs.data) !== typeof undefined &&
              JSON.parse(showLogs.data).map((item, index) => (
                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AzgoNewsletterHistory;
