import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from "react-icons/ai";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { HiOutlineSearch } from "react-icons/hi";

const PressReleaseHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTxt, setSearchTxt] = useState("");
  const [tableInfo, setTableInfo] = useState({ limit: 10, offset: 0 });

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [pressReleaseList, setPressReleaseList] = useState({ data: [], length: 0 });

  useEffect(() => {
    handleGetPressReleaseList(tableInfo.limit, tableInfo.offset);
  }, []);

  const handleGetPressReleaseList = (limit, offset, search = "") => {
    setPressReleaseList({ data: [], length: 0 });
    dispatch(setLoader(true));

    const queryString = new URLSearchParams({ limit, offset, search }).toString();
    AxiosApi.get(`${apiBaseUrl}/api/admin/press-release/list?${queryString}`).then((res) => {
      setPressReleaseList(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      setTableInfo((prev) => ({ ...prev, limit, offset }));
      handleGetPressReleaseList(limit, offset, searchTxt);
    }
  };

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Press Release ( {pressReleaseList.length || 0} )</h4>
        <div>
          <div className="input-group">
            <input
              placeholder="Search Name..." type="text" className="form-control"
              onChange={(e) => setSearchTxt(e.target.value)}
            />
            <button className="btn btn-outline-secondary" type="button"
              onClick={() => { handleGetPressReleaseList(tableInfo.limit, tableInfo.offset, searchTxt); }}
            >
              <HiOutlineSearch className="fs-4" />
            </button>
          </div>
        </div>
      </div>
      <div className="res-table">
        {pressReleaseList && pressReleaseList.length > 0 ? (
          <table className="custom-table table table-hover mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Type</th>
                <th scope="col">Sub Type</th>
                <th scope="col" className="text-center">Status</th>
                <th scope="col">Language</th>
                <th scope="col">User Name</th>
                <th scope="col">Created At</th>
                <th scope="col" className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {pressReleaseList.data && pressReleaseList.data.length > 0 && pressReleaseList.data.map((item, ind) => (
                <tr className="cursor-pointer" key={ind} onClick={() => navigate(`/press-release/${item?.id}`)}>
                  <td>{item?.id}</td>
                  <td className="">{item?.type || "-"}</td>
                  <td className="">{item?.sub_type || "-"}</td>
                  <td className="text-center">
                    <span variant="success" data-tooltip-id="my-tooltip" data-tooltip-place="bottom"
                      data-tooltip-content={item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1)}
                    >
                      {(item?.status === "completed") ? (
                        <AiFillCheckCircle size={18} color="green" />
                      ) : item?.status === "notAcceptable" ? (
                        <AiFillCloseCircle size={18} color="#EF4444" />
                      ) : (item?.status === "pending") ? (
                        <AiFillClockCircle size={18} color="#e7e721" />
                      ) : null}
                    </span>
                  </td>
                  <td className="">{item?.lan || "-"}</td>
                  <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-primary" type="button" onClick={(e) => { e.stopPropagation(); navigate(`/press-release/${item?.id}`); }}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={pressReleaseList.length} handlePageChange={handleChangePageLoadData} />
    </div>
  )
}

export default PressReleaseHistory;
