import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import toast from "react-hot-toast";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import { Modal } from "react-bootstrap";

const DeductCreditsDetails = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [editConfigPopup, setEditConfigPopup] = useState({ open: false, data: {} });
  const [creditError, setCreditError] = useState("");
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  useEffect(() => {
    getAllDeductCreditHistory();
  }, []);

  const getAllDeductCreditHistory = async () => {
    try {
      dispatch(setLoader(true));
      const response = await AxiosApi.get(`${apiBaseUrl}/api/admin/deduct-credit-config/list`);
      dispatch(setLoader(false));
      if (response && response.data && response.data.length > 0) {
        setData(response.data);
      }
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  const handleOnEditCreditConfig = async () => {
    let error = "";
    if (isNaN(editConfigPopup?.data?.credit)) {
      error = "Credit must be a number";
    }
    if (Number(editConfigPopup?.data?.credit) <= 0) {
      error = "Credit must be a greater than 0";
    }
    if (!editConfigPopup?.data?.credit) {
      error = "Credit is invalid, insert a valid credit!";
    }

    setCreditError(error);
    if (error && error !== "") {
      return false;
    }

    const id = (editConfigPopup?.data?.id);
    if (!id) { return false; }

    try {
      dispatch(setLoader(true));
      const data = {
        credit: editConfigPopup.data.credit
      }
      await AxiosApi.post(`${apiBaseUrl}/api/admin/deduct-credit-config/update/${id}`, data);
      dispatch(setLoader(false));
      setEditConfigPopup({ open: false, data: {} });
      getAllDeductCreditHistory();
    } catch (error) {
      toast.error("Something went wrong, please try again!");
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Credits Config History</h4>
      </div>
      <div className='showplan-container mb-20'>
        <div className="res-table">
          <table className="custom-table table mt-20 mb-4">
            <thead>
              <tr>
                <th scope="">#</th>
                <th scope="">Type</th>
                <th scope="">Sub Type</th>
                <th scope="" className="text-center">Deduct Credits</th>
                <th scope="">Created At</th>
                <th scope="">Updated At</th>
                <th scope="" className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                <React.Fragment>
                  {data.map((item, ind) => (
                    <tr key={ind}>
                      <td>{item?.id || '-'}</td>
                      <td>{item?.type || '-'}</td>
                      <td>{item?.sub_type || '-'}</td>
                      <td className="text-center">{item?.credit || '-'}</td>
                      <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm A")}</td>
                      <td>{item?.updated_at === null ? '-' : moment(item?.updated_at).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" : moment(item?.updated_at).format("MMM DD YYYY - h:mm A")}</td>
                      <td className="text-center">
                        <button
                          type="button" className="addlly-outline py-2 px-3 mx-auto"
                          onClick={() => setEditConfigPopup({ open: true, data: { id: item.id, credit: item.credit } })}
                        >
                          <span>Edit</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ) : (
                <tr>
                  <td colSpan="7">
                    <NoDataAvailable />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {editConfigPopup && editConfigPopup.open && (
        <Modal show={editConfigPopup.open} onHide={() => { setEditConfigPopup({ open: false, data: {} }); setCreditError(""); }}>
          <Modal.Header closeButton>
            <h4>Edit Credits</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <div className="form-group mb-4">
              <label>Credit</label>
              <input
                name="credit" type="number" placeholder="Insert credit here" value={editConfigPopup?.data?.credit}
                onChange={(e) => setEditConfigPopup((prev) => ({ ...prev, data: { ...prev.data, credit: e.target.value } }))}
                className={`addllyForm-control ${creditError ? "is-invalid" : ""}`}
              />
              <div className="invalid-feedback">{creditError}</div>
            </div>
            <div className="mt-4">
              <button className="addlly-primary ms-auto" type="button" onClick={handleOnEditCreditConfig}>
                Save
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default DeductCreditsDetails;
