import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AxiosApi } from "../utility/axios";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";

const AzgoNewsletterDetails = () => {
  const params = useParams();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [azgoNewsletterData, setAzgoNewsletterData] = useState({});
  const [postImagesModal, setPostImagesModal] = useState({ open: false, });

  useEffect(() => {
    if (params?.id) {
      const articleID = { articleId: params?.id };
      const queryString = new URLSearchParams(articleID).toString();
      AxiosApi.get(`${apiBaseUrl}/api/azgo/getAllHistory?${queryString}`).then((res) => {
        setAzgoNewsletterData(res.data);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [params]);

  const editorInitObj = {
    height: "calc(100% - 32px)",
    menubar: false,
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar:
      "undo redo | blocks | " +
      "bold italic backcolor | alignleft aligncenter " +
      "alignright alignjustify | bullist numlist outdent indent | " +
      "removeformat | help",
    content_style: `${(typeof azgoNewsletterData?.response === "string" && azgoNewsletterData?.response.match(/<style>([\s\S]*?)<\/style>/i)?.[1]) || ""}`,
  };

  return (
    <div className="w-100">
      <div className="border-radius-12 bg-white AIWriterBlog">
        <div className="AIWriterBlogHead">
          <div className="AIWriterBlogHeadTop">
            <h3>Azgo Newsletter</h3>
          </div>
        </div>
        <div className="addllyFormWrap">
          <form>
            <Row>
              <Col md="12">
                <div className="form-group mb-4">
                  <label>Offer Description</label>
                  <textarea
                    name="generatedMetaDescription" type="text"
                    placeholder="Type Meta Description Generated" defaultValue={azgoNewsletterData?.topic}
                    className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
              <Row>
                <Col sm="12">
                  <label className="fw-semibold">Products Images</label>
                  <Row>
                    {azgoNewsletterData && azgoNewsletterData?.selected_products ? (
                      JSON.parse(azgoNewsletterData?.selected_products).map((product, index) => (
                        <Col sm="6" md="6" lg="3" xxl="3" className="pb-3" key={index}>
                          <div className={`image-wrapper`}>
                            <img src={product.image} alt={product.name} />
                            <div className="card-product-info">
                              <p>{product.name}</p>
                            </div>
                          </div>
                        </Col>
                      ))
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Row>

            {azgoNewsletterData?.status === "done" && (
              <Row>
                <Col xl={12}>
                  <div className="smartWriterTab">
                    <Tabs defaultActiveKey={"article"} className="mb-3 mt-4">
                      <Tab eventKey="article" title={<span className="active-tab-article">Newsletter</span>}>
                        <Row>
                          <Col xl={12} lg={12} md={12}>
                            <div className="position-relative form-group" style={{ minHeight: "100vh" }}>
                              <Editor
                                initialValue={azgoNewsletterData?.response}
                                apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                                init={editorInitObj} disabled
                              />
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              </Row>
            )}
          </form>
        </div>
      </div>

      {postImagesModal && postImagesModal.open && (
        <MediaLibraryImagesModal postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} />
      )}
    </div>
  );
};

export default AzgoNewsletterDetails;
