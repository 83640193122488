import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import HtmlViewer from "../Components/HtmlViewer";
import { AxiosApi } from "../utility/axios";
import { FacebookPostPreview, InstagramPostPreview, LinkedInPostPreview, TwitterPostPreview } from "../Components/PostPreview";
import SeoScore from "../Components/SeoScore";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";
const editorInitObj = {
    height: "calc(100% - 32px)",
    menubar: false,
    plugins: ["advlist autolink lists link image charmap print preview anchor", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
    toolbar: "undo redo | blocks | " + "bold italic backcolor | alignleft aligncenter " + "alignright alignjustify | bullist numlist outdent indent | " + "removeformat | help",
    content_style: "body { margin:24px; font-family: 'Jost', sans-serif; font-size:14px }"
};

const ECommerceBlogDetails = () => {
    const editorRef = useRef(null);
    const params = useParams();
    const [toggle, setToggle] = useState(false);
    const [ecommerceData, setEcommerceData] = useState({});
    const [postImagesModal, setPostImagesModal] = useState({
        open: false
    });
    const apiBaseUrl = useSelector(({
        apiBaseUrl
    }) => apiBaseUrl);
    useEffect(() => {
        if (params?.id) {
            const articleID = {
                articleId: params?.id
            };
            const queryString = new URLSearchParams(articleID).toString();
            AxiosApi.get(`${apiBaseUrl}/api/product-catalog/getAllHistory?${queryString}`).then(res => {
                setEcommerceData(res.data);
            }).catch(error => {
                console.log(error);
            });
        }
    }, [params]);

    const handleViewAiImagesById = subType => {
        const obj = {
            type: "Blog Co-Pilot",
            subType,
            articleId: params?.id ? params.id : "",
            userId: ecommerceData?.user_id ? ecommerceData.user_id : ""
        };
        setPostImagesModal({
            open: true,
            isLoader: true
        });
        AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/get-images?${new URLSearchParams(obj).toString()}`).then(res => {
            setTimeout(() => {
                setPostImagesModal({
                    open: true,
                    isLoader: false,
                    ...res.data
                });
            }, 1000);
        }).catch(error => {
            setPostImagesModal({
                open: false,
                isLoader: false
            });
            toast.error("Something went wrong, Please try again.");
        });
    };
    return <div className="w-100">
        <div className="border-radius-12 bg-white AIWriterBlog">
            <div className="AIWriterBlogHead">
                <div className="AIWriterBlogHeadTop">
                    <h3>Ecommerce</h3>
                </div>
            </div>
            <div className="addllyFormWrap">
                <form>
                    <Row>
                        <Col md="6">
                            <div className="form-group mb-4">
                                <div className="d-flex gap-2 align-items-center ">
                                    <label>Final Artical</label>
                                </div>
                                <input name="generatedTopic" type="text" placeholder="Type Topic Generated" defaultValue={ecommerceData?.final_topic} className={`addllyForm-control`} disabled />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="form-group mb-4">
                                <div className="d-flex gap-2 align-items-center ">
                                    <label>Meta title Generated</label>
                                </div>
                                <input name="generatedMetaTitle" type="text" placeholder="Type Meta title Generated" defaultValue={ecommerceData?.meta_title} className={`addllyForm-control`} disabled />
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="form-group mb-4">
                                <div className="d-flex gap-2 align-items-center ">
                                    <label>Meta Description Generated</label>
                                </div>
                                <textarea name="generatedMetaDescription" type="text" placeholder="Type Meta Description Generated" defaultValue={ecommerceData?.meta_dec} className={`addllyForm-control`} disabled />
                            </div>
                        </Col>
                        <Row >
                            <Col sm="12">
                                <div className="d-flex gap-2 align-items-center mb-2">
                                    <label className="fw-semibold">Products Images</label>
                                </div>

                                <Row>
                                    {ecommerceData && ecommerceData?.selected_products ? JSON.parse(ecommerceData?.selected_products).map((product, index) => (
                                        <Col sm="6" md="6" lg="3" xxl="3" className="pb-3" key={index}>
                                            <div className={`image-wrapper`}>
                                                <img src={product.image} alt={product.name} />
                                                <div className="card-product-info">
                                                    <p>{product.name}</p>
                                                </div>
                                            </div>
                                        </Col>
                                    )) : (
                                        <></>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Row>

                    {ecommerceData?.status === "completed" && <Row>
                        <Col xl={12}>
                            <div className="smartWriterTab">
                                <Tabs defaultActiveKey={"article"} className="mb-3 mt-4">
                                    <Tab eventKey="article" title={<span className="active-tab-article">Article</span>}>
                                        <Row>
                                            <Col xl={ecommerceData.SEO_score_response && Object.keys(ecommerceData.SEO_score_response).length > 0 ? 8 : 12} lg={12} md={12}>
                                                <div className="position-relative form-group" style={{
                                                    minHeight: "100vh"
                                                }}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <button type="button" className="content-view-btn" onClick={() => setToggle(!toggle)}>
                                                            {toggle ? <span>Go back to content</span> : <span>Go to HTML View</span>}
                                                        </button>
                                                        {!toggle ? <button type="button" className="images-view-btn" onClick={() => handleViewAiImagesById('Article Images')}>
                                                            Media Library
                                                        </button> : null}
                                                    </div>
                                                    {toggle ? <div className="html-content" style={{
                                                        height: "calc(100% - 32px)"
                                                    }}>
                                                        <HtmlViewer value={ecommerceData?.article_html} />
                                                    </div> : <Editor initialValue={ecommerceData?.withImageHtml || ecommerceData?.article_html} apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY} init={editorInitObj} disabled />}
                                                </div>
                                            </Col>
                                            {ecommerceData.SEO_score_response && JSON.parse(ecommerceData.SEO_score_response) && JSON.parse(ecommerceData.SEO_score_response)?.data && <SeoScore contentScore={JSON.parse(ecommerceData.SEO_score_response).data} />}
                                        </Row>
                                    </Tab>

                                    <Tab eventKey="linkedIn" title={<span className="d-flex align-items-center gap-2"><FaLinkedinIn /> Linkedin Post</span>}>
                                        <Row className="mt-5">
                                            <Col sm={ecommerceData?.linkedIn_post ? 8 : 12}>
                                                <div className="socialPostEditor">
                                                    <div className="position-relative">
                                                        <div className="custom-textarea-editor">
                                                            <div className="textarea-header">LinkedIn Post</div>
                                                            <textarea className="w-100 outlineTextarea textarea-content" value={ecommerceData?.linkedIn_post} style={{
                                                                height: "40vh"
                                                            }} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {ecommerceData?.linkedIn_post && <Col sm={4} className="pb-2">
                                                <LinkedInPostPreview post={ecommerceData?.linkedIn_post} preview_img={ecommerceData?.linkedIn_post_img} col={12} isUploadImage={true} setUploadFileModal={() => handleViewAiImagesById('LinkedIn Post')} />
                                            </Col>}
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="facebook" title={<span className="d-flex align-items-center gap-2"><FaFacebookF /> Facebook Post</span>}>
                                        <Row className="mt-5">
                                            <Col sm={ecommerceData?.facebook_post ? 8 : 12}>
                                                <div className="socialPostEditor">
                                                    <div className="position-relative">
                                                        <div className="custom-textarea-editor">
                                                            <div className="textarea-header">Facebook Post</div>
                                                            <textarea className="w-100 outlineTextarea textarea-content" value={ecommerceData?.facebook_post} style={{
                                                                height: "40vh"
                                                            }} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {ecommerceData?.facebook_post && <Col sm={4} className="pb-2">
                                                <FacebookPostPreview post={ecommerceData?.facebook_post} preview_img={ecommerceData?.facebook_post_img} col={12} isUploadImage={true} setUploadFileModal={() => handleViewAiImagesById('Facebook Post')} />
                                            </Col>}
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="twitter" title={<span className="d-flex align-items-center gap-2"><FaXTwitter /> Twitter Post</span>}>
                                        <Row className="mt-5">
                                            <Col sm={ecommerceData?.twitter_post ? 8 : 12}>
                                                <div className="socialPostEditor">
                                                    <div className="position-relative">
                                                        <div className="custom-textarea-editor">
                                                            <div className="textarea-header">Twitter Post</div>
                                                            <textarea className="w-100 outlineTextarea textarea-content" value={ecommerceData?.twitter_post} style={{
                                                                height: "40vh"
                                                            }} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {ecommerceData?.twitter_post && <Col sm={4} className="pb-2">
                                                <TwitterPostPreview post={ecommerceData?.twitter_post} preview_img={ecommerceData?.twitter_post_img} col={12} isUploadImage={true} setUploadFileModal={() => handleViewAiImagesById('Twitter Post')} />
                                            </Col>}
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="instagram" title={<span className="d-flex align-items-center gap-2"><FaInstagram /> Instagram Post</span>}>
                                        <Row className="mt-5">
                                            <Col sm={ecommerceData?.instagram_post ? 8 : 12}>
                                                <div className="socialPostEditor">
                                                    <div className="position-relative">
                                                        <div className="custom-textarea-editor">
                                                            <div className="textarea-header">Instagram Post</div>
                                                            <textarea className="w-100 outlineTextarea textarea-content" value={ecommerceData?.instagram_post} style={{
                                                                height: "40vh"
                                                            }} disabled />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {ecommerceData?.instagram_post && <Col sm={4} className="pb-2">
                                                <InstagramPostPreview post={ecommerceData?.instagram_post} preview_img={ecommerceData?.instagram_post_img} col={12} isUploadImage={true} setUploadFileModal={() => handleViewAiImagesById('Instagram Post')} />
                                            </Col>}
                                        </Row>
                                    </Tab>

                                </Tabs>
                            </div>
                        </Col>
                    </Row>}
                </form>
            </div>
        </div>

        {postImagesModal && postImagesModal.open && <MediaLibraryImagesModal postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} />}
    </div>;
};

export default ECommerceBlogDetails;