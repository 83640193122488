import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import { Dropdown, Modal } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineEye } from "react-icons/ai";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { HiOutlineSearch } from "react-icons/hi";

const ECommerceHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginDetails = useSelector(({ loginDetails }) => loginDetails);
    const [showLogs, setShowLogs] = useState({});
    const [searchTxt, setSearchTxt] = useState("");
    const [tableInfo, setTableInfo] = useState({ limit: 10, offset: 0 });

    const apiBaseUrl = useSelector(({
        apiBaseUrl
    }) => apiBaseUrl);
    const [ecommerceHistory, setEcommerceHistory] = useState({
        data: [],
        length: 0
    });

    useEffect(() => {
        handleGetAllEcommerceHistory(tableInfo.limit, tableInfo.offset);
    }, []);

    const handleGetAllEcommerceHistory = (limit, offset, search = "") => {
        setEcommerceHistory({
            data: [],
            length: 0
        });
        dispatch(setLoader(true));
        const queryString = new URLSearchParams({
            limit,
            offset,
            search
        }).toString();
        AxiosApi.get(`${apiBaseUrl}/api/product-catalog/getAllHistory?${queryString}`).then(res => {
            setEcommerceHistory(res.data);
            dispatch(setLoader(false));
        }).catch(error => {
            toast.error("Something went wrong.");
            dispatch(setLoader(false));
        });
    };

    const handleChangePageLoadData = (limit, offset) => {
        if (offset >= 0) {
            setTableInfo((prev) => ({ ...prev, limit, offset }));
            handleGetAllEcommerceHistory(limit, offset, searchTxt);
        }
    };

    const handleDisplayError = (item) => {
        try {
            const error = item.error ? JSON.parse(item.error) : {};
            if (error.code === "Timeout") {
                return "System is overloaded. Please try again in some time."
            } else {
                if (error.message.indexOf("Azure") !== -1 || error.message.indexOf("filtered") !== -1) {
                    return "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic."
                }
                return error.message;
            }
        } catch (error) {
            return item.error;
        }
    }

    const actionBodyTemplate = (item) => {
        return (
            <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle id="dropdown-basic">
                    <BsThreeDots />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item target="_blank" href={`/e-commerce/${item.id}`} rel="noreferrer">
                        View <AiOutlineEye />
                    </Dropdown.Item>
                    {/* {loginDetails && loginDetails.role === "admin" && (
                        <Dropdown.Item >Delete</Dropdown.Item>
                    )} */}
                    <Dropdown.Item onClick={() => { setShowLogs({ open: true, data: item.logs, id: item.id }) }}>
                        View Logs
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const topicBodyTemplate = (item) => {
        return (
            <React.Fragment>
                <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.meta_title} data-tooltip-place="bottom">
                    {item?.meta_title ? item?.meta_title : "-"}
                </h6>
            </React.Fragment>
        );
    };

    return (
        <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
            <div className="d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Ecommerce ( {ecommerceHistory.length || 0} )</h4>
                <div>
                    <div className="input-group">
                        <input
                            placeholder="Search Name..." type="text" className="form-control"
                            onChange={(e) => setSearchTxt(e.target.value)}
                        />
                        <button className="btn btn-outline-secondary" type="button"
                            onClick={() => { handleGetAllEcommerceHistory(tableInfo.limit, tableInfo.offset, searchTxt); }}
                        >
                            <HiOutlineSearch className="fs-4" />
                        </button>
                    </div>
                </div>
            </div>
            <DataTable value={ecommerceHistory.data} className="table" emptyMessage={<NoDataAvailable />} selectionMode="single" dataKey="id" onSelectionChange={(e) => window.open(`/e-commerce/${e.value?.id}`, "_blank")}>
                <Column header="Id" field="id" sortable></Column>
                <Column header="Topic" sortField="topic" body={topicBodyTemplate} sortable></Column>
                <Column header="AI Type" sortField="ai_type" body={(item) => item.AI_type} sortable></Column>
                <Column header="Language" sortField="language" body={(item) => item?.lan || "English"} sortable></Column>
                <Column header="Status" sortField="status" body={(item) => item?.status === "error" ? (
                    <AiFillCloseCircle size={18} color="red" />
                ) : (item?.status === "completed") ? (
                    <AiFillCheckCircle size={18} color="green" />
                ) : (
                    item?.status === "pending" && <AiFillClockCircle size={18} color="#e7e721" />
                )} sortable>
                </Column>
                <Column header="SEO score" sortfiled="SEO score" body={(item) => {
                    const res = item.SEO_score_response ? JSON.parse(item.SEO_score_response) : {};
                    const overSEOScore = res?.data?.Overview["Overall SEO score"];
                    return overSEOScore ? `${overSEOScore} %` : "-";
                }}></Column>
                <Column header="User Name" sortField="username" body={(item) => item?.first_name + " " + (item?.last_name || "")} sortable></Column>
                <Column header="Created At" sortField="created_at" body={(item) => moment((item?.created_at)).format("MMM DD YYYY - h:mm a")} sortable></Column>
                <Column header="Action" body={actionBodyTemplate}></Column>
            </DataTable>

            <TablePaginate length={ecommerceHistory.length} handlePageChange={handleChangePageLoadData} />
            <Modal className="" centered size="xl" show={showLogs?.open} onHide={() => setShowLogs({ open: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="me-4">Logs</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <ul>
                        {showLogs && showLogs.data && typeof JSON.parse(showLogs.data) !== typeof undefined && JSON.parse(showLogs.data).map((item, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>
        </div >
    );
};

export default ECommerceHistory;