import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";

const MediaLibraryImagesHistory = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [imageListData, setImageListData] = useState({ data: [], length: 0 });
  const [postImagesModal, setPostImagesModal] = useState({ open: false });
  const [currentStatus, setCurrentStatus] = useState("");

  useEffect(() => {
    setImageListData({ length: 0 });
    getMediaLibraryImageList(10, 0, currentStatus);
  }, []);

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      getMediaLibraryImageList(limit, offset, currentStatus);
    }
  };

  const getMediaLibraryImageList = (limit, offset, type) => {
    setImageListData({ data: [], length: 0 });
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/list?limit=${limit}&offset=${offset}&type=${type}`).then((res) => {
      setImageListData(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      console.log("Error :", error);
      dispatch(setLoader(false));
    });
  }

  const handleOnChangeCurrentTab = async (val) => {
    setImageListData({ data: [], length: 0 });
    setCurrentStatus(val);

    if (val === "list") {
      setCurrentStatus("");
      getMediaLibraryImageList(10, 0, "");
    } else {
      getMediaLibraryImageList(10, 0, val);
    }
  }

  const handleViewAiImagesById = (item) => {
    const obj = {
      type: item?.article_type ? item.article_type : "",
      subType: item?.article_sub_type ? item.article_sub_type : "",
      articleId: item?.article_id ? item.article_id : "",
      userId: item?.user_id ? item.user_id : "",
    };

    setPostImagesModal({ open: true, isLoader: true });
    AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/get-images?${new URLSearchParams(obj).toString()}`).then((res) => {
      setTimeout(() => {
        setPostImagesModal({ open: true, isLoader: false, ...res.data });
      }, 1000);
    }).catch((error) => {
      setPostImagesModal({ open: false, isLoader: false });
      toast.error("Something went wrong, Please try again.");
    });
  };

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Media Library Image ( {imageListData.length || 0} )</h4>
          <div className="d-flex align-items-center">
            <Dropdown data-bs-theme="">
              <Dropdown.Toggle id="" variant="primary" className="">
                <FaFilter /> <span className="mx-2">Filter</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {[
                  { label: "1-Click Blog", value: "1-Click Blog" },
                  { label: "Blog Co-Pilot", value: "Blog Co-Pilot" },
                  { label: "Social Media Post Writer", value: "Social Media Post Writer" },
                  { label: "NewsLetters", value: "NewsLetters" },
                  { label: "Press Release", value: "Press Release" },
                ].map((item, index) => (
                  <Dropdown.Item as="button" key={index}
                    onClick={() => handleOnChangeCurrentTab(item.value)}
                    active={item.value === currentStatus ? true : false}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => handleOnChangeCurrentTab("list")}>Clear Filter</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="res-table">
          <table className="custom-table table mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">User Name</th>
                <th scope="col">Article ID</th>
                <th scope="col">Article Type</th>
                <th scope="col">Article SubType</th>
                <th scope="col">Create At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {imageListData && imageListData.data && imageListData.data.length > 0 && (
              <tbody>
                {imageListData.data.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item?.id || ""}</td>
                    <td>{item?.first_name || ""} {item?.last_name || ""}</td>
                    <td>{item?.article_id || "-"}</td>
                    <td>{item?.article_type || "-"}</td>
                    <td>{item?.article_sub_type || "-"}</td>
                    <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                    <td>
                      <button className="btn btn-primary" type="button" onClick={() => handleViewAiImagesById(item)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {imageListData && imageListData.length === 0 && (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={imageListData.length} handlePageChange={handleChangePageLoadData} />
      </div>

      {postImagesModal && postImagesModal.open && (
        <MediaLibraryImagesModal postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} />
      )}
    </React.Fragment>
  )
}

export default MediaLibraryImagesHistory;
