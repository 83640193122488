import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Badge } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { setLoader } from "../store/reducer";
import TablePaginate from "../Components/TablePaginate";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";

const ReviewArticleOrPostHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [reviewArticleList, setReviewArticleList] = useState({ data: [], length: 0 });
  const [articleType, setArticleType] = useState("");
  const [infoModalData, setInfoModalData] = useState({ open: false, data: null });

  useEffect(() => {
    let articleTypeTemp = "1-Click Blog";
    if (location && (location.pathname === "/article-optimized-blog" || location.pathname === "/article-optimized-blog/")) {
      articleTypeTemp = "Blog Co-Pilot";
    } else if (location && (location.pathname === "/social-media-post" || location.pathname === "/social-media-post/")) {
      articleTypeTemp = "Social Media Post Writer";
    } else if (location && (location.pathname === "/news-letters" || location.pathname === "/news-letters/")) {
      articleTypeTemp = "NewsLetters";
    } else if (location && (location.pathname === "/press-release" || location.pathname === "/press-release/")) {
      articleTypeTemp = "Press Release";
    }

    if (articleType !== articleTypeTemp) {
      setArticleType(articleTypeTemp);
      handleGetReviewListByArticleType(articleTypeTemp, 10, 0);
    }
  }, [location]);

  const handleGetReviewListByArticleType = (type, limit, offset) => {
    setReviewArticleList({ data: [], length: 0 });
    dispatch(setLoader(true));

    const queryString = new URLSearchParams({ articleType: type, limit, offset }).toString();
    AxiosApi.get(`${apiBaseUrl}/api/editorial-comment/list/byArticleType?${queryString}`).then((res) => {
      setReviewArticleList(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      handleGetReviewListByArticleType(articleType, limit, offset);
    }
  };

  const handleCloseInfoModal = () => {
    setInfoModalData({ open: false, data: "" });
  }

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Review {articleType} Articles ( {reviewArticleList.length || 0} )</h4>
      </div>
      <div className="res-table">
        {reviewArticleList && reviewArticleList.length > 0 ? (
          <table className="custom-table table table-hover mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Topic</th>
                <th scope="col">Keyword</th>
                <th scope="col" className="text-center">Remaining Comments</th>
                <th scope="col">User Name</th>
                {/* <th scope="col">User Email</th> */}
                <th scope="col">Created At</th>
                <th scope="col">Last Updated At</th>
                <th scope="col" className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {reviewArticleList.data && reviewArticleList.data.length > 0 && reviewArticleList.data.map((item, ind) => (
                <tr className="cursor-pointer" key={ind}
                  onClick={() => navigate(`/review-article/${articleType}/${item?.article_id}?sub-type=${item.article_sub_type}`)}
                >
                  <td>{item?.id}</td>
                  <td>
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
                      {item?.topic || "-"}
                    </h6>
                  </td>
                  <td>
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.keyword} data-tooltip-place="bottom">
                      {item?.keyword || "-"}
                    </h6>
                  </td>
                  <td className="text-center">
                    {item?.remaining_comments ? (
                      <Badge className="fs-6" pill
                        bg={item.remaining_comments > 4 ? "danger" : "warning"}
                        text={item.remaining_comments > 4 ? "white" : "dark"}
                      >
                        {item?.remaining_comments}
                      </Badge>
                    ) : "-"}
                  </td>
                  <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                  {/* <td>{item?.email || "-"}</td> */}
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td>{moment(item?.updated_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-info me-3" type="button"
                      onClick={(e) => { e.stopPropagation(); setInfoModalData({ open: true, data: item }) }}
                    >
                      Info
                    </button>
                    <button className="btn btn-primary" type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/review-article/${articleType}/${item?.article_id}?sub-type=${item.article_sub_type}`)
                      }}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={reviewArticleList.length} handlePageChange={handleChangePageLoadData} />

      {infoModalData.open && (
        <Modal centered size="" show={infoModalData.open} onHide={handleCloseInfoModal}>
          <Modal.Header closeButton>
            <Modal.Title>Request for Editing Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <h4 className="fw-normal text-black-50">What would you like our editors to look at?</h4>
              <div>{infoModalData?.data?.our_editors_to_look_at}</div>
            </div>
            <div className="mb-4">
              <h4 className="fw-normal text-black-50">What would you like us to look at?</h4>
              <ul>
                {infoModalData?.data?.like_us_to_look_at.split(",").map((ele, index) => (
                  <li key={index}>{ele}</li>
                ))}
              </ul>
            </div>
            {infoModalData?.data?.specify_like_us_to_look_at && infoModalData?.data?.specify_like_us_to_look_at !== "" && (
              <div className="mb-4">
                <h4 className="fw-normal text-black-50">Specify like us to look at?</h4>
                <div>{infoModalData?.data?.specify_like_us_to_look_at}</div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default ReviewArticleOrPostHistory;
