import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import moment from "moment";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import TablePaginate from "../Components/TablePaginate";

const ContactUsList = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [contactUsData, setContactUsData] = useState([]);
  const [currentTab, setCurrentTab] = useState("pending");
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    getContactUsHistory(currentTab);
  }, []);

  const filterData = contactUsData.filter((item) => item);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  const handlePageClick = (limit, newOffset) => {
    setItemOffset(newOffset);
  };

  const getContactUsHistory = (status) => {
    setContactUsData([]);
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/user/contact-us/list?status=${status}`).then((res) => {
      setContactUsData(res.data.data);
      dispatch(setLoader(false));
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  }

  const handleDoneContactUs = (item) => {
    dispatch(setLoader(true));
    AxiosApi.post(`${apiBaseUrl}/api/user/contact-us/create/${item.user_id}`, { ...item, status: "done" }).then((res) => {
      dispatch(setLoader(false));
      getContactUsHistory(currentTab);
    }).catch((e) => {
      console.log(e, "eee");
      dispatch(setLoader(false));
    });
  };

  const handleOnChangeCurrentTab = async (e) => {
    const val = e.currentTarget.value;
    setCurrentTab(val);
    setItemOffset(0);
    await getContactUsHistory(val);
  }

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">{/* Contact Us */} Enterprise Plan Requests</h4>
        <div>
          <ButtonGroup>
            <ToggleButton
              id={`pending`} type="radio" variant="outline-primary" name="creditTabButton" style={{ width: "100px" }}
              value={"pending"} checked={currentTab === "pending"} onChange={handleOnChangeCurrentTab}
            >
              Pending
            </ToggleButton>
            <ToggleButton
              id={`done`} type="radio" variant="outline-primary" name="creditTabButton" style={{ width: "100px" }}
              value={"done"} checked={currentTab === "done"} onChange={handleOnChangeCurrentTab}
            >
              Done
            </ToggleButton>
          </ButtonGroup>
        </div>
      </div>
      <div className="res-table">
        {contactUsData && contactUsData.length > 0 ? (
          <table className="custom-table table mt-20 mb-4">
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Message</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {contactUsData?.map((item, ind) => (
                <tr key={ind}>
                  <td>
                    <div>{item?.first_name + " " + (item?.last_name || "")}</div>
                  </td>
                  <td>
                    <div>{item?.email}</div>
                  </td>
                  <td>
                    <div>{item?.phone_number}</div>
                  </td>
                  <td>
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.message} data-tooltip-place="bottom">
                      {item?.message}
                    </h6>
                  </td>
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td>
                    <div>
                      {item?.status === "pending" ? (
                        <button onClick={() => handleDoneContactUs(item)} className="addlly-primary me-3 py-2">
                          Done
                        </button>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
    </div>
  );
};

export default ContactUsList;
