import React, { useEffect, useState } from 'react'

function SelectedItem({ title, onChange, objKey, accessData }) {
  const [selectItem, setSelectItem] = useState(0);

  useEffect(() => {
    setSelectItem(accessData && accessData[objKey] > 0 ? accessData[objKey] : 0);
  }, [])

  return (
    <ul className="selectItem">
      <li
        className={selectItem ? "active" : ""}
        onKeyDown={() => { }} onClick={() => {
          setSelectItem(!selectItem)
          onChange({ [objKey]: selectItem ? 0 : 1 })
        }}
      >
        <p>{title}</p>
      </li>
    </ul>
  )
}

export default SelectedItem
