import React from "react";
import { Dropdown } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
import { HiOutlineMenu } from "react-icons/hi";
import { resetReduxData, setLoginDetails } from "../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddllyLogo from "../Assets/Images/AddllyLogo.png";

const HeaderMenu = ({ toggle, setToggle }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);

  const logout = async () => {
    await dispatch(resetReduxData());
    dispatch(setLoginDetails({
      username: "",
      password: "",
    }));
    localStorage.removeItem("persist:root");
    localStorage.clear();

    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  return (
    <div className="header-menu dark-border-bottom align-items-center d-flex">
      <div className="bg-white h-100 d-flex align-items-center menu-col-1">
        <button className="btn py-0 toggleBtn" onClick={() => setToggle(!toggle)}>
          <HiOutlineMenu size={20} />
        </button>
        <div className="h-100">
          <img src={AddllyLogo} alt="addlly logo" />
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-end main-header w-100">
        <div className="user-info d-flex align-items-center justify-content-end">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="p-0 bg-transparent border-0 d-flex align-items-center"
            >
              <div className="avatar">{loginDetails?.username[0]}</div>
              <div className="ms-2 d-none d-sm-block me-2">
                <p className="mb-0">{loginDetails?.username}</p>
                <p className="mb-0 text-start fw-bold">
                  {loginDetails && loginDetails.role === "admin" ? "Admin" :
                    loginDetails.role === "sales" ? "Sales" :
                      loginDetails.role === "reviewer" ? "Reviewer" : ""}
                </p>
              </div>
              <BsChevronDown color="#50506A" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
