import React from 'react'
import NoDataAvailableImg from "../Assets/Images/no-data-available.svg";
import DataLoadingGif from "../Assets/Gif/data-loading.gif";
import { useSelector } from 'react-redux';

const NoDataAvailable = ({ text = "No Data Available" }) => {
  const loader = useSelector(({ loader }) => loader);

  return (
    <div className='w-100 p-3'>
      {/* <Col sm="12" md="8" lg="4" className="mx-auto">
        <div className="mx-auto col-8">
          <img src={NoDataAvailableImg} alt="access-denied" className="w-100" />
        </div>
      </Col> */}
      <div className='d-flex align-items-center justify-content-center flex-column'>
        <img src={loader ? DataLoadingGif : NoDataAvailableImg} alt="no data" className="no-data-image" />
        <h2 className='text-dark fw-normal opacity-50 m-0'>
          {loader ? "Data Loading..." : text}
        </h2>
      </div>
    </div>
  )
}

export default NoDataAvailable;
