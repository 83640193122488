import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import toast from "react-hot-toast";
import moment from "moment";
import NoDataAvailable from "../Components/NoDataAvailable";
import TablePaginate from "../Components/TablePaginate";

const TryingToSignup = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [userList, setUserList] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  const filterData = userList.filter((item) => item);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  useEffect(() => {
    handleGetAllUserList();
  }, [])

  const handlePageClick = (limit, offset) => {
    setItemOffset(offset);
  };

  const handleGetAllUserList = async () => {
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/admin/list?type=tryToSignUp`).then((response) => {
      if (response && response.data && response.data.length > 0) {
        setUserList(response.data);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
      toast.error("Something went wrong.");
    });
  }

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Trying To Signup Users ( {filterData.length || 0} )</h4>
        <div className="d-flex align-items-center"></div>
      </div>
      <div className="res-table">
        {currentItems.length > 0 ? (
          <table className="custom-table table mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Username</th>
                <th scope="col">Created Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item, ind) => (
                <tr key={ind}>
                  <td>
                    <h6>{item?.id}</h6>
                  </td>
                  <td>
                    <h6>{(item?.first_name || "-") + " " + (item?.last_name || "")}</h6>
                  </td>
                  <td>
                    <h6>{item?.email || "-"}</h6>
                  </td>
                  <td>
                    <h6>{item?.username || "-"}</h6>
                  </td>
                  <td>
                    <h6>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
    </div>
  )
}

export default TryingToSignup;
