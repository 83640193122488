import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { AiFillCheckCircle, AiFillClockCircle } from "react-icons/ai";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";

const TopicSuggestionsHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [topicSuggestionList, setTopicSuggestionList] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [itemOffset, setItemOffset] = useState(0);

  const filterData = topicSuggestionList.length > 0 ? topicSuggestionList.filter((item) => {
    if (item?.topic?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0) {
      return item;
    } else if (item?.first_name?.toLocaleLowerCase().search(searchTxt.toLocaleLowerCase()) >= 0) {
      return item;
    } else {
      return false;
    };
  }) : [];

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  const handlePageClick = (limit, offset) => {
    setItemOffset(offset);
  };

  useEffect(() => {
    handleGetTopicSuggestionList();
  }, []);

  const handleGetTopicSuggestionList = () => {
    setTopicSuggestionList([]);
    dispatch(setLoader(true));

    AxiosApi.get(`${apiBaseUrl}/api/topic-suggestions/get-history`).then((res) => {
      if (res.data && res.data.data && res.data.data.length > 0) {
        setTopicSuggestionList(res.data.data);
      }
      dispatch(setLoader(false));
    }).catch((error) => {
      toast.error("Something went wrong.");
      dispatch(setLoader(false));
    });
  }

  return (
    <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
      <div className="d-flex align-items-center justify-content-between">
        <h4 className="mb-0">Topic Suggestions ( {filterData.length || 0} )</h4>
        <div className="d-flex align-items-center">
          <input
            placeholder="Search Topic Or Username..." type="text"
            onChange={(e) => setSearchTxt(e.target.value)}
            className="form-control"
          />
        </div>
      </div>
      <div className="res-table">
        {currentItems && currentItems.length > 0 ? (
          <table className="custom-table table table-hover mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Topic</th>
                <th scope="col">Language</th>
                <th scope="col">GeoLocation</th>
                <th scope="col" className="text-center">Status</th>
                <th scope="col">User Name</th>
                <th scope="col">Created At</th>
                <th scope="col" className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, ind) => (
                <tr className="cursor-pointer" key={ind} onClick={() => navigate(`/topic-suggestions/${item?.id}`)}>
                  <td>{item?.id}</td>
                  <td className="">{item?.topic || "-"}</td>
                  <td className="">{item?.language || "-"}</td>
                  <td className="">{item?.geoLocation || "-"}</td>
                  <td className="text-center">
                    <span variant="success" data-tooltip-id="my-tooltip" data-tooltip-place="bottom"
                      data-tooltip-content={item?.status?.charAt(0).toUpperCase() + item?.status?.slice(1)}
                    >
                      {(item?.status === "completed") ? (
                        <AiFillCheckCircle size={18} color="green" />
                      ) : (item?.status === "pending") ? (
                        <AiFillClockCircle size={18} color="#e7e721" />
                      ) : null}
                    </span>
                  </td>
                  <td>{item?.username || (item?.first_name + " " + (item?.last_name || ""))}</td>
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                    <button className="btn btn-primary" type="button" onClick={(e) => { e.stopPropagation(); navigate(`/topic-suggestions/${item?.id}`); }}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataAvailable />
        )}
      </div>
      <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
    </div>
  )
}

export default TopicSuggestionsHistory;
