import { useSelector } from "react-redux";
import GIFLoader from "../Assets/Gif/8080laoder.gif";

const Loader = () => {
  const loader = useSelector(({ loader }) => loader);
  return (
    <>
      {loader && (
        <div className="custom-loader">
          <img src={GIFLoader} alt="loading..." />
        </div>
      )}
    </>
  );
};
export default Loader;
