import React, { useState } from "react"
import { Col, Modal, Placeholder, Row, Tab, Tabs } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import { HiOutlineMagnifyingGlassPlus } from "react-icons/hi2";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { LuDownload } from "react-icons/lu";
import { GrClose } from "react-icons/gr";
import GIFLoader from "./../Assets/Gif/8080laoder.gif";
import NoDataAvailable from "./NoDataAvailable";

const MediaLibraryImagesModal = ({ postImagesModal, setPostImagesModal }) => {
  const [viewImgModal, setViewImgModal] = useState({ open: false, img: "", index: 0, history: [] });

  const handleClosePostImagesModal = () => {
    setPostImagesModal({ open: false });
  };

  const handleOnChangeViewImage = (index) => {
    if (viewImgModal.history && viewImgModal.history.length > 0) {
      if (index !== -1 && viewImgModal.history.length >= index) {
        if (viewImgModal.history[index] && viewImgModal.history[index] !== "" && viewImgModal.history[index].backgroundImage) {
          setViewImgModal((prev) => ({ ...prev, open: true, img: viewImgModal.history[index].backgroundImage, index }));
        } else {
          setViewImgModal((prev) => ({ ...prev, open: true, img: viewImgModal.history[index], index }));
        }
      }
    }
  }

  const handleDownloadImage = (e, image) => {
    e.stopPropagation();
    e.preventDefault();
    const link = document.createElement('a');
    link.download = `image-${Date.now()}.png`;

    fetch(image).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    }).then(blob => {
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href)
    }).catch(() => {
    });
  }

  if (!postImagesModal || !postImagesModal.open) {
    return false;
  }

  return (
    <React.Fragment>
      <Modal
        className="media-library-modal" size="lg" centered backdrop="static"
        show={postImagesModal?.open && !viewImgModal?.open} onHide={handleClosePostImagesModal}
      >
        <Modal.Body className="p-4">
          <Tabs defaultActiveKey={postImagesModal?.currentTab || "pexelsImages"} className="mb-3"
            onSelect={(k) => setPostImagesModal((prev) => ({ ...prev, currentTab: k }))}
          >
            <Tab eventKey="pexelsImages" title="Pexels Images">
              <div className="mt-4">
                <div className="modal-image-wrapper mb-3">
                  {postImagesModal && postImagesModal.isLoader ? (
                    <ImageSkeletonLoader />
                  ) : (
                    <React.Fragment>
                      {(postImagesModal && postImagesModal.pexelImages && postImagesModal.pexelImages.length > 0) ? (
                        postImagesModal.pexelImages.map((image, index) => (
                          <ImageDisplayContent
                            image={image} key={index} viewImgHistory={postImagesModal.pexelImages}
                            setViewImgModal={setViewImgModal} imageIndex={index}
                          />
                        ))
                      ) : (
                        <NoDataAvailable text="Image Not Generated." />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="pixabayImages" title="Pixabay Images">
              <div className="mt-4">
                <div className="modal-image-wrapper mb-3">
                  {postImagesModal && postImagesModal.isLoader ? (
                    <ImageSkeletonLoader />
                  ) : (
                    <React.Fragment>
                      {(postImagesModal && postImagesModal.pixabayImages && postImagesModal.pixabayImages.length > 0) ? (
                        postImagesModal.pixabayImages.map((image, index) => (
                          <ImageDisplayContent
                            image={image} key={index} viewImgHistory={postImagesModal.pixabayImages}
                            setViewImgModal={setViewImgModal} imageIndex={index}
                          />
                        ))
                      ) : (
                        <NoDataAvailable text="Image Not Generated." />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="aiGeneratedImages" title="AI Generated Images">
              <div className="mt-4">
                <div className="modal-image-wrapper mb-3">
                  {postImagesModal && postImagesModal.isLoader ? (
                    <ImageSkeletonLoader />
                  ) : (
                    <React.Fragment>
                      {(postImagesModal && postImagesModal.openAiImages && postImagesModal.openAiImages.length > 0) ? (
                        postImagesModal.openAiImages.map((image, index) => (
                          <ImageDisplayContent
                            image={image} key={index} viewImgHistory={postImagesModal.openAiImages}
                            setViewImgModal={setViewImgModal} imageIndex={index}
                          />
                        ))
                      ) : (
                        <NoDataAvailable text="Image Not Generated." />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="aiProductImages" title="AI Enhanced Brand Images">
              <div className="mt-4">
                <div className="modal-image-wrapper mb-3">
                  {postImagesModal && postImagesModal.isLoader ? (
                    <ImageSkeletonLoader />
                  ) : (
                    <React.Fragment>
                      {(postImagesModal && postImagesModal.aiProductImages && postImagesModal.aiProductImages.length > 0) ? (
                        postImagesModal.aiProductImages.map((image, index) => (
                          <ImageDisplayContent
                            image={image} key={index} viewImgHistory={postImagesModal.aiProductImages}
                            setViewImgModal={setViewImgModal} imageIndex={index}
                          />
                        ))
                      ) : (
                        <NoDataAvailable text="Image Not Generated." />
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </Tab>
          </Tabs>

          <div className="d-flex align-items-center justify-content-end">
            <button onClick={handleClosePostImagesModal} type="button" className="addlly-outline ms-3">
              <span>Close</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {viewImgModal.open && (
        <Modal centered size="lg" className="image-view-modal" backdrop="static"
          show={viewImgModal.open} onHide={() => setViewImgModal({ open: false, img: "" })}
        >
          <Modal.Body className="">
            <div className="image-view-wrapper">
              <img src={viewImgModal.img} alt="" />
            </div>
            <hr className="mt-0" />
            <div className="image-arrow-btn">
              {viewImgModal.index !== 0 && (
                <button className="btn btn-dark left-btn" onClick={() => handleOnChangeViewImage(viewImgModal.index - 1)}>
                  <FiArrowLeft className="fs-4" />
                </button>
              )}
              {viewImgModal.index !== (viewImgModal.history.length - 1) && (
                <button className="btn btn-dark right-btn" onClick={() => handleOnChangeViewImage(viewImgModal.index + 1)}>
                  <FiArrowRight className="fs-4" />
                </button>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-end m-3">
              <button className="btn btn-dark me-3" onClick={(e) => handleDownloadImage(e, viewImgModal.img)}>
                <LuDownload className="fs-4" />
              </button>
              <button className="btn btn-dark close-btn" onClick={() => setViewImgModal({ open: false, img: "", index: 0 })}>
                <GrClose className="fs-4" />
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

const ImageSkeletonLoader = () => {
  return (
    <Row className="w-100">
      {[1, 2, 3, 4, 5].map((ele, index) => (
        <Col sm="4" key={index}>
          <Placeholder as="p" animation="glow" className="overflow-hidden rounded-3 mb-3" style={{ height: "220px" }}>
            <Placeholder xs={12} className="h-100" />
          </Placeholder>
        </Col>
      ))}
    </Row>
  )
};

const ImageDisplayContent = ({ image, imageIndex, viewImgHistory, setViewImgModal }) => {
  const [loaded, setLoaded] = useState(false);

  const handleDownloadImage = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setLoaded(false);
    const link = document.createElement('a');
    link.download = `image-${Date.now()}.png`;

    fetch(image).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    }).then(blob => {
      setLoaded(true);
      link.href = URL.createObjectURL(blob);
      link.click();
      URL.revokeObjectURL(link.href)
    }).catch(() => {
      setLoaded(true);
    });
  }

  return (
    <div className={`image-content position-relative ${!loaded ? "bg-dark opacity-50" : ""}`}>
      <img src={image} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
      {!loaded && image && (
        <div className="custom-post-loader">
          <img src={GIFLoader} alt="loading..." />
        </div>
      )}
      <button type="button" className="addlly-primary image-download-btn" onClick={handleDownloadImage}>
        <BsDownload />
      </button>
      <button type="button" className="addlly-primary image-view-btn"
        onClick={(e) => {
          e.stopPropagation(); e.preventDefault();
          setViewImgModal({ open: true, img: image, index: imageIndex, history: viewImgHistory });
        }}
      >
        <HiOutlineMagnifyingGlassPlus />
      </button>
    </div>
  )
}

export default MediaLibraryImagesModal;
