import axios from "axios";

export const AxiosApi = axios.create({
});

AxiosApi.interceptors.request.use(config => {
  const token = localStorage.getItem('token') || '';
  config.headers.Authorization = `${token}`;
  return config;
});
