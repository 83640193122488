import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from 'react-select';
import Swal from "sweetalert";
import { Modal, Row, Col, Dropdown } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { HiOutlinePlus } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import TablePaginate from "../Components/TablePaginate";
import NoDataAvailable from "../Components/NoDataAvailable";

const addValidationSchema = Yup.object().shape({
  label: Yup.string().required("Tone of voice label is required."),
  prompt: Yup.string().required("Tone of voice prompt is required."),
  type: Yup.string().required("Tone of voice type is required."),
});

const writerTypeOptions = [
  { label: "1-Click Blog Writer", value: "1-Click Blog Writer" },
  { label: "Blog Co-Pilot", value: "Blog Co-Pilot" },
  { label: "Social Media Post Writer", value: "Social Media Post Writer" },
]

const ToneOfVoiceHistory = () => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [toneOfVoiceData, setToneOfVoiceData] = useState({ data: [], length: 0 });
  const [toneOfVoicePopup, setToneOfVoicePopup] = useState(false);
  const [toneOfVoiceEditPopup, setToneOfVoiceEditPopup] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");

  const { register, handleSubmit, formState: { errors }, reset, control } = useForm({
    defaultValues: {},
    resolver: yupResolver(addValidationSchema),
  });

  useEffect(() => {
    setToneOfVoiceData({ data: [], length: 0 });
    getToneOfVoiceList(10, 0, currentStatus);
  }, []);

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      getToneOfVoiceList(limit, offset, currentStatus);
    }
  };

  const handleOnChangeCurrentTab = async (val) => {
    if (val === currentStatus) {
      return false;
    }

    setToneOfVoiceData({ data: [], length: 0 });
    setCurrentStatus(val);
    getToneOfVoiceList(10, 0, val);
  }

  const getToneOfVoiceList = (limit, offset, type) => {
    setToneOfVoiceData({ data: [], length: 0 });
    dispatch(setLoader(true));
    AxiosApi.get(`${apiBaseUrl}/api/admin/tone-of-voice/list?type=${type}&limit=${limit}&offset=${offset}`).then((res) => {
      setToneOfVoiceData(res.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
    });
  }

  const onSubmitToneOfVoice = async (data) => {
    try {
      dispatch(setLoader(true));
      const response = await AxiosApi.post(`${apiBaseUrl}/api/admin/tone-of-voice/create-or-update`, data);
      if (response && response.data) {
        toast.success(`Tone of voice ${data.id ? "updated" : "added"} successfully.`);
        dispatch(setLoader(false));
        reset({}); setToneOfVoicePopup(false);
        getToneOfVoiceList(10, 0, currentStatus);
      } else {
        toast.success("Something went wrong, Please try again.");
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
    }
  }

  const handleOnEditToneOfVoice = async (data) => {
    setToneOfVoicePopup(true);
    setToneOfVoiceEditPopup(true);
    reset({
      id: data.id || "",
      type: data.type || "",
      label: data.label || "",
      prompt: data.prompt || "",
    });
  }

  const handleOnDeleteToneOfVoice = async (id) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: "Yes, delete it!" },
    }).then((isConfirmed) => {
      if (isConfirmed) {
        dispatch(setLoader(true));
        AxiosApi.delete(`${apiBaseUrl}/api/admin/tone-of-voice/delete/${id}`).then(() => {
          getToneOfVoiceList(10, 0, currentStatus);
          dispatch(setLoader(false));
          Swal("Deleted!", "Your record has been deleted.", "success");
        }).catch((error) => {
          dispatch(setLoader(false));
          toast.error("Something went wrong.");
        });
      }
    });
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Tone Of Voice ( {toneOfVoiceData.length || 0} )</h4>
          <div className="d-flex align-items-center">
            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" className="me-3">
                <FaFilter /> <span className="mx-2">{currentStatus && currentStatus !== "" ? currentStatus : "Filter"}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {writerTypeOptions.map((item, index) => (
                  <Dropdown.Item as="button" key={index}
                    onClick={() => handleOnChangeCurrentTab(item.value)}
                    active={item.value === currentStatus ? true : false}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => handleOnChangeCurrentTab("")}>Clear Filter</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button onClick={() => setToneOfVoicePopup(true)} type="button" className="addlly-primary py-2 px-3 ms-3">
              <HiOutlinePlus className="fs-6" /> <span>Add</span>
            </button>
          </div>
        </div>
        <div className="res-table">
          <table className="custom-table table mt-20 mb-0">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Type</th>
                <th scope="col">Label</th>
                <th scope="col">Prompt</th>
                <th scope="col">Create At</th>
                <th className="text-center" scope="col">Action</th>
              </tr>
            </thead>
            {toneOfVoiceData && toneOfVoiceData.data && toneOfVoiceData.data.length > 0 && (
              <tbody>
                {toneOfVoiceData.data.map((item, ind) => (
                  <tr key={ind}>
                    <td>{item?.id || ""}</td>
                    <td>{item?.type || "-"}</td>
                    <td>{item?.label || "-"}</td>
                    <td className="text-width" style={{ maxWidth: "260px" }}>{item?.prompt || "-"}</td>
                    <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                    <td className="text-center">
                      <button className="btn btn-primary me-3" type="button" onClick={() => handleOnEditToneOfVoice(item)}>
                        Edit
                      </button>
                      <button className="btn btn-danger" type="button" onClick={() => handleOnDeleteToneOfVoice(item?.id)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {toneOfVoiceData && toneOfVoiceData.length === 0 && (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={toneOfVoiceData.length} handlePageChange={handleChangePageLoadData} />
      </div>

      {toneOfVoicePopup && (
        <Modal show={toneOfVoicePopup} centered onHide={() => { setToneOfVoicePopup(false); reset({}) }}>
          <Modal.Header closeButton>
            <h4>{toneOfVoiceEditPopup ? "Edit" : "Add"} Tone Of Voice</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <form onSubmit={handleSubmit(onSubmitToneOfVoice)} className="">
              <Row>
                <Col sm="12">
                  <div className="form-group mb-4">
                    <label>Label</label>
                    <input
                      name="label" type="text" placeholder="Insert tone of voice label" {...register("label")}
                      className={`addllyForm-control ${errors.label ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.label?.message}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-4">
                    <label>Prompt</label>
                    <textarea
                      name="prompt" type="text" rows="3" placeholder="Insert tone of voice prompt" {...register("prompt")}
                      className={`addllyForm-control ${errors.prompt ? "is-invalid" : ""}`} style={{ borderRadius: "15px" }}
                    />
                    <div className="invalid-feedback">{errors.prompt?.message}</div>
                  </div>
                </Col>
                <Col sm="12">
                  <div className="form-group mb-4">
                    <label>Writer Type</label>
                    <Controller
                      control={control} name="type" {...register("type")}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className={`custom-select ${errors.type ? "is-invalid" : ""}`}
                          classNamePrefix="custom-select" placeholder="Select Writer Type"
                          options={writerTypeOptions} isClearable={false} inputRef={ref}
                          value={writerTypeOptions.find(c => c.value === value)}
                          onChange={(e) => onChange(e.value)}
                        />
                      )}
                    />
                    <div className="invalid-feedback">{errors.type?.message}</div>
                  </div>
                </Col>
              </Row>
              <div className="">
                <button className="addlly-primary ms-auto" type="submit" variant="primary">
                  Save
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  )
}

export default ToneOfVoiceHistory;
