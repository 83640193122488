import React, { useEffect, useState } from "react";
import { HiDotsHorizontal, HiOutlineMail, HiShoppingBag } from "react-icons/hi";
import { Col } from "react-bootstrap";
import { AiOutlineSearch, AiOutlineYoutube, AiOutlineHeart } from "react-icons/ai";
import { BsChatDots, BsArrow90DegRight, BsPlusSquareFill, BsSearch, BsPlayBtn, BsStars, BsUpload } from "react-icons/bs";
import { ImEarth } from "react-icons/im";
import { SlLike } from "react-icons/sl";
import { MdOutlineInsertComment, MdHome, MdShoppingBag, MdOutlineAddBox, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { TbBrandMessenger, TbSend, TbUpload } from "react-icons/tb";
import { RiMic2Line, RiSendPlaneFill } from "react-icons/ri";
import { HiUsers } from "react-icons/hi";
import { FaBell, FaFacebookMessenger, FaRegBell, FaBars, FaRegComment, FaRegHeart, FaRegUserCircle, FaRetweet } from "react-icons/fa";
import { SiHomeassistantcommunitystore } from "react-icons/si";
import facebookLogo from "../Assets/Images/facebook-logo.png";
import instagramTextLogo from "../Assets/Images/Social_media/instagram-text-logo.png";
import TwitterLogo from "../Assets/Images/Social_media/twitter-logo.png";
import { BiBookmark, BiHomeCircle, BiMessageSquare, BiUserCircle } from "react-icons/bi";
import { TiBell } from "react-icons/ti";
import GIFLoader from "../Assets/Gif/8080laoder.gif";
import NoImageAvailable from "../Assets/Images/no_image_available.png";
import { AxiosApi } from "../utility/axios";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import NoDataAvailable from "./NoDataAvailable";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
Chart.register(CategoryScale);

const ImageLoader = () => {
  return (
    <div className="custom-post-loader">
      <img src={GIFLoader} alt="loading..." />
    </div>
  )
}

export const LinkedInPostPreview = ({ post = "", preview_img = "", col = 4, isUploadImage = false, setUploadFileModal, carousel_images = {} }) => {
  const [loaded, setLoaded] = useState(false);
  const [isShowFullPost, setIsShowFullPost] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    if (!preview_img) {
      setLoaded(true);
    }
  }, [])

  return (
    <Col sm={col} className="pb-2">
      <div className="post-preview-container">
        <div className="preview-header">
          <div className="profile-pic"></div>
          <div className="search-box form-group">
            <input
              name="search" type="text" disabled placeholder="Search"
              className={`addllyForm-control`}
            />
            <AiOutlineSearch className="search-icon" />
          </div>
          <BsChatDots className="message-icon" />
        </div>
        <div className="preview-body">
          <div className="post-content">
            <div className="post-header">
              <div className="user-info">
                <div className="user-img"></div>
                <div className="user-desc">
                  <p className="fw-bold mb-1">Username</p>
                  {/* <p className="text-primary">Lorem ipsum</p> */}
                  <p className="text-dark d-flex align-items-center">
                    2 days <ImEarth className="ms-2 text-dark" />
                  </p>
                </div>
                <HiDotsHorizontal className="option-icon" />
              </div>
              <div className="post-desc">
                <pre style={isShowFullPost ? { display: "block" } : {}}>{post}</pre>
                <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => setIsShowFullPost(!isShowFullPost)}>
                  {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                  {isShowFullPost ? "Show Less" : "See More"}
                </span>
              </div>
            </div>
            <div className={`post-img carousel-wrapper ${isUploadImage && 'image-upload-content'}`}>
              {carousel_images && Object.keys(carousel_images).length > 0 ? (
                <div className={`img-wrapper h-100 position-relative ${!preview_img ? "bg-dark opacity-75" : ""}`}>
                  <div className="slides" id="scroll-div">
                    {Object.keys(carousel_images).map((image, index) => (
                      <div className="" key={index}>
                        <img src={carousel_images[index]} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                      </div>
                    ))}
                  </div>
                  <div className="arrow-button">
                    {carouselIndex !== 0 && (
                      <div className="left-arrow-button" type="button"
                        onClick={() => {
                          setCarouselIndex(carouselIndex - 1);
                          const content = document.querySelector('#scroll-div');
                          content.scrollLeft -= 300;
                        }} onKeyDown={() => { }}
                      >
                        <MdKeyboardArrowLeft className='fs-3' />
                      </div>
                    )}
                    {(carouselIndex + 1) !== Object.keys(carousel_images).length && (
                      <div className="right-arrow-button" type="button"
                        onClick={() => {
                          setCarouselIndex(carouselIndex + 1);
                          const content = document.querySelector('#scroll-div');
                          content.scrollLeft += 300;
                        }} onKeyDown={() => { }}
                      >
                        <MdKeyboardArrowRight className='fs-3' />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={`img-wrapper position-relative ${!preview_img ? "bg-dark opacity-75" : ""}`}>
                  {preview_img ? (
                    <img src={preview_img} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                  ) : (
                    <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                  )}
                  {!loaded && preview_img && (<ImageLoader />)}
                  {isUploadImage && !preview_img ? (
                    <div className="image-upload-btn-div">
                      <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary">
                        <BsUpload /> <span className="ms-2">Upload Or Select Image</span>
                      </button>
                    </div>
                  ) : isUploadImage ? (
                    <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn">
                      <BsUpload />
                    </button>
                  ) : null}
                </div>
              )}
              {/* <div className="img-desc">
                <p>Lorem ipsum dolor.</p>
              </div> */}
            </div>
            <div className="post-footer">
              <div className="li-comment-desc">
                <div className="d-flex align-items-center">
                  <span className="dot dot-1"></span>
                  <span className="dot dot-2"></span>
                  <span className="dot dot-3"></span>
                  <p className="ms-2">0</p>
                </div>
                <p>0 comments</p>
              </div>
              <div className="post-actions">
                <div>
                  <SlLike className="action-icon" />
                  <p className="action-label">Recommend</p>
                </div>
                <div>
                  <MdOutlineInsertComment className="action-icon" />
                  <p className="action-label">Comment</p>
                </div>
                <div>
                  <BsArrow90DegRight className="action-icon" />
                  <p className="action-label">Share</p>
                </div>
                <div>
                  <RiSendPlaneFill className="action-icon" />
                  <p className="action-label">Send</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-footer">
          <div className="footer-actions">
            <MdHome className="action-icon fs-3" />
            <HiUsers className="action-icon fs-4" />
            <BsPlusSquareFill className="action-icon fs-5" />
            <FaBell className="action-icon fs-5" />
            <MdShoppingBag className="action-icon fs-4" />
          </div>
          <div className="home-button"></div>
        </div>
      </div>
    </Col>
  )
}

export const FacebookPostPreview = ({ post = "", preview_img = "", col = 4, isUploadImage = false, setUploadFileModal }) => {
  const [loaded, setLoaded] = useState(false);
  const [isShowFullPost, setIsShowFullPost] = useState(false);

  useEffect(() => {
    if (!preview_img) {
      setLoaded(true);
    }
  }, [])

  return (
    <Col sm={col} className="pb-2">
      <div className="post-preview-container">
        <div className="preview-header">
          <div className="fb-logo">
            <img src={facebookLogo} alt="facebook" />
          </div>
          <div className="d-flex align-items-center">
            <div className="fb-header-icon me-2">
              <AiOutlineSearch className="" />
            </div>
            <div className="fb-header-icon">
              <FaFacebookMessenger className="" />
            </div>
          </div>
        </div>
        <div className="preview-body">
          <div className="post-content">
            <div className="post-header">
              <div className="user-info">
                <div className="fb-user-img"></div>
                <div className="user-desc">
                  <p className="fw-bold mb-1">Username</p>
                  <p className="d-flex align-items-center">
                    2 days <ImEarth className="ms-2 text-dark" />
                  </p>
                </div>
                <HiDotsHorizontal className="option-icon" />
              </div>
              <div className="post-desc">
                <pre style={isShowFullPost ? { display: "block" } : {}}>{post}</pre>
                <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => setIsShowFullPost(!isShowFullPost)}>
                  {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                  {isShowFullPost ? "Show Less" : "See More"}
                </span>
              </div>
            </div>
            <div className={`post-img ${isUploadImage && 'image-upload-content'}`}>
              <div className={`img-wrapper position-relative ${!preview_img ? "bg-dark opacity-75" : ""}`}>
                {preview_img ? (
                  <img src={preview_img} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                ) : (
                  <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                )}
                {!loaded && preview_img && (<ImageLoader />)}
                {isUploadImage && !preview_img ? (
                  <div className="image-upload-btn-div">
                    <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary">
                      <BsUpload /> <span className="ms-2">Upload Or Select Image</span>
                    </button>
                  </div>
                ) : isUploadImage ? (
                  <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn">
                    <BsUpload />
                  </button>
                ) : null}
              </div>
              {/* <div className="img-desc">
                <p>Lorem ipsum dolor.</p>
              </div> */}
            </div>
            <div className="post-footer">
              <div className="fb-comment-desc">
                <div className="d-flex align-items-center">
                  <span className="dot dot-1"></span>
                  <span className="dot dot-2"></span>
                  <span className="dot dot-3"></span>
                  <p className="ms-2">1.4K</p>
                </div>
                <p>105 comments 25 shares</p>
              </div>
              <div className="post-actions py-3">
                <div className="d-flex align-items-center">
                  <SlLike className="action-icon" />
                  <span className="action-label ms-2">Like</span>
                </div>
                <div className="d-flex align-items-center">
                  <MdOutlineInsertComment className="action-icon" />
                  <span className="action-label ms-2">Comment</span>
                </div>
                <div className="d-flex align-items-center">
                  <BsArrow90DegRight className="action-icon" />
                  <span className="action-label ms-2">Share</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-footer">
          <div className="footer-actions">
            <MdHome className="action-icon fs-3" />
            <AiOutlineYoutube className="action-icon fs-4" />
            <SiHomeassistantcommunitystore className="action-icon fs-5" />
            <FaRegBell className="action-icon fs-5" />
            <FaBars className="action-icon fs-5" />
          </div>
          <div className="home-button"></div>
        </div>
      </div>
    </Col>
  )
}

export const InstagramPostPreview = ({ post = "", preview_img = "", col = 4, isUploadImage = false, setUploadFileModal }) => {
  const [loaded, setLoaded] = useState(false);
  const [isShowFullPost, setIsShowFullPost] = useState(false);

  useEffect(() => {
    if (!preview_img) {
      setLoaded(true);
    }
  }, [])

  return (
    <Col sm={col} className="pb-2">
      <div className="post-preview-container">
        <div className="preview-header">
          <div className="ig-logo">
            <img src={instagramTextLogo} alt="instagram" />
          </div>
          <div className="d-flex align-items-center">
            <MdOutlineAddBox className="ig-header-icon me-3" />
            <AiOutlineHeart className="ig-header-icon me-3" />
            <TbBrandMessenger className="ig-header-icon" />
          </div>
        </div>
        <div className="preview-body">
          <div className="post-content">
            <div className="post-header">
              <div className="user-info align-items-center">
                <div className="fb-user-img"></div>
                <div className="user-desc d-flex align-items-center">
                  <h5 className="fw-normal mb-0">Username</h5>
                </div>
                <HiDotsHorizontal className="option-icon" />
              </div>
            </div>
            <div className={`ig-post-img ${isUploadImage && 'image-upload-content'}`}>
              <div className={`img-wrapper position-relative ${!preview_img ? "bg-dark opacity-75" : ""}`}>
                {preview_img ? (
                  <img src={preview_img} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                ) : (
                  <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                )}
                {!loaded && preview_img && (<ImageLoader />)}
                {isUploadImage && !preview_img ? (
                  <div className="image-upload-btn-div">
                    <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary">
                      <BsUpload /> <span className="ms-2">Upload Or Select Image</span>
                    </button>
                  </div>
                ) : isUploadImage ? (
                  <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn">
                    <BsUpload />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="ig-post-content">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <FaRegHeart className="post-icon me-3" />
                  <FaRegComment className="post-icon me-3" />
                  <TbSend className="post-icon" />
                </div>
                <BiBookmark className="post-icon" />
              </div>
              <div className="">
                <h6 className="fw-normal mt-2">Liked by <b>User_1</b> and <b>Others</b></h6>
                <div className="post-desc">
                  <pre style={isShowFullPost ? { display: "block" } : {}}><b>Username</b> {post}</pre>
                  <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => setIsShowFullPost(!isShowFullPost)}>
                    {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                    {isShowFullPost ? "Show Less" : "See More"}
                  </span>
                </div>
                <h6 className="fw-normal mt-2">View all 25 comments</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-footer">
          <div className="footer-actions">
            <MdHome className="action-icon fs-3" />
            <BsSearch className="action-icon fs-4" />
            <BsPlayBtn className="action-icon fs-4" />
            <HiShoppingBag className="action-icon fs-4" />
            <BiUserCircle className="action-icon fs-3" />
          </div>
          <div className="home-button"></div>
        </div>
      </div>
    </Col>
  )
}

export const TwitterPostPreview = ({ post = "", preview_img = "", col = 4, isUploadImage = false, setUploadFileModal }) => {
  const [loaded, setLoaded] = useState(false);
  const [isShowFullPost, setIsShowFullPost] = useState(false);

  useEffect(() => {
    if (!preview_img) {
      setLoaded(true);
    }
  }, [])

  return (
    <Col sm={col} className="pb-2">
      <div className="post-preview-container">
        <div className="preview-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            <FaRegUserCircle className="tw-header-icon" />
            <img src={TwitterLogo} alt="" width={"28px"} />
            <BsStars className="tw-header-icon" />
          </div>
        </div>
        <div className="preview-body">
          <div className="post-content">
            <div className="post-header">
              <div className="user-info">
                <div className="fb-user-img"></div>
                <div className="user-desc">
                  <p className="fw-bold mb-1">Username</p>
                  <p className="d-flex align-items-center">@Username - 2 days</p>
                </div>
                <HiDotsHorizontal className="option-icon" />
              </div>
            </div>
            <div className="post-content-wrapper">
              <div className="tw-post-desc">
                <pre style={isShowFullPost ? { display: "block" } : {}}>
                  {post?.replace("Title:", "")?.replace("Judul:", "")?.replace("Tweet 1:", "")?.replace("Tweet 2:", "")?.replace("Tweet 3:", "").trim()}
                </pre>
                <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => setIsShowFullPost(!isShowFullPost)}>
                  {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                  {isShowFullPost ? "Show Less" : "See More"}
                </span>
              </div>
              <div className={`${isUploadImage && 'image-upload-content'}`}>
                <div className={`img-wrapper position-relative ${!preview_img ? "bg-dark opacity-75" : ""}`}>
                  {preview_img ? (
                    <img src={preview_img} alt="Post pictures" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                  ) : (
                    <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={() => setLoaded(true)} />
                  )}
                  {!loaded && preview_img && (<ImageLoader />)}
                  {isUploadImage && !preview_img ? (
                    <div className="image-upload-btn-div">
                      <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary">
                        <BsUpload /> <span className="ms-2">Upload Or Select Image</span>
                      </button>
                    </div>
                  ) : isUploadImage ? (
                    <button type="button" onClick={() => setUploadFileModal(true)} className="addlly-primary image-upload-btn">
                      <BsUpload />
                    </button>
                  ) : null}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-2">
                <div className="post-footer">
                  <BiMessageSquare className="" /> 38
                </div>
                <div className="post-footer">
                  <FaRetweet className="" /> 468
                </div>
                <div className="post-footer">
                  <AiOutlineHeart className="" /> 4105
                </div>
                <div className="post-footer">
                  <TbUpload className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="preview-footer">
          <div className="footer-actions">
            <BiHomeCircle className="action-icon fs-4" />
            <AiOutlineSearch className="action-icon fs-4" />
            <RiMic2Line className="action-icon fs-4" />
            <TiBell className="action-icon fs-4" />
            <HiOutlineMail className="action-icon fs-4" />
          </div>
          <div className="home-button"></div>
        </div>
      </div>
    </Col>
  )
}

export const GoogleAdPreview = ({ googleAdCopy, keywordCPC, col = 6 }) => {
  let keywordData = [];
  try {
    keywordData = keywordCPC ? JSON.parse(keywordCPC) : [];
  } catch (error) {
    //console.log(error);
  }

  return (
    <Col xl={col}>
      <div className="preview-container">
        {keywordData && keywordData.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                <th>Keyword</th>
                <th>CPC</th>
              </tr>
            </thead>
            <tbody>
              {keywordData.map(item => (
                <tr key={item.keyword}>
                  <td>{item.keyword}</td>
                  <td>{item.cpc}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {[1, 2, 3].map((ele) => (
          <div className="mb-4" key={ele}>
            <h4 className="text-primary">
              {googleAdCopy.substring(
                googleAdCopy.indexOf(`Headline ${ele}`), googleAdCopy.indexOf(ele === 3 ? 'Description 1' : `Headline ${ele + 1}`)
              ).replace(`Headline ${ele}: `, "")}
            </h4>
            <h6 className="text-success"> <span className="badge bg-success me-2">AD</span>
              {googleAdCopy.substring(
                googleAdCopy.indexOf("Display Path"), googleAdCopy.indexOf("Call to Action")
              ).replace("Display Path: ", "")}
            </h6>
            <p>
              {googleAdCopy.substring(
                googleAdCopy.indexOf(`Description ${ele}`), googleAdCopy.indexOf(ele === 2 ? 'Display Path' : `Description ${ele + 1}: `)
              ).replace(`Description ${ele}: `, "")}
            </p>
            <p className="text-primary">
              {googleAdCopy.substring(
                googleAdCopy.indexOf("Call to Action")
              ).replace("Call to Action: ", "")}
            </p>
          </div>
        ))}
      </div>
    </Col>
  )
}

export const SuggestionPopularPostsPreview = ({ postInfo = {}, postType, index, selectedSuggestionPost, setSelectedSuggestionPost }) => {
  const [loaded, setLoaded] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const [isShowFullPost, setIsShowFullPost] = useState(false);
  const [isShowBtn, setIsShowBtn] = useState(true);

  useEffect(() => {
    if (postInfo && postInfo.text && postInfo.text.length < 220) {
      setIsShowBtn(false);
      setIsShowFullPost(true);
    }
  }, [])

  useEffect(() => {
    let imgKey = "images";
    let previewImgStr = "";
    if (postType === "Facebook") {
      imgKey = "attachments";
    } else if (postType === "Instagram") {
      imgKey = "displayUrl";
    } else if (postType === "LinkedIn") {
      imgKey = "image";
    } else if (postType === "Twitter") {
      imgKey = "media";
    }

    if (postInfo && postInfo[imgKey]) {
      if (typeof postInfo[imgKey] === "object") {
        previewImgStr = (postInfo[imgKey][0]?.image?.uri || postInfo[imgKey][0]?.thumbnail || postInfo[imgKey][0]?.url || postInfo[imgKey][0]);
      } else {
        previewImgStr = postInfo[imgKey];
      }
    }

    if (postType === "Instagram") {
      getInstagramPostImage(previewImgStr);
    } else {
      setPreviewImg(previewImgStr);
    }

    if (postInfo && postInfo.text && postInfo.text.length < 200 && postType !== "Instagram") {
      setIsShowFullPost(true);
    }
  }, [])

  const getInstagramPostImage = async (url) => {
    AxiosApi.get(`/api/post-writer/get-img-base64`, {
      params: { url }
    }).then((response) => {
      setPreviewImg(response.data.data);
    }).catch((error) => {
      setPreviewImg("");
      console.log(error);
    });
  };

  const handleOnErrorImage = () => {
    setLoaded(true);
    setPreviewImg("");
  }

  return (
    <div className="post-preview-container">
      <div className="preview-body">
        <div className="post-content">
          <div className="post-header">
            <div className="post-select-checkbox py-2 border-bottom">
              {/* <Form.Check inline name={"post " + index} id={"post " + index} type="checkbox"
                label={<div className="ms-3">Select Post {index + 1}</div>}
                checked={selectedSuggestionPost && selectedSuggestionPost.index === index ? true : false}
                onChange={(e) => (e.target.checked ? setSelectedSuggestionPost({ index, text: postInfo?.text }) : setSelectedSuggestionPost({ index: null, text: null }))}
              /> */}
              <div className="fs-5 text-center">Trending Post {index + 1}</div>
            </div>
            {(postType === "LinkedIn" || postType === "Facebook") && (
              <div className="mt-3">
                <div className="user-info">
                  <div className="user-img rounded-circle" style={{ width: "40px", height: "40px", minWidth: "40px" }}></div>
                  <div className="user-desc">
                    <p className="fw-bold mb-1">{/* postInfo?.authorFullName || postInfo?.user?.name || */ "User name"}</p>
                    <p className="text-dark d-flex align-items-center">
                      {moment(postInfo?.date).fromNow()}
                      <ImEarth className="ms-2 text-dark" />
                    </p>
                  </div>
                  <HiDotsHorizontal className="option-icon" />
                </div>
                <div className="post-desc">
                  <pre style={isShowFullPost ? { display: "block" } : {}}>{postInfo?.text}</pre>
                  {isShowBtn && (
                    <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => { }}>
                      {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                      {isShowFullPost ? "Show Less" : "See More"}
                    </span>
                  )}
                </div>
              </div>
            )}
            {postType === "Instagram" && (
              <div className="user-info align-items-center mt-3">
                <div className="fb-user-img"></div>
                <div className="user-desc d-flex align-items-center">
                  <h5 className="fw-normal mb-0">{/* postInfo?.ownerUsername || */ "Username"}</h5>
                </div>
                <HiDotsHorizontal className="option-icon" />
              </div>
            )}
          </div>
          {(postType === "LinkedIn" || postType === "Facebook") && (
            <React.Fragment>
              <div className="post-img">
                <div className={`img-wrapper position-relative ${!previewImg ? "bg-dark opacity-75" : ""}`}>
                  {previewImg ? (
                    <img src={previewImg} alt="Post pictures" onLoad={() => setLoaded(true)} onError={handleOnErrorImage} />
                  ) : (
                    <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={handleOnErrorImage} />
                  )}
                  {!loaded && previewImg && (<ImageLoader />)}
                </div>
              </div>
              <div className="post-footer">
                <div className="li-comment-desc">
                  <div className="d-flex align-items-center">
                    <span className="dot dot-1"></span>
                    <span className="dot dot-2"></span>
                    <span className="dot dot-3"></span>
                    <p className="ms-2">
                      {postInfo && postInfo.likesCount ? postInfo.likesCount : "0"}
                    </p>
                  </div>
                  <p>{postInfo && postInfo.commentsCount ? postInfo.commentsCount : "0"} comments</p>
                </div>
                {postType === "Facebook" ? (
                  <div className="post-actions py-3">
                    <div className="d-flex align-items-center">
                      <SlLike className="action-icon" />
                      <span className="action-label ms-2">Like</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <MdOutlineInsertComment className="action-icon" />
                      <span className="action-label ms-2">Comment</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <BsArrow90DegRight className="action-icon" />
                      <span className="action-label ms-2">Share</span>
                    </div>
                  </div>
                ) : (
                  <div className="post-actions">
                    <div>
                      <SlLike className="action-icon" />
                      <p className="action-label">Recommend</p>
                    </div>
                    <div>
                      <MdOutlineInsertComment className="action-icon" />
                      <p className="action-label">Comment</p>
                    </div>
                    <div>
                      <BsArrow90DegRight className="action-icon" />
                      <p className="action-label">Share</p>
                    </div>
                    <div>
                      <RiSendPlaneFill className="action-icon" />
                      <p className="action-label">Send</p>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
          {postType === "Instagram" && (
            <React.Fragment>
              <div className="ig-post-img">
                <div className={`img-wrapper position-relative ${!previewImg ? "bg-dark opacity-75" : ""}`}>
                  {previewImg ? (
                    <img src={previewImg} alt="Post pictures" onLoad={() => setLoaded(true)} onError={handleOnErrorImage} />
                  ) : (
                    <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={handleOnErrorImage} />
                  )}
                  {!loaded && previewImg && (<ImageLoader />)}
                </div>
              </div>
              <div className="ig-post-content">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <FaRegHeart className="post-icon me-3" />
                    <FaRegComment className="post-icon me-3" />
                    <TbSend className="post-icon" />
                  </div>
                  <BiBookmark className="post-icon" />
                </div>
                <div className="">
                  <h6 className="fw-normal mt-2">
                    {postInfo && postInfo.likesCount ? postInfo.likesCount : "0"} Likes - {" "}
                    {postInfo && postInfo.commentsCount ? postInfo.commentsCount : "0"} Comments
                  </h6>
                  <div className="post-desc mt-2">
                    <pre style={isShowFullPost ? { display: "block" } : {}}>{postInfo?.text}</pre>
                    {isShowBtn && (
                      <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => { }}>
                        {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                        {isShowFullPost ? "Show Less" : "See More"}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {postType === "Twitter" && (
            <React.Fragment>
              <div className="post-header">
                <div className="user-info">
                  <div className="fb-user-img"></div>
                  <div className="user-desc">
                    <p className="fw-bold mb-1">{/* postInfo?.author?.username || */ "User name"}</p>
                    <p className="d-flex align-items-center">{moment(postInfo?.createdAt).fromNow()}</p>
                  </div>
                  <HiDotsHorizontal className="option-icon" />
                </div>
              </div>
              <div className="post-content-wrapper p-3 pt-0">
                <div className="tw-post-desc">
                  <pre style={isShowFullPost ? { display: "block" } : {}}>{postInfo?.text}</pre>
                  {isShowBtn && (
                    <span className="more-btn" onClick={() => setIsShowFullPost(!isShowFullPost)} onKeyDown={() => { }}>
                      {isShowFullPost ? <FiArrowUp /> : <FiArrowDown />}
                      {isShowFullPost ? "Show Less" : "See More"}
                    </span>
                  )}
                </div>
                <div className={`img-wrapper position-relative ${!previewImg ? "bg-dark opacity-75" : ""}`}>
                  {previewImg ? (
                    <img src={previewImg} alt="Post pictures" onLoad={() => setLoaded(true)} onError={handleOnErrorImage} />
                  ) : (
                    <img src={NoImageAvailable} alt="No Img Available" onLoad={() => setLoaded(true)} onError={handleOnErrorImage} />
                  )}
                  {!loaded && previewImg && (<ImageLoader />)}
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div className="post-footer">
                    <BiMessageSquare className="" /> {postInfo && postInfo.replyCount ? postInfo.replyCount : "0"}
                  </div>
                  <div className="post-footer">
                    <FaRetweet className="" /> {postInfo && postInfo.retweetCount ? postInfo.retweetCount : "0"}
                  </div>
                  <div className="post-footer">
                    <AiOutlineHeart className="" /> {postInfo && postInfo.likeCount ? postInfo.likeCount : "0"}
                  </div>
                  <div className="post-footer">
                    <TbUpload className="" />
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

const DisplayWebUrlImage = ({ url, faviconLink }) => {
  const [isImageExit, setIsImageExit] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (faviconLink && (faviconLink !== 'favicon.ico' && faviconLink !== '/favicon.ico')) {
      if (faviconLink[0] === '/' && faviconLink[1] !== '/') {
        const getOrigin = new URL(url)?.origin;
        setImageUrl(`${getOrigin}${faviconLink}`);
      } else {
        setImageUrl(faviconLink);
      }
      setIsImageExit(true);
    } else if (url) {
      const getOrigin = new URL(url)?.origin;
      setImageUrl(`${getOrigin}/favicon.ico`);
      setIsImageExit(true);
    }
  }, [])

  return (
    <React.Fragment>
      {isImageExit ? (
        <img src={imageUrl} onError={() => setIsImageExit(false)} alt="" />
      ) : (
        <ImEarth className="text-primary" />
      )}
    </React.Fragment>
  )
}

export const FactCheckerPreview = ({ searchResponse, col = 12 }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      if (searchResponse && typeof JSON.parse(searchResponse) !== typeof undefined) {
        setData(JSON.parse(searchResponse));
      } else {
        setData(searchResponse);
      }
    } catch (error) {
      setData(searchResponse);
    }
  }, [searchResponse])

  return (
    <Col xl={col}>
      <div className="preview-container fact-checker-preview px-4 py-2">
        {data && data.length > 0 && data.map((item, i) => (
          <React.Fragment key={i}>
            {item && item.url && (
              <div className="py-3 overflow-hidden">
                <div className="url-wrapper">
                  <a href={item.url} target="_blank" rel="noreferrer" className="link-content">
                    <div className="d-flex align-items-end mb-1">
                      <div className="link-image">
                        <DisplayWebUrlImage url={item.url} faviconLink={item?.faviconLink} />
                      </div>
                      <div>
                        <p>{item.url.split("://")[1].split("/")[0]?.replace("www.", "")}</p>
                        <p>{item.url.split("?")[0]}</p>
                      </div>
                    </div>
                    <h3 className="fw-normal mb-2" dangerouslySetInnerHTML={{ __html: item?.title ? item.title : item?.metaDescription }}></h3>
                  </a>
                </div>

                <p className="desc-content mw-100" dangerouslySetInnerHTML={{ __html: item?.description ? item.description : item?.content?.substr(0, 400) }}></p>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </Col>
  )
}

export const RenderBarChart = ({ chartData, chartRef = null }) => {
  return (
    <Col sm="12" xl="8" className="preview-container px-4 py-2">
      {chartData && chartData.X_Data && chartData.X_Data.data && chartData.X_Data.data.length > 0 ? (
        <div className="chart-container mt-4">
          <Bar
            ref={chartRef}
            data={{
              labels: chartData.X_Data.data,
              datasets: [
                {
                  label: chartData.Y_Data.label,
                  data: chartData.Y_Data.data,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.25)',
                    'rgba(255, 159, 64, 0.25)',
                    'rgba(255, 205, 86, 0.25)',
                    'rgba(75, 192, 192, 0.25)',
                    'rgba(54, 162, 235, 0.25)',
                    'rgba(153, 102, 255, 0.25)'
                  ],
                  borderColor: [
                    'rgb(255, 99, 132)',
                    'rgb(255, 159, 64)',
                    'rgb(255, 205, 86)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)'
                  ],
                  borderWidth: 1,
                  borderRadius: 4,
                  maxBarThickness: 70,
                }
              ]
            }}
            plugins={[{
              beforeDraw: (chartCtx) => {
                const ctx = chartCtx.canvas.getContext('2d');
                ctx.save();
                ctx.fillStyle = '#fff';
                ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
                ctx.restore();
              }
            }]}
            options={{
              layout: {
                backgroundColor: '#fff',
                padding: 20
              },
              plugins: {
                title: { display: false },
                legend: { display: false }
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: chartData.X_Data.label,
                    font: { size: 20 }
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: chartData.Y_Data.label,
                    font: { size: 20 }
                  }
                }
              }
            }}
          />
        </div>
      ) : (
        <NoDataAvailable text={"No Chart Available"} />
      )}
    </Col>
  )
}
