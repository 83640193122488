import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Dropdown, Modal } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { AiOutlineEye, AiFillCheckCircle, AiFillClockCircle, AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-hot-toast";
import Swal from "sweetalert";

import { getHistory, getOptimizedBlogHistory } from "../utility/apiService";
import { setLoader } from "../store/reducer";
import TablePaginate from "../Components/TablePaginate";
import { MdOutlineRefresh } from "react-icons/md";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { HiOutlineSearch } from "react-icons/hi";

const ArticleOptimizedBlogHistory = () => {
  const dispatch = useDispatch();
  const optimizedBlogHistory = useSelector(({ optimizedBlogHistory }) => optimizedBlogHistory);
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);
  const [tableInfo, setTableInfo] = useState({ limit: 10, offset: 0 });

  const [searchTxt, setSearchTxt] = useState("");
  const [showLogs, setShowLogs] = useState({});
  const [logsLoader, setLogsLoader] = useState(false);
  // const filterData = optimizedBlogHistory && optimizedBlogHistory.length > 0 && optimizedBlogHistory.data.filter(
  //     (item) => (item?.first_name || "").toLocaleLowerCase().search((searchTxt || "").toLocaleLowerCase()) >= 0
  // );
  const filterData = optimizedBlogHistory && optimizedBlogHistory.length > 0 && optimizedBlogHistory.data.filter((item) => item);

  const handleChangePageLoadData = (limit, offset) => {
    if (offset >= 0) {
      dispatch(getOptimizedBlogHistory(limit, offset, searchTxt, tableInfo.sort, tableInfo.order));
    }
  };

  useEffect(() => {
    dispatch(getOptimizedBlogHistory(tableInfo.limit, tableInfo.offset, searchTxt, tableInfo.sort, tableInfo.order));
  }, []);

  const onSort = async (event) => {
    await dispatch(getHistory(tableInfo.limit, tableInfo.offset, searchTxt, (event?.sortField || "id"), (event?.sortOrder === 1 ? "asc" : "desc")));
    setTableInfo((prev) => ({ ...prev, sort: (event?.sortField || "id"), order: (event?.sortOrder === 1 ? "asc" : "desc") }));
  }

  const articleDelete = (id) => {
    Swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning", dangerMode: true,
      buttons: { cancel: "Cancel", confirm: "Yes, delete it!" },
    }).then((isConfirmed) => {
      if (isConfirmed) {
        dispatch(setLoader(true));
        AxiosApi.delete(`${apiBaseUrl}/api/articleHistory/${id}?tableName=artical_long_flow`).then(() => {
          dispatch(getOptimizedBlogHistory(tableInfo.limit, tableInfo.offset, searchTxt, tableInfo.sort, tableInfo.order));
          dispatch(setLoader(false));
          Swal("Deleted!", "Your record has been deleted.", "success");
        }).catch((error) => {
          dispatch(setLoader(false));
          toast.error("Something went wrong.");
          console.log(error);
        });
      }
    });
  };

  const downloadFile = (content, name) => {
    const element = document.createElement("a");
    const file = new Blob([content], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const handleDisplayError = (item) => {
    try {
      const error = item.error ? JSON.parse(item.error) : {};
      if (error.code === "Timeout") {
        return "System is overloaded. Please try again in some time."
      } else {
        if (error.message.indexOf("Azure") !== -1 || error.message.indexOf("filtered") !== -1) {
          return "OpenAI failed to generate the article due to filtering, Please rephrase or generate article for a different topic."
        }
        return error.message;
      }
    } catch (error) {
      return item.error;
    }
  }

  const handleGetLogsById = () => {
    setLogsLoader(true);
    AxiosApi.get(`${apiBaseUrl}/api/get-article-logs/${showLogs.id}?type=long`).then((res) => {
      setShowLogs((prev) => ({ ...prev, data: res.data?.logs ? res.data.logs : "" }));
      setTimeout(() => {
        setLogsLoader(false);
      }, 1000);
    }).catch((error) => {
      setLogsLoader(false);
    });
  }

  const topicBodyTemplate = (item) => {
    return (
      <React.Fragment>
        <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.topic} data-tooltip-place="bottom">
          {item?.topic}
        </h6>
        <p className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.generated_topic} data-tooltip-place="bottom">
          {item?.generated_topic}
        </p>
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (item) => {
    return (
      <div className="d-flex align-items-center">
        <span variant="success" data-tooltip-id="my-tooltip" data-tooltip-place="bottom"
          data-tooltip-content={`${item?.status.charAt(0).toUpperCase() + item?.status.slice(1)} ${(item?.status === "error") ? (`${handleDisplayError(item)}`) : ""}`}
        >
          {item?.status === "error" ? (
            <AiFillCloseCircle size={18} color="red" />
          ) : item?.status === "notAcceptable" ? (
            <AiFillCloseCircle size={18} color="#EF4444" />
          ) : (item?.status === "completed") ? (
            <AiFillCheckCircle size={18} color="green" />
          ) : (
            item?.status === "pending" && <AiFillClockCircle size={18} color="#e7e721" />
          )}
        </span>
      </div>
    );
  }

  const actionBodyTemplate = (item) => {
    return (
      <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
        <Dropdown.Toggle id="dropdown-basic">
          <BsThreeDots />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item target="_blank" href={`/article-optimized-blog/${item.id}`} rel="noreferrer">
            View <AiOutlineEye />
          </Dropdown.Item>
          {loginDetails && loginDetails.role === "admin" && (
            <Dropdown.Item onClick={() => articleDelete(item.id)}>Delete</Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => setShowLogs({ open: true, data: item.logs, id: item.id })}>
            View Logs
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    )
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Article Optimized Blog History ( {optimizedBlogHistory.length || 0} )</h4>
          <div>
            <div className="input-group">
              <input
                placeholder="Search Name..."
                type="text"
                onChange={(e) => setSearchTxt(e.target.value)}
                className="form-control"
              />
              <button className="btn btn-outline-secondary" type="button"
                onClick={() => dispatch(getOptimizedBlogHistory(tableInfo.limit, tableInfo.offset, searchTxt, tableInfo.sort, tableInfo.order))}
              >
                <HiOutlineSearch className="fs-4" />
              </button>
            </div>
          </div>
        </div>
        <div className="res-table">
          <DataTable value={filterData} onSort={onSort} sortField={tableInfo.sort} className="table"
            sortOrder={tableInfo.order === "asc" ? 1 : -1} emptyMessage={<NoDataAvailable />}
            selectionMode="single" dataKey="id" onSelectionChange={(e) => window.open(`/article-optimized-blog/${e.value.id}`, "_blank")}
          >
            <Column header="Id" field="id" sortable></Column>
            <Column header="Topic" sortField="topic" body={topicBodyTemplate} sortable></Column>
            <Column header="User Name" sortField="userName" sortable
              body={(item) => (`${item?.first_name + " " + (item?.last_name || "")}`)}
            ></Column>
            <Column header="SEO score"
              body={(item) => (`${item?.SEO_score_response && JSON.parse(item?.SEO_score_response)?.data?.Overview["Overall SEO score"]
                ? `${JSON.parse(item?.SEO_score_response)?.data?.Overview["Overall SEO score"]}%`
                : "-"}`)}
            ></Column>
            <Column header="Last modified" sortField="created_at" sortable body={(item) => moment(item?.created_at).format("MMM DD YYYY - h:mm a")}></Column>
            <Column header="Ai Type" sortField="AI_type" sortable body={(item) => (item?.AI_type || "-")}></Column>
            <Column header="Error" sortField="error" sortable body={(item) => <div>{item?.error || "-"}</div>}></Column>
            <Column header="status" sortField="status" sortable body={statusBodyTemplate}></Column>
            <Column header="Language" sortField="lan" sortable body={(item) => <div>{item?.lan}</div>}></Column>
            <Column header="Action" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
        <TablePaginate length={optimizedBlogHistory.length} handlePageChange={handleChangePageLoadData} />
      </div>
      <Modal className="" centered size="lg" show={showLogs?.open} onHide={() => setShowLogs({ open: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="me-4">Logs</span>
            <button type="button" className="btn btn-primary p-2" disabled={logsLoader} onClick={handleGetLogsById}>
              <MdOutlineRefresh className={`fs-3 ${logsLoader ? "svg-rotate" : ""}`} />
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <ul>
            {showLogs && showLogs.data && typeof JSON.parse(showLogs.data) !== typeof undefined && JSON.parse(showLogs.data).map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ArticleOptimizedBlogHistory;
