import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import HtmlViewer from "../Components/HtmlViewer";
import CodeEditor from "../Components/CodeEditor";
import { AxiosApi } from "../utility/axios";
import { FacebookPostPreview, FactCheckerPreview, InstagramPostPreview, LinkedInPostPreview, RenderBarChart, TwitterPostPreview } from "../Components/PostPreview";
import SeoScore from "../Components/SeoScore";
import MediaLibraryImagesModal from "../Components/MediaLibraryModal";
import RefundRequestCredits from "../Components/RefundRequestCredits";

const editorInitObj = {
  height: "calc(100% - 32px)",
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | blocks | " +
    "bold italic backcolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help",
  content_style: "body { margin:24px; font-family: 'Jost', sans-serif; font-size:14px }",
};

const ArticleHistoryDetails = () => {
  const editorRef = useRef(null);
  const params = useParams();

  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [articleData, setArticleData] = useState({});
  const [isShowLongArticleTab, setIsShowLongArticleTab] = useState(false);
  const [postImagesModal, setPostImagesModal] = useState({ open: false });

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  useEffect(() => {
    if (params?.id) {
      AxiosApi.get(`${apiBaseUrl}/api/articleHistory/byId/${params?.id}`).then((dd) => {
        setArticleData(dd.data.data);
      }).catch((dd) => {
        console.log(dd);
        alert("error");
      });
    }
  }, [params]);

  const handleViewAiImagesById = (subType) => {
    const obj = {
      type: "1-Click Blog", subType,
      articleId: params?.id ? params.id : "",
      userId: articleData?.user_id ? articleData.user_id : "",
    };

    setPostImagesModal({ open: true, isLoader: true });
    AxiosApi.get(`${apiBaseUrl}/api/admin/media-library-image/get-images?${new URLSearchParams(obj).toString()}`).then((res) => {
      setTimeout(() => {
        setPostImagesModal({ open: true, isLoader: false, ...res.data });
      }, 1000);
    }).catch((error) => {
      setPostImagesModal({ open: false, isLoader: false });
      toast.error("Something went wrong, Please try again.");
    });
  };

  return (
    <div className="w-100">
      <div className="border-radius-12 bg-white AIWriterBlog">
        <div className="AIWriterBlogHead">
          <div className="AIWriterBlogHeadTop">
            <h3>Smart Writer</h3>
          </div>
        </div>
        <div className="addllyFormWrap">
          <form>
            <Row>
              <Col xs="6">
                <div className="form-group mb-4">
                  <label>What do you want to write about?</label>
                  <input
                    name="topic" type="text" placeholder="Topic" defaultValue={articleData?.topic}
                    className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group mb-4">
                  <label>Keyword if any</label>
                  <input
                    name="keywords" type="text" placeholder="Type keywords"
                    defaultValue={articleData?.keyword} className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group mb-4">
                  <label>Headline Generated</label>
                  <input
                    name="generatedTopic" type="text" placeholder="Type Topic Generated"
                    defaultValue={articleData?.generated_topic} className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group mb-4">
                  <label>Meta title Generated</label>
                  <input
                    name="generatedMetaTitle" type="text" placeholder="Type Meta title Generated"
                    defaultValue={articleData?.meta_title} className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="form-group mb-4">
                  <label>Meta Description Generated</label>
                  <textarea
                    name="generatedMetaDescription" type="text" placeholder="Type Meta Description Generated"
                    defaultValue={articleData?.meta_dec} className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="form-group mb-4">
                  <label>Rating</label>
                  <input
                    name="rate" type="text" placeholder="Rating"
                    defaultValue={articleData?.rate} className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
              <Col xs="6">
                <div className="form-group mb-4">
                  <label>Comment</label>
                  <input
                    name="comment" type="text" placeholder="Comment"
                    defaultValue={articleData?.comment} className={`addllyForm-control`} disabled
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <div className="smartWriterTab">
                  <Tabs defaultActiveKey={"article"} className="mb-3 mt-4">
                    <Tab eventKey="article" title={<span className="active-tab-article">{isShowLongArticleTab ? "Short " : ""} Article</span>}>
                      <Row>
                        <Col xl={articleData.SEO_score_response && Object.keys(articleData.SEO_score_response).length > 0 ? 8 : 12} lg={12} md={12}>
                          <div className="position-relative form-group" style={{ minHeight: "100vh" }}>
                            <div className="d-flex justify-content-between align-items-center">
                              <button type="button" className="content-view-btn" onClick={() => setToggle(!toggle)}>
                                {toggle ? <span>Go back to content</span> : <span>Go to HTML View</span>}
                              </button>
                              {!toggle ? (
                                <button type="button" className="images-view-btn" onClick={() => handleViewAiImagesById('Short Article Images')}>
                                  Media Library
                                </button>
                              ) : null}
                            </div>
                            {toggle ? (
                              <div className="html-content" style={{ height: "calc(100% - 32px)" }}>
                                <HtmlViewer value={articleData?.article_html} />
                              </div>
                            ) : (
                              <Editor
                                initialValue={articleData?.withImageHtml || articleData?.article_html}
                                apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                                init={editorInitObj} disabled
                              />
                            )}
                          </div>
                        </Col>
                        {articleData.SEO_score_response && JSON.parse(articleData.SEO_score_response) && JSON.parse(articleData.SEO_score_response).data && (
                          <SeoScore contentScore={JSON.parse(articleData.SEO_score_response).data} />
                        )}
                      </Row>
                    </Tab>
                    {isShowLongArticleTab && (
                      <Tab eventKey="longArticle" title={<span className="active-tab-article">Long Article</span>}>
                        <Row>
                          <Col xl={12}>
                            <div className="position-relative form-group" style={{ minHeight: "100vh" }}>
                              <div className="d-flex justify-content-between align-items-center">
                                <button type="button" className="content-view-btn" onClick={() => setToggle1(!toggle1)}>
                                  {toggle1 ? <span>Go back to content</span> : <span>Go to HTML View</span>}
                                </button>
                                {!toggle ? (
                                  <button type="button" className="images-view-btn" onClick={() => handleViewAiImagesById('Long Article Images')}>
                                    Media Library
                                  </button>
                                ) : null}
                              </div>
                              {toggle1 ? (
                                <div className="html-content" style={{ height: "calc(100% - 32px)" }}>
                                  <HtmlViewer value={articleData?.article_detail_html} />
                                </div>
                              ) : (
                                <Editor
                                  initialValue={articleData?.withImageHtml || articleData?.article_detail_html}
                                  apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                                  init={editorInitObj}
                                  disabled
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    )}
                    <Tab eventKey="faqschema" title="FAQ and Schema Markup">
                      <Row>
                        <Col xl={6}>
                          <div className="html-content faq-schema" style={{ height: "calc(100% - 32px)" }}>
                            <label className="mb-2">FAQ</label>
                            <Editor
                              onInit={(evt, editor) => (editorRef.current = editor)}
                              initialValue={articleData?.FAQHTML}
                              apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                              init={{
                                height: "calc(100% - 32px)",
                                menubar: false,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                  "undo redo | blocks | " +
                                  "bold italic backcolor | alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent | " +
                                  "removeformat | help",
                                content_style:
                                  "body { margin:24px; font-family: 'Jost', sans-serif; font-size:14px }",
                              }}
                              disabled
                            />
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="html-content" style={{ height: "calc(100% - 32px)" }}>
                            <label className="mb-2">FAQ Markup Schema</label>
                            <CodeEditor
                              code={articleData.hasOwnProperty("FAQschema") ? articleData.FAQschema : null}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Tab>
                    <Tab eventKey="linkedIn" title={<span className="d-flex align-items-center gap-2"><FaLinkedinIn /> Linkedin Post</span>}>
                      <Row className="mt-5">
                        <Col sm={articleData?.linkedIn_post ? 8 : 12}>
                          <div className="socialPostEditor">
                            <div className="position-relative">
                              <div className="custom-textarea-editor">
                                <div className="textarea-header">LinkedIn Post</div>
                                <textarea
                                  className="w-100 outlineTextarea textarea-content"
                                  value={articleData?.linkedIn_post}
                                  style={{ height: "40vh" }} disabled
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        {articleData?.linkedIn_post && (
                          <Col sm={4} className="pb-2">
                            <LinkedInPostPreview
                              post={articleData?.linkedIn_post} preview_img={articleData?.linkedIn_post_img} col={12} isUploadImage={true}
                              setUploadFileModal={() => handleViewAiImagesById('LinkedIn Post')}
                            />
                          </Col>
                        )}
                      </Row>
                    </Tab>
                    <Tab eventKey="facebook" title={<span className="d-flex align-items-center gap-2"><FaFacebookF /> Facebook Post</span>}>
                      <Row className="mt-5">
                        <Col sm={articleData?.facebook_post ? 8 : 12}>
                          <div className="socialPostEditor">
                            <div className="position-relative">
                              <div className="custom-textarea-editor">
                                <div className="textarea-header">Facebook Post</div>
                                <textarea
                                  className="w-100 outlineTextarea textarea-content"
                                  value={articleData?.facebook_post}
                                  style={{ height: "40vh" }} disabled
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        {articleData?.facebook_post && (
                          <Col sm={4} className="pb-2">
                            <FacebookPostPreview
                              post={articleData?.facebook_post} preview_img={articleData?.facebook_post_img} col={12} isUploadImage={true}
                              setUploadFileModal={() => handleViewAiImagesById('Facebook Post')}
                            />
                          </Col>
                        )}
                      </Row>
                    </Tab>
                    <Tab eventKey="twitter" title={<span className="d-flex align-items-center gap-2"><FaXTwitter /> Twitter Post</span>}>
                      <Row className="mt-5">
                        <Col sm={articleData?.twitter_post ? 8 : 12}>
                          <div className="socialPostEditor">
                            <div className="position-relative">
                              <div className="custom-textarea-editor">
                                <div className="textarea-header">Twitter Post</div>
                                <textarea
                                  className="w-100 outlineTextarea textarea-content"
                                  value={articleData?.twitter_post}
                                  style={{ height: "40vh" }} disabled
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        {articleData?.twitter_post && (
                          <Col sm={4} className="pb-2">
                            <TwitterPostPreview
                              post={articleData?.twitter_post} preview_img={articleData?.twitter_post_img} col={12} isUploadImage={true}
                              setUploadFileModal={() => handleViewAiImagesById('Twitter Post')}
                            />
                          </Col>
                        )}
                      </Row>
                    </Tab>
                    <Tab eventKey="instagram" title={<span className="d-flex align-items-center gap-2"><FaInstagram /> Instagram Post</span>}>
                      <Row className="mt-5">
                        <Col sm={articleData?.instagram_post ? 8 : 12}>
                          <div className="socialPostEditor">
                            <div className="position-relative">
                              <div className="custom-textarea-editor">
                                <div className="textarea-header">Instagram Post</div>
                                <textarea
                                  className="w-100 outlineTextarea textarea-content"
                                  value={articleData?.instagram_post}
                                  style={{ height: "40vh" }} disabled
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        {articleData?.instagram_post && (
                          <Col sm={4} className="pb-2">
                            <InstagramPostPreview
                              post={articleData?.instagram_post} preview_img={articleData?.instagram_post_img} col={12} isUploadImage={true}
                              setUploadFileModal={() => handleViewAiImagesById('Instagram Post')}
                            />
                          </Col>
                        )}
                      </Row>
                    </Tab>
                    <Tab eventKey="bard" title="Google Ad Copy">
                      <Row>
                        <Col xl={6}>
                          <div className="socialPostEditor position-relative">
                            <div className="custom-textarea-editor">
                              <div className="textarea-header">Google Ad Copy</div>
                              <textarea
                                className="w-100 outlineTextarea textarea-content"
                                style={{ height: "40vh" }} disabled={true}
                                value={articleData?.googleAdCopy}
                              />
                            </div>
                          </div>
                        </Col>
                        {articleData && articleData.googleAdCopy && (
                          <Col xl={6}>
                            <div className="google-preview-container">
                              {[1, 2, 3].map((ele) => (
                                <div className="mb-4" key={ele}>
                                  <h4 className="text-primary">
                                    {articleData.googleAdCopy.substring(
                                      articleData.googleAdCopy.indexOf(`Headline ${ele}`), articleData.googleAdCopy.indexOf(ele === 3 ? 'Description 1' : `Headline ${ele + 1}`)
                                    ).replace(`Headline ${ele}: `, "")}
                                  </h4>
                                  <h6 className="text-success"> <span className="badge bg-success me-2">AD</span>
                                    {articleData.googleAdCopy.substring(
                                      articleData.googleAdCopy.indexOf("Display Path"), articleData.googleAdCopy.indexOf("Call to Action")
                                    ).replace("Display Path: ", "")}
                                  </h6>
                                  <p>
                                    {articleData.googleAdCopy.substring(
                                      articleData.googleAdCopy.indexOf(`Description ${ele}`), articleData.googleAdCopy.indexOf(ele === 2 ? 'Display Path' : `Description ${ele + 1}: `)
                                    ).replace(`Description ${ele}: `, "")}
                                  </p>
                                  <p className="text-primary">
                                    {articleData.googleAdCopy.substring(
                                      articleData.googleAdCopy.indexOf("Call to Action")
                                    ).replace("Call to Action: ", "")}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Tab>
                    <Tab eventKey="factChecker" title="Fact Checker">
                      <Row>
                        <FactCheckerPreview searchResponse={articleData?.search_response} col={12} />
                      </Row>
                    </Tab>
                    <Tab eventKey="chart" title="Chart">
                      <Row className="justify-content-center">
                        <RenderBarChart chartData={articleData?.article_html_chart ? JSON.parse(articleData?.article_html_chart)?.data : {}} />
                      </Row>
                    </Tab>
                    <Tab eventKey="refundRequests" title="Refund Credit">
                      {articleData?.user_id && (
                        <RefundRequestCredits articleType={"1-Click Blog"} articleId={params?.id} userId={articleData?.user_id} />
                      )}
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>

      {postImagesModal && postImagesModal.open && (
        <MediaLibraryImagesModal postImagesModal={postImagesModal} setPostImagesModal={setPostImagesModal} />
      )}
    </div>
  );
};

export default ArticleHistoryDetails;
