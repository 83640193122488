import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import { Col, Row } from "react-bootstrap";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import HtmlViewer from "../Components/HtmlViewer";

const editorInitObj = {
  height: "75vh",
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | blocks | " +
    "bold italic backcolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help",
  content_style: "body { margin:24px; font-family: 'Jost', sans-serif; font-size:14px }",
};

const PressReleaseInputFields = {
  "Product Launch": [
    {
      label: "Product Name",
      name: "productName",
      placeholder: "Insert Product Name Here",
      type: "textarea",
    },
    {
      label: "Key Features",
      name: "keyFeatures",
      placeholder: "Insert Key Features Here",
      type: "textarea",
    },
    {
      label: "Unique Benefits",
      name: "uniqueBenefits",
      placeholder: "Insert Unique Benefits Here",
      type: "textarea",
    },
    {
      label: "Target Market",
      name: "targetMarket",
      placeholder: "Insert Target Market Here",
      type: "textarea",
    },
    {
      label: "Launch Date",
      name: "launchDate",
      placeholder: "Insert Launch Date Here",
      type: "date",
    },
    {
      label: "Pricing",
      name: "pricing",
      placeholder: "Insert Pricing Here",
    },
    {
      label: "Availability",
      name: "availability",
      placeholder: "Insert Availability Here",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
  "Partnership Announcement": [
    {
      label: "Names Of The Companies",
      name: "namesOfTheCompanies",
      placeholder: "Insert Names Of The Companies Here",
      type: "textarea",
    },
    {
      label: "Nature Of The Partnership",
      name: "natureOfThePartnership",
      placeholder: "Insert Nature Of The Partnership Here",
      type: "textarea",
    },
    {
      label: "Mutual Benefits",
      name: "mutualBenefits",
      placeholder: "Insert Mutual Benefits Here",
      type: "textarea",
    },
    {
      label: "Goals",
      name: "goals",
      placeholder: "Insert Goals Here",
      type: "textarea",
    },
    {
      label: "Brief Background Of Each Company",
      name: "briefBackgroundOfEachCompany",
      placeholder: "Insert Brief Background Of Each Company Here",
      type: "textarea",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
  "Corporate Event Announcement": [
    {
      label: "Event Name",
      name: "eventName",
      placeholder: "Insert Event Name Here",
    },
    {
      label: "Date",
      name: "date",
      placeholder: "Insert Date Here",
      type: "date",
    },
    {
      label: "Location",
      name: "location",
      placeholder: "Insert Location Here",
      type: "textarea",
    },
    {
      label: "Purpose",
      name: "purpose",
      placeholder: "Insert Purpose Here",
      type: "textarea",
    },
    {
      label: "Key Speakers Or Highlights",
      name: "keySpeakersOrHighlights",
      placeholder: "Insert Key Speakers Or Highlights Here",
      type: "textarea",
    },
    {
      label: "Target Audience",
      name: "targetAudience",
      placeholder: "Insert Target Audience Here",
      type: "textarea",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
  "Financial or Earnings Report": [
    {
      label: "Key Financial Figures",
      name: "keyFinancialFigures",
      placeholder: "Insert Key Financial Figures Here",
      type: "textarea",
    },
    {
      label: "Growth Indicators",
      name: "growthIndicators",
      placeholder: "Insert Growth Indicators Here",
      type: "textarea",
    },
    {
      label: "Market Impact",
      name: "marketImpact",
      placeholder: "Insert Market Impact Here",
      type: "textarea",
    },
    {
      label: "Future Outlook",
      name: "futureOutlook",
      placeholder: "Insert Future Outlook Here",
      type: "textarea",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
  "New Executive Hire": [
    {
      label: "Executive’s Name",
      name: "executiveName",
      placeholder: "Insert Executive’s Name Here",
    },
    {
      label: "Position",
      name: "position",
      placeholder: "Insert Position Here",
    },
    {
      label: "Professional Background",
      name: "professionalBackground",
      placeholder: "Insert Professional Background Here",
      type: "textarea",
    },
    {
      label: "Role In The Company",
      name: "roleInTheCompany",
      placeholder: "Insert Role In The Company Here",
      type: "textarea",
    },
    {
      label: "Expected Impact",
      name: "expectedImpact",
      placeholder: "Insert Expected Impact Here",
      type: "textarea",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
  "Company Milestone": [
    {
      label: "Nature Of The Milestone",
      name: "natureOfTheMilestone",
      placeholder: "Insert Nature Of The Milestone Here",
      type: "textarea",
    },
    {
      label: "Historical Context",
      name: "historicalContext",
      placeholder: "Insert Historical Context Here",
      type: "textarea",
    },
    {
      label: "Impact On The Company",
      name: "impactOnTheCompany",
      placeholder: "Insert Impact On The Company Here",
      type: "textarea",
    },
    {
      label: "Future Implications",
      name: "futureImplications",
      placeholder: "Insert Future Implications Here",
      type: "textarea",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
  "Award Win": [
    {
      label: "Award Name",
      name: "awardName",
      placeholder: "Insert Award Name Here",
      type: "textarea",
    },
    {
      label: "Criteria",
      name: "criteria",
      placeholder: "Insert Criteria Here",
      type: "textarea",
    },
    {
      label: "Significance To The Company",
      name: "significanceToTheCompany",
      placeholder: "Insert Significance To The Company Here",
      type: "textarea",
    },
    {
      label: "Future Goals",
      name: "futureGoals",
      placeholder: "Insert Future Goals Here",
      type: "textarea",
    },
    {
      label: "Company Name",
      name: "companyName",
      placeholder: "Insert Company Name Here",
    },
    {
      label: "Company CEO Name",
      name: "ceoName",
      placeholder: "Insert Company CEO Name Here",
    },
    {
      label: "Media Contact Info",
      name: "mediaContactInfo",
      placeholder: "Insert Media Contact Details Here",
      type: "textarea",
    },
  ],
};

const PressReleaseDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const { id } = useParams();

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [htmlText, setHtmlText] = useState("");
  const [finalHtmlText, setFinalHtmlText] = useState("");
  const [toggle, setToggle] = useState(false);
  const [editorKey, setEditorKey] = useState(0);

  const { register, watch, reset } = useForm({ defaultValues: { type: "" } });

  useEffect(() => {
    if (id && id !== "") {
      handleGetPressReleaseDetailsById(id);
    }
  }, []);

  const handleGetPressReleaseDetailsById = async (id) => {
    try {
      dispatch(setLoader(true));
      setHtmlText("");
      setFinalHtmlText("");

      const response = await AxiosApi.get(`${apiBaseUrl}/api/press-release/get-detail?id=${id}&isNotCheck=true`);
      if (response && response.data) {
        dispatch(setLoader(false));
        const item = response.data && response.data.id ? response.data : {};
        if (item && item.resText) {
          setHtmlText(item.resText);
          setFinalHtmlText(item.resText);
          setEditorKey(editorKey + 1);
        }

        if (item && item.subType) {
          reset({ type: item.subType, ...JSON.parse(item.inputInfo) })
        }
      } else {
        dispatch(setLoader(false));
      }
    } catch (error) {
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="border-radius-12 bg-white AIWriterBlog">
      <div className="AIWriterBlogHead pb-2">
        <div className="AIWriterBlogHeadTop">
          <h4>Press Release</h4>
        </div>
      </div>
      <Row className="">
        <Col sm="12" md="4" className="border-end overflow-auto" style={{ maxHeight: "75vh" }}>
          <div className="addllyFormWrap">
            <Col sm="12">
              <div className="form-group mb-4">
                <label>Press Release Type</label>
                <input name={"type"} type="text" {...register("type")} className={`addllyForm-control`} disabled />
              </div>
            </Col>
            {PressReleaseInputFields && PressReleaseInputFields[watch("type")] && PressReleaseInputFields[watch("type")].map((ele, index) => (
              <Col sm="12" key={index}>
                <div className="form-group mb-4">
                  <label>{ele.label}</label>
                  {ele.type === "textarea" ? (
                    <textarea
                      name={ele.name} type="text" {...register(ele.name)} rows="3" className={`addllyForm-control px-3 py-2`}
                      style={{ borderRadius: "12px" }} placeholder={ele.placeholder} disabled
                    />
                  ) : (
                    <input
                      name={ele.name} type={ele.type} placeholder={ele.placeholder} {...register(ele.name)}
                      className={`addllyForm-control px-4`} disabled
                    />
                  )}
                </div>
              </Col>
            ))}
          </div>
        </Col>
        <Col sm="12" md="8" className="position-relative">
          <div className="position-relative review-editor-content">
            <div className="d-md-flex justify-content-between align-items-center text-end text-md-start">
              <button type="button" className="content-view-btn addlly-primary py-2" onClick={() => setToggle(!toggle)}>
                {toggle ? <span>Go back to content</span> : <span>Go to HTML View</span>}
              </button>
              <button type="button" className="images-view-btn addlly-primary py-2 px-5" disabled>
                <span>Save Text</span>
              </button>
            </div>

            {toggle ? (
              <div className="html-content" style={{ height: "75vh" }}>
                <HtmlViewer value={htmlText} html={finalHtmlText} onEditorChange={(e) => setHtmlText(e)} />
              </div>
            ) : (
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)} value={htmlText}
                onEditorChange={(e) => setHtmlText(e)} key={editorKey} disabled
                init={{
                  ...editorInitObj,
                  content_style: `${(finalHtmlText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")} ${editorInitObj.content_style}`
                }}
              />
            )}
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-between">
            <button type="button" className="addlly-outline" onClick={() => navigate(`/press-release`)}>
              <HiOutlineChevronLeft /> <span>Back</span>
            </button>
            <button type={"button"} className="addlly-primary" disabled>
              <span>Go Dashboard</span> <HiOutlineChevronRight />
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PressReleaseDetails;
