import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  loginDetails: {
    username: "",
    password: "",
  },
  activeMenu: 1,
  articleHistory: [],
  articleHistoryLength: 0,
  articleHistoryUser: [],
  articleHistoryUserLength: 0,
  userList: [],
  topResultsStep: 1,
  userRes: {},
  generatedFullArticle: {},
  apiBaseUrl: process.env.REACT_APP_API_URL_PROD,
  currEnv: "prod",
  optimizedBlogHistory: { data: [], length: 0 },
  socialMediaPostHistory: { data: [], length: 0 },
};

export const reducer = createSlice({
  name: "ADDLLY-ADMIN",
  initialState,
  reducers: {
    setLoader: (state, { payload }) => {
      state.loader = payload;
    },
    setLoginDetails: (state, { payload }) => {
      state.loginDetails = payload;
    },
    setActiveMenu: (state, { payload }) => {
      state.activeMenu = payload;
    },
    setArticleHistory: (state, { payload }) => {
      state.articleHistory = payload;
    },
    setArticleHistoryLength: (state, { payload }) => {
      state.articleHistoryLength = payload;
    },
    setArticleHistoryUser: (state, { payload }) => {
      state.articleHistoryUser = payload;
    },
    setArticleHistoryUserLength: (state, { payload }) => {
      state.articleHistoryUserLength = payload;
    },
    setUserList: (state, { payload }) => {
      state.userList = payload;
    },
    setTopResultsStep: (state, { payload }) => {
      state.topResultsStep = payload;
    },
    setUserRes: (state, { payload }) => {
      state.userRes = payload;
    },
    setGeneratedFullArticle: (state, { payload }) => {
      state.generatedFullArticle = payload;
    },
    setCurrentEnvironment: (state, { payload }) => {
      state.currEnv = payload;
      state.articleHistory = [];
      state.articleHistoryLength = 0;
      state.articleHistoryUser = [];
      state.articleHistoryUserLength = 0;
      state.userList = [];
      state.topResultsStep = 1;
      state.userRes = {};
      state.generatedFullArticle = {};
      state.optimizedBlogHistory = { data: [], length: 0 };
      state.socialMediaPostHistory = { data: [], length: 0 };

      if (payload === "prod") {
        state.apiBaseUrl = process.env.REACT_APP_API_URL_PROD;
      } else {
        state.apiBaseUrl = process.env.REACT_APP_API_URL_STAGING;
      }
    },
    setOptimizedBlogHistory: (state, { payload }) => {
      state.optimizedBlogHistory = payload;
    },
    setSocialMediaPostHistory: (state, { payload }) => {
      state.socialMediaPostHistory = payload;
    },
    resetReduxData: (state, { payload }) => {
      Object.keys(initialState).map((key) => {
        state[key] = initialState[key];
        return key;
      })
    },
  },
});

export const {
  setLoader,
  setUserRes,
  setLoginDetails,
  setActiveMenu,
  setArticleHistory,
  setArticleHistoryLength,
  setArticleHistoryUser,
  setArticleHistoryUserLength,
  setUserList,
  setTopResultsStep,
  setGeneratedFullArticle,
  setCurrentEnvironment,
  setOptimizedBlogHistory,
  setSocialMediaPostHistory,
  resetReduxData
} = reducer.actions;

export default reducer.reducer;
