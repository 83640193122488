import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Row, Col } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import { HiOutlinePlus } from "react-icons/hi";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-hot-toast";

import Modal from "react-bootstrap/Modal";
import { getUser } from "../utility/apiService";
import { setLoader, setUserList } from "../store/reducer";
import { useNavigate } from "react-router-dom";
import { AxiosApi } from "../utility/axios";
import NoDataAvailable from "../Components/NoDataAvailable";
import TablePaginate from "../Components/TablePaginate";

const addValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  username: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
  email: Yup.string().required("Email is required"),
});

const editValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  username: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
});

const Users = () => {
  const userList = useSelector(({ userList }) => userList);
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const loginDetails = useSelector(({ loginDetails }) => loginDetails);

  const [itemOffset, setItemOffset] = useState(0);
  const [searchTxt, setSearchTxt] = useState("");
  const [userPopup, setUserPopup] = useState({ open: false, type: "" });
  const [viewUserPopup, setViewUserPopup] = useState({ open: false, data: {} });
  const [typePassword, setTypePassword] = useState(true);
  const [currentStatus, setCurrentStatus] = useState("");

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({
    defaultValues: {},
    resolver: yupResolver((userPopup?.type && userPopup.type === "Edit") ? editValidationSchema : addValidationSchema),
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUser(""));
  }, [])

  const filterData = userList.filter((item) => {
    const searchTxtLower = searchTxt.toLowerCase();
    return (
      item?.id?.toString().includes(searchTxtLower) ||
      item?.username?.toLowerCase().includes(searchTxtLower) ||
      item?.company?.toLowerCase().includes(searchTxtLower) ||
      (item?.first_name + " " + item?.last_name)?.toLowerCase().includes(searchTxtLower) ||
      item?.role?.toLowerCase().includes(searchTxtLower) ||
      item?.status?.toLowerCase().includes(searchTxtLower)
    );
  });

  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filterData.slice(itemOffset, endOffset);

  const handlePageClick = (limit, offset) => {
    setItemOffset(offset);
  };

  const onSubmitAddUser = async (data) => {
    try {
      dispatch(setLoader(true));
      let apiUrl = "user/register";
      if (userPopup?.type && userPopup.type === "Edit") {
        apiUrl = "user/update";
      }

      const response = await AxiosApi.post(`${apiBaseUrl}/${apiUrl}?role=admin`, data);
      if (response && response.data) {
        toast.success(`User ${userPopup?.type === "Edit" ? "updated" : "added"} successfully.`);
        dispatch(setLoader(false));
        dispatch(getUser(currentStatus));
        setUserPopup({ open: false, type: "" });
        reset({ first_name: "" });
      } else {
        toast.error("something went wrong, Please try again!");
      }
    } catch (error) {
      dispatch(setLoader(false));
      toast.error(error?.response?.data?.error || "something went wrong");
    }
  }

  const handleOnEditUserSetData = (item) => {
    const setValObj = {
      id: item?.id || "",
      first_name: item?.first_name || "",
      last_name: item?.last_name || "",
      username: item?.username || "",
      phone_number: item?.phone_number || "",
      password: item?.password || "",
      credits: item?.credits || "",
      industry: item?.industry || "",
      company: item?.company || "",
      is_active: item?.is_active || "",
      email: item?.email || "",
    }
    reset(setValObj);
    setUserPopup({ open: true, type: "Edit" });
  }

  const handleOnChangeCurrentTab = async (val) => {
    dispatch(setUserList([]));
    setCurrentStatus(val);

    if (val === "list") {
      setCurrentStatus("");
      dispatch(getUser(""));
    } else {
      dispatch(getUser(val));
    }
  }

  return (
    <React.Fragment>
      <div className="dashboard-history border-radius-12 bg-white p-3 p-xl-4">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">Users History ( {filterData.length || 0} )</h4>
          <div className="d-flex align-items-center">
            <Dropdown data-bs-theme="dark">
              <Dropdown.Toggle id="dropdown-button-dark-example1" variant="primary" className="me-4">
                <FaFilter /> <span className="mx-2">Filter</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {[
                  { label: "Active", value: "active" },
                  { label: "Inactive", value: "inactive" },
                  { label: "External User", value: "external" },
                  { label: "Internal User", value: "internal" },
                  { label: "Subscribed", value: "subscribed" },
                  { label: "Organization User", value: "organization" },
                ].map((item, index) => (
                  <Dropdown.Item as="button" key={index}
                    onClick={() => handleOnChangeCurrentTab(item.value)}
                    active={item.value === currentStatus ? true : false}
                  >
                    {item.label}
                  </Dropdown.Item>
                ))}
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={() => handleOnChangeCurrentTab("list")}>Clear Filter</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <input
              placeholder="Search Username..."
              type="text"
              onChange={(e) => setSearchTxt(e.target.value)}
              className="form-control"
            />
            {loginDetails && loginDetails.role === "admin" && (
              <button onClick={() => setUserPopup({ open: true, type: "Add" })} type="button" className="addlly-primary py-2 px-3 ms-3">
                <span>Add</span> <HiOutlinePlus className="fs-6" />
              </button>
            )}
          </div>
        </div>
        <div className="res-table">
          {currentItems.length > 0 ? (
            <table className="custom-table table table-hover mt-20 mb-4">
              <thead>
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Username</th>
                  <th scope="col">Name</th>
                  <th scope="col">Organization</th>
                  <th scope="col">Role</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Last Login</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((item, ind) => (
                  <tr className="cursor-pointer" key={ind} onClick={() => setViewUserPopup({ open: true, data: item })}>
                    <td>{item?.id}</td>
                    <td>{item?.username}</td>
                    <td>{item?.first_name + " " + (item?.last_name || "")}</td>
                    <td>{item?.company || "-"}</td>
                    <td>{item?.role ? item.role.replace("OrgAdmin", "Org Admin").replace("OrgUser", "Org User") : "-"}</td>
                    <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                    <td>{item?.last_login === null ? '-' : moment(item?.last_login).format("MMM DD YYYY - h:mm a") === "Invalid date" ? "-" : moment(item?.last_login).format("MMM DD YYYY - h:mm a")}</td>
                    <td>{item?.is_active === 1 ? (
                      <span className="badge text-bg-success">Active</span>
                    ) : (
                      <span className="badge text-bg-danger" data-tooltip-id="my-tooltip" data-tooltip-content={item?.email_verified == 0 ? 'OTP Step Not Completed' : 'Admin Deactivated'} data-tooltip-place="bottom">Inactive</span>
                    )}
                    </td>
                    <td>
                      <Dropdown align="end" className="actionDropdown" onClick={(e) => e.stopPropagation()}>
                        <Dropdown.Toggle id="dropdown-basic">
                          <BsThreeDots />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {loginDetails && loginDetails.role === "admin" && (
                            <Dropdown.Item eventKey="1" onClick={() => handleOnEditUserSetData(item)}>
                              Edit
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item eventKey="2" onClick={() => navigate(`/user-transaction-history/${item.id}`)} >
                            Credit Transaction History
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="3" onClick={() => navigate(`/user-log/${item.id}`)} >
                            Usage Log
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <TablePaginate length={filterData.length} handlePageChange={handlePageClick} />
      </div>

      {userPopup && userPopup.open && (
        <Modal show={userPopup.open} size="lg" onHide={() => { setUserPopup({ open: false, type: "" }); reset({ first_name: "" }); }}>
          <Modal.Header closeButton>
            <h4>{userPopup?.type || "Add"} User</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            <form onSubmit={handleSubmit(onSubmitAddUser)} className="">
              <Row>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>First Name</label>
                    <input
                      name="first_name" type="text" placeholder="Enter first name" {...register("first_name")}
                      className={`addllyForm-control ${errors.first_name ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Last Name</label>
                    <input
                      name="last_name" type="text" placeholder="Enter last name" {...register("last_name")}
                      className={`addllyForm-control ${errors.last_name ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                  </div>
                </Col>
                {/* {userPopup?.type && userPopup.type !== "Edit" && ( */}
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Username</label>
                    <input
                      name="username" type="text" placeholder="Enter user name" {...register("username")}
                      className={`addllyForm-control ${errors.username ? "is-invalid" : ""}`}
                      disabled={userPopup?.type && userPopup.type === "Edit" ? true : false}
                    />
                    <div className="invalid-feedback">{errors.username?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Email</label>
                    <input
                      name="email" type="email" placeholder="Enter email" {...register("email")}
                      className={`addllyForm-control ${errors.email ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                </Col>
                {/* )} */}
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Phone Number</label>
                    <input
                      name="phone_number" type="phone" placeholder="Enter phone number" {...register("phone_number")}
                      className={`addllyForm-control ${errors.phone_number ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.phone_number?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4 position-relative">
                    <label>Password</label>
                    <input
                      name="password" type={typePassword ? "password" : "text"} placeholder="Enter password" {...register("password")}
                      className={`addllyForm-control ${errors.password ? "is-invalid" : ""}`}
                    />
                    <button type="button" onClick={() => setTypePassword(!typePassword)} className="passwordIcon">
                      {!typePassword ? (
                        <AiOutlineEyeInvisible size={16} color="#50506A" />
                      ) : (
                        <AiOutlineEye size={16} color="#50506A" />
                      )}
                    </button>
                    <div className="invalid-feedback">{errors.password?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Addlly Credits</label>
                    <input
                      name="credits" type="number" placeholder="Enter addlly credits" {...register("credits")}
                      className={`addllyForm-control ${errors.credits ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.credits?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Industry</label>
                    <input
                      name="industry" type="text" placeholder="Enter industry" {...register("industry")}
                      className={`addllyForm-control ${errors.industry ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.industry?.message}</div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group mb-4">
                    <label>Company</label>
                    <input
                      name="company" type="text" placeholder="Enter company" {...register("company")}
                      className={`addllyForm-control ${errors.company ? "is-invalid" : ""}`}
                    />
                    <div className="invalid-feedback">{errors.company?.message}</div>
                  </div>
                </Col>
                {userPopup?.type && userPopup.type === "Edit" && (
                  <Col sm="6">
                    <div className="form-group mb-4">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                          {...register("is_active")} onChange={(e) => setValue("is_active", e.target.checked ? 1 : 0)}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Active</label>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              <div className="mt-4">
                <button className="addlly-primary ms-auto" type="submit" variant="primary">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {viewUserPopup && viewUserPopup.open && (
        <Modal centered show={viewUserPopup.open} size="" onHide={() => setViewUserPopup({ open: false, data: {} })}>
          <Modal.Header closeButton>
            <h4>User Info</h4>
          </Modal.Header>
          <Modal.Body className="addllyFormWrap">
            {[
              { label: "First Name", value: (viewUserPopup?.data?.first_name || "-") },
              { label: "Last Name", value: (viewUserPopup?.data?.last_name || "-") },
              { label: "Username", value: (viewUserPopup?.data?.username || "-") },
              { label: "Email", value: (viewUserPopup?.data?.email || "-") },
              { label: "Phone Number", value: (viewUserPopup?.data?.phone_number || "-") },
              { label: "Company", value: (viewUserPopup?.data?.company || "-") },
              { label: "Industry", value: (viewUserPopup?.data?.industry || "-") },
              { label: "Role", value: (viewUserPopup?.data?.role || "-") },
              { label: "Addlly Credits", value: (viewUserPopup?.data?.credits || "-") },
              { label: "Current Plan", value: (viewUserPopup?.data?.current_plan || "-") },
            ].map((ele) => (
              <div className="d-flex align-items-center mb-3" key={ele}>
                <div className="w-50">{ele.label}</div>
                <div className="w-50">{ele.value}</div>
              </div>
            ))}
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default Users;
