import React, { useState, useEffect } from "react";
import moment from "moment";
import { Modal, Form } from "react-bootstrap";
import { BiMinus, BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";

const RefundRequestCredits = ({ articleType, articleId, userId }) => {
  const dispatch = useDispatch();
  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);

  const [requestsHistory, setRequestsHistory] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [reqRefundData, setReqRefundData] = useState({ id: "", comment: "" });

  useEffect(() => {
    if (articleId && articleId !== "") {
      getRefundRequestsHistory();
    }
  }, []);

  const getRefundRequestsHistory = async () => {
    dispatch(setLoader(true));
    setRequestsHistory([]);
    const url = `${apiBaseUrl}/api/user/credit-transactions/${userId}?articleType=${articleType}&articleId=${articleId}`;
    AxiosApi.get(url).then((response) => {
      setRequestsHistory(response?.data?.data);
      dispatch(setLoader(false));
    }).catch((error) => {
      dispatch(setLoader(false));
    });
  };

  const handleOpenRefundModal = (row) => {
    setShowRefundModal(true);
    setReqRefundData({ id: row?.id, comment: "" });
  };

  const handleCloseRefundModal = () => {
    setShowRefundModal(false);
    setReqRefundData({ id: "", comment: "" });
  };

  const handleSendRefundCreditRequest = () => {
    dispatch(setLoader(true));
    const apiPostUrl = `${apiBaseUrl}/api/refund-request/approved/${reqRefundData.id}`;
    AxiosApi.get(`${apiPostUrl}?status=refund&comment=${reqRefundData.comment}`).then((res) => {
      dispatch(setLoader(false)); handleCloseRefundModal();
      getRefundRequestsHistory();
    }).catch((e) => {
      dispatch(setLoader(false));
    });
  };

  return (
    <div className="dashboard-history bg-white">
      <div className="res-table">
        {requestsHistory && requestsHistory.length > 0 ? (
          <table className="custom-table table mb-0">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Credit</th>
                <th scope="col">User Comment</th>
                <th scope="col">Admin Comment</th>
                <th scope="col">Created At</th>
                <th scope="col">Refund Status</th>
              </tr>
            </thead>
            <tbody>
              {requestsHistory.map((item, ind) => (
                <tr key={ind}>
                  <td>{item?.sub_type || "-"}</td>
                  <td className="">
                    <div className="d-flex align-items-center">
                      <span style={{ width: "24px" }}>
                        {item?.credit > 0 ? <BiPlus color='#00ab55' className='fs-5' /> : <BiMinus color='#e7515a' className='fs-5' />}
                      </span> {item?.credit ? item.credit : item?.debit}
                    </div>
                  </td>
                  <td className="">
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.comment} data-tooltip-place="bottom">
                      {item?.comment || "-"}
                    </h6>
                  </td>
                  <td className="">
                    <h6 className="text-width" data-tooltip-id="my-tooltip" data-tooltip-content={item?.admin_comment} data-tooltip-place="bottom">
                      {item?.admin_comment || "-"}
                    </h6>
                  </td>
                  <td>{moment(item?.created_at).format("MMM DD YYYY - h:mm a")}</td>
                  <td className="">
                    <div className="">
                      {!item?.refund_status && item?.credit === 0 ? (
                        <button className="btn btn-primary" type="button" onClick={() => handleOpenRefundModal(item)}>Refund</button>
                      ) : item?.refund_status ? (
                        <div className="d-flex align-items-center gap-2">
                          <div className="dotStatus">
                            <span className={`d-block`}
                              style={{
                                width: "8px", height: "8px", borderRadius: "50%",
                                backgroundColor: item.refund_status === "approved" ? "#84CC16" : item.refund_status === "refund" ? "#84CC16" :
                                  item.refund_status === "rejected" ? "#EF4444" : "#F59E0B",
                              }}
                            />
                          </div>
                          {item.refund_status === "approved" ? "Approved" : item.refund_status === "refund" ? "Refund" :
                            item.refund_status === "rejected" ? "Rejected" : "Pending"}
                        </div>
                      ) : '-'}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h3 className="text-center mt-3 mb-4 color-Dark-dark_4">Transaction history not available yet!</h3>
        )}
      </div>

      {showRefundModal && (
        <Modal centered show={showRefundModal} onHide={handleCloseRefundModal}>
          <Modal.Header closeButton>
            <Modal.Title>Refund Credit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addllyFormWrap">
              <div className="form-group mb-4">
                <label className="mt-2 d-block">Comment</label>
                <Form.Control
                  as="textarea" rows={3} name="comment" type="text" value={reqRefundData?.comment}
                  placeholder="Type the Comment" onChange={(e) => setReqRefundData({ ...reqRefundData, comment: e.target.value })}
                  className={`addllyForm-control d-block w-100`} style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="mt-4 d-flex justify-content-between">
                <button className="addlly-primary" type="button" variant="secondary" onClick={() => handleCloseRefundModal()}>
                  Close
                </button>
                <button className="addlly-primary" type="button" variant="primary" onClick={() => handleSendRefundCreditRequest()}>
                  Save
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}

export default RefundRequestCredits;
