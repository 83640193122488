import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Editor } from "@tinymce/tinymce-react";
import { Col, Row } from "react-bootstrap";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { setLoader } from "../store/reducer";
import { AxiosApi } from "../utility/axios";
import HtmlViewer from "../Components/HtmlViewer";

const editorInitObj = {
  height: "75vh",
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | blocks | " +
    "bold italic backcolor | alignleft aligncenter " +
    "alignright alignjustify | bullist numlist outdent indent | " +
    "removeformat | help",
  content_style: "body { margin:24px; font-family: 'Jost', sans-serif; font-size:14px }",
};

const NewslettersDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const editorRef = useRef(null);

  const apiBaseUrl = useSelector(({ apiBaseUrl }) => apiBaseUrl);
  const [htmlText, setHtmlText] = useState("");
  const [finalHtmlText, setFinalHtmlText] = useState("");
  const [toggle, setToggle] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [newsletterInfo, setNewsletterInfo] = useState({});

  useEffect(() => {
    if (id) {
      handleGetDetailsById(id);
    }
  }, [])

  const handleGetDetailsById = async (id) => {
    try {
      dispatch(setLoader(true));
      const res = await AxiosApi.get(`${apiBaseUrl}/api/news-letters/get-detail?id=${id}`);
      if (res && res.data && res.data.resText) {
        setHtmlText(res.data.resText);
        setFinalHtmlText(res.data.resText);
        setEditorKey(editorKey + 1);
        setNewsletterInfo(res.data);
      } else {
        toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      }
      dispatch(setLoader(false));
    } catch (error) {
      toast.error("Something went wrong, Please try again later.", { id: "Toast-01" });
      dispatch(setLoader(false));
    }
  }

  return (
    <div className="border-radius-12 bg-white AIWriterBlog">
      <div className="AIWriterBlogHead pb-2">
        <div className="AIWriterBlogHeadTop">
          <h4>Newsletters</h4>
        </div>
      </div>
      <Row>
        <Col sm="4" className="d-flex flex-column">
          <div className="addllyFormWrap">
            <Col sm="12">
              <div className="form-group mb-4">
                <label>What do you want to write about Newsletters? Write a brief description.</label>
                <textarea
                  name="topic" type="text" rows="3" className={`addllyForm-control`} disabled
                  style={{ borderRadius: "12px" }} placeholder="Insert description here"
                  value={newsletterInfo?.topic || ""}
                />
              </div>
            </Col>
            <Col sm="12">
              <div className="form-group mb-4">
                <label>Add Call to Action</label>
                <input
                  name="cta" type="text" placeholder="Insert Call to Action" disabled
                  className={`addllyForm-control`} value={newsletterInfo?.cta || ""}
                />
              </div>
            </Col>
            {[1, 2, 3].map((no) => newsletterInfo?.[`url${no}`] ? (
              <Col sm="12" key={no}>
                <div className="form-group mb-4">
                  <label>URL {no}</label>
                  <input
                    name={`url${no}`} type="text" placeholder="Example: https://addlly.ai/" disabled
                    className={`addllyForm-control`} value={newsletterInfo?.[`url${no}`] || ""}
                  />
                </div>
              </Col>
            ) : null)}
          </div>
          <div className="d-flex justify-content-between mt-auto">
            <button type="button" className="addlly-outline" onClick={() => navigate(`/news-letters`)}>
              <HiOutlineChevronLeft /> <span>Back</span>
            </button>
            <button type="button" className="addlly-primary" disabled>
              <span>Save</span> <HiOutlineChevronRight />
            </button>
          </div>
        </Col>
        <Col sm="8">
          <div className="position-relative newsletter-editor">
            <div className="d-md-flex justify-content-between align-items-center text-end text-md-start">
              <button type="button" className="content-view-btn" onClick={() => setToggle(!toggle)}>
                {toggle ? <span>Go back to content</span> : <span>Go to HTML View</span>}
              </button>
            </div>

            {toggle ? (
              <div className="html-content" style={{ height: "75vh" }}>
                <HtmlViewer value={finalHtmlText} />
              </div>
            ) : (
              <Editor
                apiKey={process.env.REACT_APP_TINYMCE_EDITOR_KEY}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={htmlText} key={editorKey} disabled
                init={{
                  ...editorInitObj,
                  content_style: `${(finalHtmlText.match(/<style>([\s\S]*?)<\/style>/i)?.[1] || "")} ${editorInitObj.content_style}`
                }}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default NewslettersDetails;
